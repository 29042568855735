import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f8f9fa;
  overflow-y: auto;
`

const ContentContainer = styled.div`
  flex: 1;
  padding: 1rem 3rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const CardContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Card = styled.div`
  width: 300px; // Set a fixed width for consistency
  
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s ease;
  flex: 1;
  border: ${(props) => (props.grey ? '2px solid #4a5568' : '2px solid #c471ed')};

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    width: 90%; // Full width on mobile for better stacking
    margin-bottom: 0.5rem;
  }
`

const TableTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
`

const MostPopularTag = styled.span`
  position: absolute;
  border-radius: 0px 0px 0px 8px;
  top: 0px;
  right: 0px;
  background-color: #c471ed;
  color: #ffffff;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
  width: 5rem;
`

const BulletPoint = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.6;
  font-size: 9pt;
  margin-top: 3px;
  padding: 3px;
  font-weight: 500;
  width: auto; 
  border-radius: 4px;

  color: ${(props) => (props.highlight ? 'white' : '#4a5568')};
  background-color: ${(props) => (props.highlight ? '#c471ed' : 'transparent')};

  svg {
    margin-right: 10px;
    margin-left: 4px;
  }
`

const CardDescription = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 10px;
  height: 24px;
`

const CardPrice = styled.p`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
  height: 2rem; //set height for cards with no price
`

const CardButton = styled.button`
  background-color: ${(props) => (props.grey ? '#4a5568' : '#c471ed')};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease; 
  width: 100%;
  
  &:hover {
    background-color: ${(props) => (props.grey ? 'rgba(74, 85, 104, 0.9)' : 'rgba(196, 113, 237, 0.9)')}; //same colors just 90% opacity on hover
  }
`

const BulletContainerTitle = styled.h3`
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #4a5568;
`

const BulletPointContainer = styled.div`
  text-align: left;
  padding: 10px;
`
const ShadeContainer = styled.div`
  padding: 1rem;
  text-align: center;

  
  background-color: ${(props) =>
    props.grey ? 'rgba(74, 85, 104, 0.1)' : 'rgba(196, 113, 237, 0.1)'};
`
const Emoji = styled.img`
  width: 1.5rem;
  padding-right: 0.1rem;
`

export default function EmployerPricing() {
  const [selectedPlan, setSelectedPlan] = useState(null)

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan)
  }

  const handleOpenUpgradeLink = () => {
    window.open('https://meetings.hubspot.com/parul-khosla/schedule-a-demo')
  }

  return (
    <PageWrapper>
      <PageContainer>
        <ContentContainer>
          <CardContainer>
            {/* Free Trial */}
            <Card grey={true}>
              <ShadeContainer grey={true}>
                <TableTitle>Post a Job for Free</TableTitle>
                <CardDescription>Post in minutes</CardDescription>
                <CardPrice>$0 USD</CardPrice>
                <CardButton
                  grey={true}
                  onClick={() =>
                    window.open('https://app.arenatalent.com', '_blank')
                  }
                >
                  Post a Job
                </CardButton>
              </ShadeContainer>
              <BulletPointContainer>
                <BulletContainerTitle>Includes:</BulletContainerTitle>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>2</strong> job posts total on Arena
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>1</strong> recruiter seat
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Company profile
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Applicant tracking
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Featured email of your job(s) on Arena’s newsletter (6K subs)
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Featured post of your job(s) on Arena’s LinkedIn (16K followers)
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Limited AI applicant matches
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Limited AI candidate search filters
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Limited AI candidate sourcing tool
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Limited real-time candidate insights
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Limited direct messaging with applicants
                </BulletPoint>
              </BulletPointContainer>
            </Card>

            {/* Premium Plan */}
            <Card onClick={() => handlePlanClick('premium')}>
              <ShadeContainer>
                <TableTitle>Premium</TableTitle>
                <CardDescription>Best for individuals hiring a few people a year.</CardDescription>
                <CardPrice>
                </CardPrice>
                <CardButton onClick={handleOpenUpgradeLink}>
                  Talk to sales
                </CardButton>
              </ShadeContainer>
              <BulletPointContainer>
                <BulletContainerTitle>Includes:</BulletContainerTitle>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>5</strong> rotating job posts on Arena
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>5</strong> recruiter
                  seats
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Company profile
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Applicant tracking
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Featured emails of your jobs on Arena’s newsletter (6K subs)
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Featured posts of your jobs on Arena’s LinkedIn (16K followers)
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited AI applicant matches</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited real-time candidate insights</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited direct messaging with applicants</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited AI job description optimizing</strong>
                </BulletPoint>
              </BulletPointContainer>
            </Card>

            {/* Enterprise Plan */}
            <Card
              onClick={() => handlePlanClick('enterprise')}
            >
              <ShadeContainer>
              <TableTitle>Enterprise</TableTitle>
              <CardDescription>
                Best for HR & Talent Acquisition teams who need to source for competitive roles or hire regularly throughout the year.
              </CardDescription>
              <CardPrice></CardPrice>
              <CardButton onClick={handleOpenUpgradeLink}>
                Talk to sales
              </CardButton>
              </ShadeContainer>
              <BulletPointContainer>
                <BulletContainerTitle>Includes:</BulletContainerTitle>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>Unlimited</strong> job posts on Arena
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>Unlimited</strong>{' '}
                  recruiter seats
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Company profile
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Applicant tracking
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Featured emails of your jobs on Arena’s newsletter (6K subs)
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Featured posts of your jobs on Arena’s LinkedIn (16K followers)
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited AI applicant matches</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited AI candidate searches</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited AI candidate sourcing tool</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited real-time candidate insights</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited direct messaging with applicants</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Unlimited AI job description optimizing</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color='#c471ed' icon={faCheckCircle} /><Emoji style= {{width: '1rem', marginLeft: '-0.5rem'}} src="/images/sparkle.png" alt="Sparkle Emoji" /><strong>Free add on: Custom ATS integration</strong>
                </BulletPoint>
              </BulletPointContainer>
            </Card>
          </CardContainer>
        </ContentContainer>
      </PageContainer>
    </PageWrapper>
  )
}
