import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { createOrUpdateResource } from '../models/Resource'

const allSubcategories = [
  'Esports/Gaming',
  'Events',
  'Fashion',
  'Film/TV',
  'Media',
  'Music',
  'Sports',
  'Technology'
].sort()

const topicOptions = [
  'AI',
  'Business Development',
  'Communications/Public Relations',
  'Coaching',
  'Content/Digital',
  'Creative',
  'Customer Support',
  'Data Analytics',
  'Diversity, Equity, Inclusion & Belonging',
  'Engineering',
  'Finance/Accounting',
  'Gaming',
  'Human Resources',
  'Journalism',
  'Legal',
  'Marketing',
  'Medical/Training',
  'Officiating',
  'Operations',
  'Partnerships/Sponsorships',
  'Philanthropy',
  'Production/Studios',
  'Professional Development',
  'Sales',
  'Scouting',
  'Strategy',
  'Job Searching',
  'Talent Management',
  'Tech & Engineering',
  'Venue Operations',
  'Events/Hospitality'
].sort()

const levelOptions = ['Student', 'Early Career', 'Mid Career', 'Executive']
const locationOptions = ['Virtual', 'In-Person', 'Hybrid']
const targetAudienceOptions = [
  'Asian Professionals',
  'Athletes',
  'Black Professionals',
  'Disabled Professionals',
  'Hispanic/Latino Professionals',
  'Indigenous Professionals',
  'LGBTQIA+',
  'Veterans',
  'Women',
  'All'
]
const deliveryFormatOptions = ['Virtual', 'In-Person', 'Hybrid']
const resourceTypes = [
  'Books',
  'Courses',
  'Coaching',
  'Certifications',
  'Events',
  'Media',
  'Newsletters',
  'Podcasts',
  'Schools'
]
// Styled Components
const FormContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`

const Section = styled.div`
  margin-bottom: 2rem;
`

const SectionTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

const Label = styled.label`
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 0.5rem;
`

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.9rem;
  &:focus {
    border-color: #7f9cf5;
    outline: none;
    box-shadow: 0 0 0 2px rgba(127, 156, 245, 0.4);
  }
`

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.9rem;
  &:focus {
    border-color: #7f9cf5;
    outline: none;
    box-shadow: 0 0 0 2px rgba(127, 156, 245, 0.4);
  }
`

const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.9rem;
  resize: vertical;
  &:focus {
    border-color: #7f9cf5;
    outline: none;
    box-shadow: 0 0 0 2px rgba(127, 156, 245, 0.4);
  }
`

const Button = styled.button`
  width: 100%;
  background: ${(props) => (props.disabled ? '#ccc' : '#4a90e2')};
  color: white;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background 0.2s ease;

  &:hover {
    background: ${(props) => (props.disabled ? '#ccc' : '#357abd')};
  }
`

const ErrorMessage = styled.div`
  padding: 0.75rem;
  background: #ffe4e4;
  color: #d8000c;
  border: 1px solid #f5c6cb;
  border-radius: 6px;
  margin-bottom: 1rem;
`

const SuccessMessage = styled.div`
  padding: 0.75rem;
  background: #e6ffed;
  color: #2d862d;
  border: 1px solid #c3e6cb;
  border-radius: 6px;
  margin-bottom: 1rem;
`

const MultiSelectContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1rem;
  max-height: 150px;
  overflow-y: auto;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const Checkbox = styled.input`
  margin-right: 0.5rem;
`

const ImagePreviewContainer = styled.div`
  width: 200px;
  aspect-ratio: 1;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 0.5rem;
  border: 1px solid #e2e8f0;
  position: relative;
  padding: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: white;
    border-radius: 8px;
  }
`

const PreviewLabel = styled.div`
  font-size: 0.8rem;
  color: #718096;
  margin-top: 0.25rem;
  font-style: italic;
`

// Add these styled components
const ValidationMessage = styled.div`
  font-size: 0.8rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ValidationSuccess = styled(ValidationMessage)`
  color: #2d862d;
`

const ValidationError = styled(ValidationMessage)`
  color: #d8000c;
`

const ValidationList = styled.ul`
  margin: 0.5rem 0;
  padding-left: 1.5rem;

  li {
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
  }
`
const RequiredLabel = styled.span`
  color: #fc8181;
  margin-left: 0.25rem;
`

// Image validation constants
const IMAGE_CONSTRAINTS = {
  maxWidth: 1200,
  maxHeight: 1200,
  minWidth: 400,
  minHeight: 400,
  maxSizeInMB: 2,
  aspectRatio: 1,
  acceptedFormats: ['image/jpeg', 'image/png', 'image/webp']
}

const MultiSelect = ({ options, value, onChange, label }) => {
  const handleCheckboxChange = (option) => {
    const newValue = value.includes(option)
      ? value.filter((item) => item !== option)
      : [...value, option]
    onChange(newValue)
  }

  return (
    <div>
      <Label>{label}</Label>
      <MultiSelectContainer>
        {options.map((option) => (
          <CheckboxContainer key={option}>
            <Checkbox
              type="checkbox"
              checked={value.includes(option)}
              onChange={() => handleCheckboxChange(option)}
            />
            <span>{option}</span>
          </CheckboxContainer>
        ))}
      </MultiSelectContainer>
    </div>
  )
}

const ResourceForm = () => {
  const [formData, setFormData] = useState({
    resourceType: '',
    heading: '',
    subheading: '',
    startDate: '',
    endDate: '',
    description: '',
    learnMore: '',
    industry: '',
    subcategories: [],
    topic: [],
    careerLevel: [],
    location: [],
    targetAudience: [],
    deliveryFormat: [],
    city: '',
    state: '',
    country: ''
  })

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  // Validation helpers
  const validateForm = (formData) => {
    const errors = []

    // Required fields validation
    const requiredFields = {
      resourceType: 'Resource Type',
      heading: 'Heading',
      description: 'Description'
    }

    Object.entries(requiredFields).forEach(([field, label]) => {
      if (!formData[field]) {
        errors.push(`${label} is required`)
      }
    })

    // Description length validation
    if (formData.description) {
      if (formData.description.length > 200) {
        errors.push(
          `Description must be 200 characters or less (currently ${formData.description.length})`
        )
      }
    }

    // Date validation
    if (formData.startDate && formData.endDate) {
      const start = new Date(formData.startDate)
      const end = new Date(formData.endDate)
      if (end < start) {
        errors.push('End date cannot be before start date')
      }
    }

    // URL validation
    if (formData.learnMore && !isValidUrl(formData.learnMore)) {
      errors.push('Please enter a valid URL for Learn More')
    }
    // Check if Industries are selected
    if (!formData.subcategories || formData.subcategories.length === 0) {
      errors.push('Select at least one Industry.') 
    }

    // Check if Topic is selected
    if (!formData.topic || formData.topic.length === 0) {
      errors.push('Select at least one Topic.')
    }

    // Check if Career Level is selected
    if (!formData.careerLevel || formData.careerLevel.length === 0) {
      errors.push('Select at least one Career Level.')
    }

    // Check if Location is selected
    if (!formData.location || formData.location.length === 0) {
      errors.push('Select a Location.')
    }


    // Location validation for in person events
    if (formData.location.includes('In-Person')) {
      if (!formData.city) errors.push('City is required for in-person events')
      if (!formData.state) errors.push('State is required for in-person events')
      if (!formData.country)
        errors.push('Country is required for in-person events')
    }
    // Check if Target Audience is selected
    if (!formData.targetAudience || formData.targetAudience.length === 0) {
      errors.push('Select at least one Target Audience.')
    }

    return errors
  }

  const isValidUrl = (string) => {
    try {
      new URL(string)
      return true
    } catch (_) {
      return false
    }
  }

  const validateImage = async (file) => {
    if (!file) return null

    const errors = []

    // File size validation
    const fileSizeInMB = file.size / (1024 * 1024)
    if (fileSizeInMB > IMAGE_CONSTRAINTS.maxSizeInMB) {
      errors.push(
        `Image size must be under ${
          IMAGE_CONSTRAINTS.maxSizeInMB
        }MB (current size: ${fileSizeInMB.toFixed(2)}MB)`
      )
    }

    // File type validation
    if (!IMAGE_CONSTRAINTS.acceptedFormats.includes(file.type)) {
      errors.push(
        `Image must be in ${IMAGE_CONSTRAINTS.acceptedFormats
          .map((format) => format.split('/')[1])
          .join(', ')} format`
      )
    }

    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = URL.createObjectURL(file)

      img.onload = () => {
        URL.revokeObjectURL(img.src)

        if (
          img.width < IMAGE_CONSTRAINTS.minWidth ||
          img.height < IMAGE_CONSTRAINTS.minHeight
        ) {
          errors.push(
            `Image dimensions must be at least ${IMAGE_CONSTRAINTS.minWidth}x${IMAGE_CONSTRAINTS.minHeight}px (current: ${img.width}x${img.height}px)`
          )
        }

        if (
          img.width > IMAGE_CONSTRAINTS.maxWidth ||
          img.height > IMAGE_CONSTRAINTS.maxHeight
        ) {
          errors.push(
            `Image dimensions must not exceed ${IMAGE_CONSTRAINTS.maxWidth}x${IMAGE_CONSTRAINTS.maxHeight}px (current: ${img.width}x${img.height}px)`
          )
        }

        const aspectRatio = img.width / img.height
        const allowedDeviation = 0.1
        if (
          Math.abs(aspectRatio - IMAGE_CONSTRAINTS.aspectRatio) >
          allowedDeviation
        ) {
          errors.push(
            `Image must be square (current aspect ratio: ${aspectRatio.toFixed(
              2
            )}:1)`
          )
        }

        if (errors.length > 0) {
          reject(errors)
        } else {
          resolve(file)
        }
      }

      img.onerror = () => {
        URL.revokeObjectURL(img.src)
        reject(['Invalid or corrupted image file'])
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')
    setSuccess(false)

    try {
      // Validate form fields
      const formErrors = validateForm(formData)

      // Validate image if one is provided
      if (formData.headerImage) {
        try {
          await validateImage(formData.headerImage)
        } catch (imageErrors) {
          formErrors.push(
            ...(Array.isArray(imageErrors) ? imageErrors : [imageErrors])
          )
        }
      }

      // If there are any errors, throw them
      if (formErrors.length > 0) {
        throw new Error(JSON.stringify(formErrors)) // Convert array to string
      }

      // Generate a unique resource ID
      const resourceId = `resource_${Date.now()}`

      // Create/update the resource
      const { headerImage, ...resourceData } = formData
      const result = await createOrUpdateResource(
        resourceId,
        resourceData,
        headerImage
      )

      setSuccess(true)
      // Reset form after successful submission
      setFormData({
        resourceType: '',
        heading: '',
        subheading: '',
        startDate: '',
        endDate: '',
        description: '',
        learnMore: '',
        industry: '',
        subcategories: [],
        topic: [],
        careerLevel: [],
        location: [],
        targetAudience: [],
        deliveryFormat: [],
        city: '',
        state: '',
        country: ''
      })
    } catch (err) {
      console.error('Error submitting form:', err)
      // Handle different types of errors
      let errorMessage
      try {
        // Try to parse JSON string back to array
        const errorArray = JSON.parse(err.message)
        if (Array.isArray(errorArray)) {
          errorMessage = (
            <ul>
              {errorArray.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )
        } else {
          errorMessage = err.message
        }
      } catch {
        // If not JSON, just use the error message directly
        errorMessage =
          err.message || 'Error uploading resource. Please try again.'
      }
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  // Update how errors are rendered in the form
  {
    error && (
      <ErrorMessage>{typeof error === 'string' ? error : error}</ErrorMessage>
    )
  }

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <h2>Upload Resource</h2>
        {success && (
          <SuccessMessage>Resource uploaded successfully!</SuccessMessage>
        )}

        <Section>
          <SectionTitle>General Information</SectionTitle>
          <InputGroup>
            <Label>Resource Type<RequiredLabel>*</RequiredLabel></Label>
            <Select
              name="resourceType"
              value={formData.resourceType}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Resource Type</option>
              {resourceTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
          </InputGroup>
          <InputGroup>
            <Label>Heading<RequiredLabel>*</RequiredLabel></Label>
            <Input
              type="text"
              name="heading"
              value={formData.heading}
              onChange={handleInputChange}
              required
            />
          </InputGroup>
          <InputGroup>
            <Label>Subheading<RequiredLabel>*</RequiredLabel></Label>
            <Input
              type="text"
              name="subheading"
              value={formData.subheading}
              onChange={handleInputChange}
              required
            />
          </InputGroup>
          <InputGroup>
            <Label>Start Date</Label>
            <Input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
            />
          </InputGroup>
          <InputGroup>
            <Label>End Date</Label>
            <Input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
            />
          </InputGroup>
          <InputGroup>
            <Label>Description (max 200 characters)<RequiredLabel>*</RequiredLabel></Label>
            <TextArea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="4"
              maxLength={200} 
              required
            />
            <div
              style={{
                textAlign: 'right',
                fontSize: '0.8rem',
                color:
                  formData.description?.length >= 180 ? '#d8000c' : '#718096'
              }}
            >
              {formData.description?.length || 0}/200 characters
            </div>
          </InputGroup>
          <InputGroup>
            <Label>Learn More (URL)<RequiredLabel>*</RequiredLabel></Label>
            <Input
              type="url"
              name="learnMore"
              value={formData.learnMore}
              onChange={handleInputChange}
              required
            />
          </InputGroup>
          <InputGroup>
            <Label>
              Header Image (Square 400x400 to 1200x1200 px, max 2MB)<RequiredLabel>*</RequiredLabel>
            </Label>
            <Input
              type="file"
              name="headerImage"
              accept="image/jpeg,image/png,image/webp"
              required
              onChange={(e) => {
                const file = e.target.files[0]
                if (file) {
                  setFormData((prev) => ({
                    ...prev,
                    headerImage: file
                  }))
                }
              }}
            />
            {formData.headerImage && (
              <>
                <ImagePreviewContainer>
                  <img
                    src={URL.createObjectURL(formData.headerImage)}
                    alt="Preview"
                    onLoad={(e) => URL.revokeObjectURL(e.target.src)}
                  />
                </ImagePreviewContainer>
                <PreviewLabel>
                  Preview of how image will appear in resource card
                </PreviewLabel>
              </>
            )}
          </InputGroup>
        </Section>

        <Section>
          <SectionTitle>Additional Details</SectionTitle>
          <InputGroup>
            <Label>Industry<RequiredLabel>*</RequiredLabel></Label>
            <MultiSelect
              options={allSubcategories}
              value={formData.subcategories}
              onChange={(selected) =>
                setFormData((prev) => ({ ...prev, subcategories: selected }))
              }
            />
          </InputGroup>
          
          <InputGroup>
            <Label>Topics<RequiredLabel>*</RequiredLabel></Label>
            <MultiSelect
              options={topicOptions}
              value={formData.topic}
              onChange={(selected) =>
                setFormData((prev) => ({ ...prev, topic: selected }))
              }
            />
          </InputGroup>
          <InputGroup>
            <Label>Career Levels<RequiredLabel>*</RequiredLabel></Label>
            <MultiSelect
              options={levelOptions}
              value={formData.careerLevel}
              onChange={(selected) =>
                setFormData((prev) => ({ ...prev, careerLevel: selected }))
              }
            />
          </InputGroup>
          <InputGroup>
            <Label>Location<RequiredLabel>*</RequiredLabel></Label>
            <MultiSelect
              options={locationOptions}
              value={formData.location}
              onChange={(selected) =>
                setFormData((prev) => ({ ...prev, location: selected }))
              }
            />
          </InputGroup>

          {formData.location.includes('In-Person') && (
            <>
              <InputGroup>
                <Label>City<RequiredLabel>*</RequiredLabel></Label>
                <Input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </InputGroup>
              <InputGroup>
                <Label>State<RequiredLabel>*</RequiredLabel></Label>
                <Input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                />
              </InputGroup>
              <InputGroup>
                <Label>Country<RequiredLabel>*</RequiredLabel></Label>
                <Input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </>
          )}

          <InputGroup>
            <Label>Target Audience<RequiredLabel>*</RequiredLabel></Label>
            <MultiSelect
              options={targetAudienceOptions}
              value={formData.targetAudience}
              onChange={(selected) =>
                setFormData((prev) => ({ ...prev, targetAudience: selected }))
              }
            />
          </InputGroup>
        </Section>
        {error && <ErrorMessage>{error}</ErrorMessage>}

        <Button type="submit" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload Resource'}
        </Button>
      </form>
    </FormContainer>
  )
}

export default ResourceForm
