import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom'
import {
  X,
  Share2,
  Briefcase,
  Users,
  Monitor,
  Coffee,
  Train,
  CheckCircle,
  Info,
  ArrowLeft,
  Heart,
  Calendar,
  Eye,
  GraduationCap,
  Laptop,
  Smile,
  Shield,
  Building,
  MapPin,
  Clock,
  BanknoteIcon,
  Upload,
  Share,
  SquareArrowUpRight
} from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { Hash } from 'lucide-react'

import {
  getJobPosting,
  incrementJobPostingApplicants
} from '../../models/JobPosting'
import { db, auth, storage } from '../../firebase'
import {
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import DOMPurify from 'dompurify'

import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'
import { getEmployerProfile } from '../../models/EmployerProfile'
import { createJobApplication } from '../../models/JobApplication'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const ContentWrapper = styled.div`
  flex: 1;
  padding: 2rem;
  max-width: 900px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: none;
  color: #4a5568;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  margin-bottom: 1rem;

  &:hover {
    color: #2d3748;
  }
`

const JobHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  gap: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0.75rem;
    gap: 0.25rem;
    margin-bottom: 1rem;
  }
`

const JobTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: bold;
  margin: 0;
  color: #1a202c;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const CompanyName = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  color: #4a5568;
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`

const JobLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #718096;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media (max-width: 768px) {
    align-self: flex-end;
  }
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.primary
      ? `
    background-color: #caaae1;
    color: white;
    border: none;
    &:hover {
      background-color: #7e22ce;
    }
  `
      : `
    background-color: white;
    color: #9333ea;
    border: 1px solid #9333ea;
    &:hover {
      background-color: #f3e8ff;
    }
  `}
`

const Section = styled.section`
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    padding: 0.75rem;
    margin-bottom: 1rem;
  }
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #1a202c;
  @media (max-width: 768px) {
    align-self: flex-end;
    gap: 0rem;
  }
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  li::marker {
    display: none;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin-bottom: 1rem;
  color: #4a5568;
  list-style: none;
  &::before {
    content: '';
    display: inline-block;
    min-width: 0.5rem;
    height: 0.5rem;
    margin-top: 0.5rem;
    background-color: #caaae1;
    border-radius: 50%;
  }
`
const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
`

const BenefitCard = styled.div`
  background-color: #f7fafc;
  padding: 1.5rem;
  border-radius: 0.75rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const BenefitIcon = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: #f3e8ff;
  color: #9333ea;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
`

const BenefitTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #1a202c;
`

const BenefitDescription = styled.p`
  color: #718096;
  font-size: 0.875rem;
`

const AboutRole = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
`

const AboutRoleItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const AboutRoleLabel = styled.span`
  font-size: 0.875rem;
  color: #718096;
  white-space: nowrap;
`

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const AboutRoleValue = styled.span`
  font-weight: 600;
  color: #1a202c;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`

const TooltipContent = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  ${TooltipContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }
`

const ShareModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 90%;
  max-width: 400px;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #718096;
  &:hover {
    color: #4a5568;
  }
`

const TinyUrl = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
`

const CompanyLogo = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: contain;
  background-color: #f8f9fa;
  margin-right: 1rem;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ResumeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`

const ResumeViewSection = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  background-color: #f8fafc;
`

const ResumeUploadSection = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1rem;
`

const ModalCompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
`

const ModalCompanyLogo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-bottom: 0.5rem;
`

const ModalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 0.5rem;
`

const SectionLabel = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 0.5rem;
  text-align: center;
`

const ResumeUploadArea = styled.div`
  border: 2px dashed #e2e8f0;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease-in-out;
  background-color: #fff;

  &:hover {
    border-color: #9333ea;
    background-color: #faf5ff;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  resize: vertical;
  font-family: 'Open Sans';
`

const ViewResumeButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #9333ea;
  color: #9333ea;
  border-radius: 0.5rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f3e8ff;
  }
`
const ModalCompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ExternalConfirmModal = styled(Modal)`
  max-width: 400px;
  text-align: center;
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
`

const ModalCompanyName = styled.h4`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: #1a202c;
`

const ModalJobTitle = styled.p`
  font-size: 1rem;
  color: #4a5568;
  margin: 0.25rem 0 0 0;
`

const RichTextContent = styled.div`
  color: #4a5568;

  /* Preserve line breaks and spacing */
  white-space: pre-wrap;

  /* Style basic elements */
  p,
  ul,
  ol {
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  /* Style lists */
  ul,
  ol {
    padding-left: 1.5rem;
  }

  /* Style headings */
  h1,
  h2,
  h3,
  h4 {
    margin: 1.5rem 0 1rem;
    color: #1a202c;
    font-weight: 600;
  }

  /* Style links */
  a {
    color: #805ad5;
    text-decoration: underline;
    &:hover {
      color: #6b46c1;
    }
  }

  /* Style bold and italic */
  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }
`

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`

const SkillTag = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f8f4fb;
  color: #805ad5;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
`

const ProBanner = styled.div`
  background-color: #d29856;
  padding: 6px 16px;
  position: fixed;
  margin-top: 10px;
  top: 0;
  right: 0;
  z-index: 1001;
  border-bottom-left-radius: 8px;
  font-family: 'Roboto', sans-serif;
`

const ProText = styled.span`
  color: white;
  font-size: 14px;
`

const Tooltip = ({ children, content }) => (
  <TooltipContainer>
    {children}
    <TooltipContent>{content}</TooltipContent>
  </TooltipContainer>
)

const JobSeekerJobView = () => {
  const { jobpostingId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [jobData, setJobData] = useState(null)
  const [employerProfile, setEmployerProfile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showShareModal, setShowShareModal] = useState(false)
  const [tinyUrl, setTinyUrl] = useState('')
  const [copied, setCopied] = useState(false)
  const [isApplied, setIsApplied] = useState(false)
  const [error, setError] = useState(null) // Add error state here
  const sanitizeConfig = {
    ALLOWED_TAGS: [
      'p',
      'br',
      'strong',
      'em',
      'u',
      'h1',
      'h2',
      'h3',
      'h4',
      'ul',
      'ol',
      'li',
      'a'
    ],
    ALLOWED_ATTR: ['href', 'target', 'rel'],
    ALLOWED_URI_REGEXP:
      /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i
  }

  const matchScore = location.state?.matchScore

  const companyName = location.state?.companyName || 'Unknown Company'
  const companyLogoUrl =
    location.state?.companyLogoUrl || '/api/placeholder/50/50'
  const [showApplyModal, setShowApplyModal] = useState(false)
  const [coverLetter, setCoverLetter] = useState('')
  const [userProfile, setUserProfile] = useState(null)

  const [currentResume, setCurrentResume] = useState('')
  const [showExternalConfirmModal, setShowExternalConfirmModal] =
    useState(false)

  const [resumeFile, setResumeFile] = useState(null)
  const getMatchColor = (matchPercentage) => {
    if (matchPercentage < 40) return '#e53e3e' // Red
    if (matchPercentage < 60) return '#ed8936' // Orange
    if (matchPercentage < 80) return '#ecc94b' // Yellow
    return '#22c55e' // Green
  }
  const benefitCategories = [
    {
      value: 'vacation',
      label: 'Time Off',
      icon: Calendar
    },
    {
      value: 'learning',
      label: 'Learning & Development',
      icon: GraduationCap
    },
    {
      value: 'remote',
      label: 'Remote Work',
      icon: Laptop
    },
    {
      value: 'health',
      label: 'Healthcare',
      icon: Heart
    },
    {
      value: 'wellness',
      label: 'Wellness',
      icon: Smile
    },
    {
      value: 'equipment',
      label: 'Equipment',
      icon: Monitor
    },
    {
      value: 'family',
      label: 'Family',
      icon: Users
    },
    {
      value: 'commuter',
      label: 'Commuter',
      icon: Train
    },
    {
      value: 'food',
      label: 'Food & Drinks',
      icon: Coffee
    },
    {
      value: 'retirement',
      label: 'Retirement',
      icon: BanknoteIcon
    },
    {
      value: 'insurance',
      label: 'Insurance',
      icon: Shield
    }
  ]

  useEffect(() => {
    const fetchUserProfile = async () => {
      const userId = auth.currentUser?.uid
      if (userId) {
        try {
          const profile = await getJobseekerProfile(userId)
          setUserProfile(profile)
        } catch (error) {
          console.error('Error fetching user profile:', error)
        }
      }
    }

    fetchUserProfile()
  }, [])

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true)
        if (!jobpostingId) {
          throw new Error('Job posting ID is missing')
        }

        const jobData = await getJobPosting(jobpostingId)
        if (!jobData) {
          throw new Error('Job posting not found')
        }
        setJobData(jobData)

        if (jobData.employer_id) {
          try {
            const profileData = await getEmployerProfile(jobData.employer_id)
            if (profileData) {
              setEmployerProfile({
                company_name: profileData.company_name || jobData.company_name,
                company_logo_url:
                  profileData.company_logo_url || '/api/placeholder/50/50',
                company_website: profileData.company_website,
                industry: profileData.industry
              })
            } else {
              const userDocRef = doc(db, 'users', jobData.employer_id)
              const userDocSnap = await getDoc(userDocRef)
              if (userDocSnap.exists()) {
                const userData = userDocSnap.data()
                setEmployerProfile({
                  company_name: userData.company_name || jobData.company_name,
                  company_logo_url: '/api/placeholder/50/50'
                })
              } else {
                setEmployerProfile({
                  company_name: jobData.company_name || 'Unknown Company',
                  company_logo_url: '/api/placeholder/50/50'
                })
              }
            }
          } catch (error) {
            console.error('Error fetching employer profile:', error)
            setEmployerProfile({
              company_name: jobData.company_name || 'Unknown Company',

              company_logo_url: '/api/placeholder/50/50'
            })
          }
        }

        setLoading(false)
      } catch (error) {
        console.error('Error fetching job details:', error)
        alert('Error loading job details')
        navigate('/job-search')
      }
    }

    fetchJobDetails()
  }, [jobpostingId, navigate])

  useEffect(() => {
    const checkApplicationStatus = async () => {
      const userId = auth.currentUser?.uid
      if (userId && jobpostingId && jobData?.application_type !== 'external') {
        try {
          const profile = await getJobseekerProfile(userId)
          setIsApplied(
            profile?.jobs_applied?.some((job) => job.job_id === jobpostingId) ||
              false
          )
        } catch (error) {
          console.error('Error checking application status:', error)
        }
      }
    }

    checkApplicationStatus()
  }, [jobpostingId, jobData?.application_type])

  useEffect(() => {
    const fetchCurrentResume = async () => {
      const userId = auth.currentUser?.uid
      if (userId) {
        try {
          const profile = await getJobseekerProfile(userId)
          setCurrentResume(
            profile?.resume_url ||
              'No resume found. Please upload a resume before applying.'
          )
        } catch (error) {
          console.error('Error fetching current resume:', error)
          setCurrentResume('Error fetching resume. Please try again later.')
        }
      }
    }

    fetchCurrentResume()
  }, [])
  const handleApply = () => {
    if (jobData.application_type === 'external') {
      // Format URL and open in new tab
      const formattedUrl = formatUrl(jobData.external_application_url)
      window.open(formattedUrl, '_blank')
      // Show confirmation modal
      setShowExternalConfirmModal(true)
    } else {
      setShowApplyModal(true)
    }
  }

  const formatUrl = (url) => {
    if (!url) return ''
    const trimmedUrl = url.trim()
    if (!trimmedUrl) return ''
    if (trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')) {
      return trimmedUrl
    }
    return `https://${trimmedUrl}`
  }

  const handleExternalApplyConfirm = async (didApply) => {
    if (didApply) {
      try {
        const applicationData = {
          jobseeker_id: auth.currentUser.uid,
          job_posting_id: jobpostingId,
          employer_id: jobData.employer_id,
          cover_letter: '',
          resume_url: currentResume,
          status: 'submitted',
          company_name: companyName,
          match_score: matchScore,
          company_logo_url:
            companyLogoUrl === '/api/placeholder/50/50' ? null : companyLogoUrl,
          job_title: jobData.title,
          department: jobData.department || '',
          location: jobData.location || '',
          work_policy: jobData.work_policy || '',
          type: Array.isArray(jobData.type) ? jobData.type : [],
          level: jobData.level || '',
          application_date: new Date().toISOString()
        }

        // Create the job application
        const applicationRef = await createJobApplication(applicationData)

        // Update the jobseeker profile
        const jobseekerProfile = await getJobseekerProfile(auth.currentUser.uid)
        const updatedAppliedJobs = [
          ...(jobseekerProfile?.jobs_applied || []),
          {
            job_id: jobpostingId,
            application_id: applicationRef.id,
            application_date: new Date().toISOString(),
            status: 'submitted',
            match_score: matchScore,
            company_name: companyName,
            company_logo_url:
              companyLogoUrl === '/api/placeholder/50/50'
                ? null
                : companyLogoUrl,
            job_title: jobData.title,
            department: jobData.department || '',
            location: jobData.location || '',
            work_policy: jobData.work_policy || ''
          }
        ]

        await updateJobseekerProfile(auth.currentUser.uid, {
          jobs_applied: updatedAppliedJobs
        })

        setIsApplied(true)
        setShowExternalConfirmModal(false)
        navigate('/application-tracking')
      } catch (error) {
        console.error('Error recording external application:', error)
        alert('Error recording your application. Please try again.')
      }
    } else {
      setShowExternalConfirmModal(false)
    }
  }

  const handleConfirmApply = async () => {
    const userId = auth.currentUser?.uid
    if (!userId) {
      alert('Please log in to apply for this job')
      return
    }

    try {
      setLoading(true)
      setError(null)

      // Validation
      if (!currentResume && !resumeFile) {
        throw new Error('Please provide a resume to apply')
      }

      // Ensure we have valid company data
      const finalCompanyName =
        companyName || jobData?.company_name || 'Unknown Company'
      const finalCompanyLogo =
        companyLogoUrl === '/api/placeholder/50/50' ? null : companyLogoUrl

      console.log('Application Details:', {
        companyName: finalCompanyName,
        companyLogo: finalCompanyLogo,
        originalLogoUrl: companyLogoUrl
      })

      let resumeUrl = currentResume
      if (resumeFile) {
        const storageRef = ref(storage, `resumes/${userId}/${resumeFile.name}`)
        await uploadBytes(storageRef, resumeFile)
        resumeUrl = await getDownloadURL(storageRef)
        await updateJobseekerProfile(userId, { resume_url: resumeUrl })
      }

      const applicationData = {
        jobseeker_id: userId,
        job_posting_id: jobpostingId,
        employer_id: jobData.employer_id,
        cover_letter: coverLetter || '',
        resume_url: resumeUrl,
        status: 'submitted',
        company_name: finalCompanyName,
        match_score: matchScore,
        company_logo_url: finalCompanyLogo || null,
        job_title: jobData.title || 'Untitled Position',
        department: jobData.department || '',
        location: jobData.location || '',
        work_policy: jobData.work_policy || '',
        type: Array.isArray(jobData.type) ? jobData.type : [],
        level: jobData.level || '',
        application_date: new Date().toISOString()
      }

      // Log the application data before submission
      console.log('Submitting application with data:', applicationData)

      // Create the job application
      const applicationRef = await createJobApplication(applicationData)

      // Update the jobseeker profile with the new application
      const jobseekerProfile = await getJobseekerProfile(userId)
      const updatedAppliedJobs = [
        ...(jobseekerProfile?.jobs_applied || []),
        {
          job_id: jobpostingId,
          application_id: applicationRef.id,
          application_date: new Date().toISOString(),
          status: 'submitted',
          match_score: matchScore,
          company_name: finalCompanyName,
          company_logo_url: finalCompanyLogo || null,
          job_title: jobData.title || 'Untitled Position',
          department: jobData.department || '',
          location: jobData.location || '',
          work_policy: jobData.work_policy || ''
        }
      ]

      await updateJobseekerProfile(userId, {
        jobs_applied: updatedAppliedJobs
      })

      // Update local state
      setIsApplied(true)
      setShowApplyModal(false)

      // Show success message and redirect
      navigate('/application-tracking')
    } catch (error) {
      console.error('Error submitting application:', error)
      setError(error.message || 'Error submitting application')
      alert(error.message || 'Error submitting application')
    } finally {
      setLoading(false)
    }
  }

  const createClickableLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-purple-600 hover:text-purple-800 underline">${url}</a>`
    )
  }
  const handleShare = () => {
    setTinyUrl(window.location.href)
    setShowShareModal(true)
  }

  const formatBulletPoints = (text) => {
    if (!text) return []

    // Split by common bullet point indicators
    const lines = text.split(/[\n\r]+/)

    return lines
      .map((line) => line.trim())
      .filter((line) => line) // Remove empty lines
      .map((line) => line.replace(/^[-•*]\s*/, '').trim()) // Remove bullet symbols
      .filter((line) => line) // Remove empty lines again after cleaning
  }

  const formatAdditionalCompensation = (additionalCompensation) => {
    if (!additionalCompensation || additionalCompensation.length === 0)
      return null
    return additionalCompensation.join(', ')
  }
  const handleCopyTinyUrl = () => {
    navigator.clipboard.writeText(tinyUrl)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  const formatSalary = (salary) => {
    if (!salary) return 'Not specified'

    // Handle hidden salary type
    if (salary.type === 'hidden') return 'Undisclosed'

    // Handle unpaid salary type
    if (salary.type === 'unpaid') return 'Unpaid'

    // Format salary range
    const formatNumber = (num) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(num)

    const range = `${formatNumber(salary.range_start)} - ${formatNumber(
      salary.range_end
    )}`
    return salary.type === 'hourly' ? `${range}/hr` : `${range}/year`
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleResumeUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      setResumeFile(file)
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file) {
      setResumeFile(file)
    }
  }

  if (loading) return <div>Loading...</div>
  if (!jobData) return <div>Job not found</div>

  return (
    <PageWrapper>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>
      <ContentWrapper>
        {userProfile?.plan === 'paid' && (
          <ProBanner>
            <ProText>Arena Pro✨</ProText>
          </ProBanner>
        )}
        <BackButton onClick={handleGoBack}>
          <ArrowLeft size={18} />
          Back
        </BackButton>
        <JobHeader>
          <CompanyInfo>
            <CompanyLogo
              src={companyLogoUrl}
              alt={`${companyName} logo`}
              onError={(e) => {
                e.target.src = '/api/placeholder/50/50'
              }}
            />
            <div>
              <JobTitle>{jobData.title}</JobTitle>
              <CompanyName>
                <Building size={18} />
                <span>{companyName}</span>
              </CompanyName>
            </div>
          </CompanyInfo>
          {userProfile?.plan === 'paid' && matchScore && (
            <AboutRoleItem>
              <AboutRoleLabel>Match Score</AboutRoleLabel>
              <AboutRoleValue style={{ color: getMatchColor(matchScore) }}>
                {matchScore}% Match
              </AboutRoleValue>
            </AboutRoleItem>
          )}
          <ButtonGroup>
            {!isApplied ? (
              jobData.application_type === 'external' ? (
                <Button primary onClick={handleApply}>
                  Apply on Company Site <SquareArrowUpRight size={16} />
                </Button>
              ) : (
                <Button primary onClick={handleApply}>
                  Apply Now
                </Button>
              )
            ) : (
              <Button primary disabled>
                Applied
              </Button>
            )}
          </ButtonGroup>
        </JobHeader>

        <Section>
          <SectionTitle>About this role</SectionTitle>
          <AboutRole>
            {jobData.department && (
              <AboutRoleItem>
                <AboutRoleLabel>Department</AboutRoleLabel>
                <AboutRoleValue>{jobData.department}</AboutRoleValue>
              </AboutRoleItem>
            )}
            {jobData.level && (
              <AboutRoleItem>
                <AboutRoleLabel>Job Level</AboutRoleLabel>
                <AboutRoleValue>{jobData.level}</AboutRoleValue>
              </AboutRoleItem>
            )}
            {jobData.type && jobData.type.length > 0 && (
              <AboutRoleItem>
                <AboutRoleLabel>Job Type</AboutRoleLabel>
                <AboutRoleValue>
                  <Clock size={16} />
                  <span>
                    {Array.isArray(jobData.type)
                      ? jobData.type.join(', ')
                      : jobData.type}
                  </span>
                </AboutRoleValue>
              </AboutRoleItem>
            )}
            {jobData.work_policy && (
              <AboutRoleItem>
                <AboutRoleLabel>Work Policy</AboutRoleLabel>
                <AboutRoleValue>{jobData.work_policy}</AboutRoleValue>
              </AboutRoleItem>
            )}
            {jobData.salary &&
              !isNaN(jobData.salary.range_start) &&
              !isNaN(jobData.salary.range_end) && (
                <AboutRoleItem>
                  <AboutRoleLabel>Compensation</AboutRoleLabel>
                  <AboutRoleValue
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                      }}
                    >
                      <BanknoteIcon size={18} />
                      {formatSalary(jobData.salary)}
                    </div>
                    {jobData.additional_compensation?.length > 0 && (
                      <div
                        style={{
                          fontSize: '0.875rem',
                          color: '#6B7280',
                          marginTop: '0.25rem',
                          paddingLeft: '1.75rem'
                        }}
                      >
                        +{' '}
                        {formatAdditionalCompensation(
                          jobData.additional_compensation
                        )}
                      </div>
                    )}
                  </AboutRoleValue>
                </AboutRoleItem>
              )}
            {jobData.location && (
              <AboutRoleItem>
                <AboutRoleLabel>Location</AboutRoleLabel>
                <AboutRoleValue>{jobData.location}</AboutRoleValue>
              </AboutRoleItem>
            )}
          </AboutRole>
        </Section>

        {jobData.description && (
          <Section>
            <SectionTitle>Job Summary</SectionTitle>
            <RichTextContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(jobData.description, sanitizeConfig)
              }}
            />
          </Section>
        )}

        {jobData.responsibilities && (
          <Section>
            <SectionTitle>Responsibilities</SectionTitle>
            <RichTextContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  jobData.responsibilities,
                  sanitizeConfig
                )
              }}
            />
          </Section>
        )}

        {jobData.requirements && (
          <Section>
            <SectionTitle>Requirements</SectionTitle>
            <RichTextContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(jobData.requirements, sanitizeConfig)
              }}
            />
          </Section>
        )}

        {jobData.nice_to_haves && (
          <Section>
            <SectionTitle>Preferred Qualifications</SectionTitle>
            <RichTextContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  jobData.nice_to_haves,
                  sanitizeConfig
                )
              }}
            />
          </Section>
        )}
        {jobData.recommended_skills &&
          jobData.recommended_skills.length > 0 && (
            <Section>
              <SectionTitle>Required Skills</SectionTitle>
              <List>
                {jobData.recommended_skills.map((skill, index) => (
                  <ListItem key={index}>{skill}</ListItem>
                ))}
              </List>
            </Section>
          )}

        {jobData.technical_skills?.length > 0 && (
          <Section>
            <SectionTitle>Technical Skills</SectionTitle>
            <SkillsContainer>
              {jobData.technical_skills.map((skill, index) => (
                <SkillTag key={index}>
                  <Hash size={16} />
                  {skill}
                </SkillTag>
              ))}
            </SkillsContainer>
          </Section>
        )}

        {jobData.benefits && jobData.benefits.length > 0 && (
          <Section>
            <SectionTitle>Benefits</SectionTitle>
            <BenefitsGrid>
              {jobData.benefits.map((benefit, index) => {
                const categoryInfo = benefitCategories.find(
                  (cat) => cat.value === benefit.category.toLowerCase()
                ) || { icon: Briefcase, label: benefit.category }

                const Icon = categoryInfo.icon

                return (
                  <BenefitCard key={index}>
                    <BenefitIcon>
                      <Icon size={24} />
                    </BenefitIcon>
                    <BenefitTitle>{categoryInfo.label}</BenefitTitle>
                    <BenefitDescription>
                      {benefit.description}
                    </BenefitDescription>
                  </BenefitCard>
                )
              })}
            </BenefitsGrid>
          </Section>
        )}

        {showApplyModal && (
          <>
            <Modal>
              <ModalHeader>
                <ModalTitle>Apply for this job</ModalTitle>
                <CloseButton onClick={() => setShowApplyModal(false)}>
                  <X size={18} />
                </CloseButton>
              </ModalHeader>
              <ModalContent>
                <ModalCompanyInfo>
                  <ModalCompanyLogo
                    src={companyLogoUrl}
                    alt={`${companyName} logo`}
                    onError={(e) => {
                      e.target.src = '/api/placeholder/50/50'
                    }}
                  />
                  <ModalCompanyDetails>
                    <ModalCompanyName>{companyName}</ModalCompanyName>
                    <ModalJobTitle>{jobData.title}</ModalJobTitle>
                  </ModalCompanyDetails>
                </ModalCompanyInfo>

                <ResumeSection>
                  <ResumeViewSection>
                    <SectionLabel>Apply With Current Resume</SectionLabel>
                    {currentResume.startsWith('http') ? (
                      <ViewResumeButton
                        href={currentResume}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Eye size={18} />
                        View Resume
                      </ViewResumeButton>
                    ) : (
                      <p>{currentResume}</p>
                    )}
                  </ResumeViewSection>

                  <ResumeUploadSection>
                    <SectionLabel>Or Upload New Resume</SectionLabel>
                    <ResumeUploadArea
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      onClick={() =>
                        document.getElementById('resumeUpload').click()
                      }
                    >
                      {resumeFile ? (
                        <>
                          <CheckCircle size={24} color="#9333ea" />
                          <p>{resumeFile.name}</p>
                        </>
                      ) : (
                        <>
                          <Upload size={24} />
                          <p>Drag and drop or click to upload</p>
                        </>
                      )}
                    </ResumeUploadArea>
                    <input
                      id="resumeUpload"
                      type="file"
                      accept=".pdf,.doc,.docx"
                      onChange={handleResumeUpload}
                      style={{ display: 'none' }}
                    />
                  </ResumeUploadSection>
                </ResumeSection>

                <div>
                  <SectionLabel>Cover Letter</SectionLabel>
                  <TextArea
                    value={coverLetter}
                    onChange={(e) => setCoverLetter(e.target.value)}
                    placeholder="Enter your cover letter here..."
                  />
                </div>

                <ButtonGroup>
                  <Button onClick={() => setShowApplyModal(false)}>
                    Cancel
                  </Button>
                  <Button primary onClick={handleConfirmApply}>
                    Submit Application
                  </Button>
                </ButtonGroup>
              </ModalContent>
            </Modal>
            <Backdrop onClick={() => setShowApplyModal(false)} />
          </>
        )}
        {showExternalConfirmModal && (
          <>
            <ExternalConfirmModal>
              <ModalHeader>
                <ModalTitle>Did you apply?</ModalTitle>
                <CloseButton onClick={() => setShowExternalConfirmModal(false)}>
                  <X size={18} />
                </CloseButton>
              </ModalHeader>
              <ModalContent>
                <p>
                  Did you complete your application on the company's website?
                </p>
                <ModalButtons>
                  <Button onClick={() => handleExternalApplyConfirm(false)}>
                    No
                  </Button>
                  <Button
                    primary
                    onClick={() => handleExternalApplyConfirm(true)}
                  >
                    Yes, I Applied
                  </Button>
                </ModalButtons>
              </ModalContent>
            </ExternalConfirmModal>
            <Backdrop onClick={() => setShowExternalConfirmModal(false)} />
          </>
        )}
      </ContentWrapper>
    </PageWrapper>
  )
}

export default JobSeekerJobView
