export const MATCH_WEIGHTS = {
  skills: {
    weight: 0.45, // Increase to reflect importance
    required: 0.6,
    preferred: 0.5,
    technical: 0.6,
    recommended: 0.4
  },
  experience: {
    weight: 0.3,
    years: 0.51,
    level: 0.5
  },
  education: {
    weight: 0.15 // Increased for critical roles
  },
  location: {
    weight: 0.1,
    remote: 1.0,
    hybrid: 0.43, // Reduced for partial match
    onsite: 0.2 // Further reduced for mismatches
  },
  industry: {
    weight: 0.415, // Slightly reduced
    direct: 0.61,
    related: 0.5
  },
  compensation: {
    weight: 0.05 // Increased for better impact
  }
}

export const EDUCATION_LEVELS = {
  'High School': 1,
  Associate: 2,
  Bachelor: 3,
  Master: 4,
  PhD: 5,
  Other: 1
}

export const CAREER_LEVELS = {
  Internship: 1,
  'Entry Level': 2,
  'Mid Level': 3,
  'Senior Level': 4,
  Manager: 5,
  Director: 6,
  Executive: 7
}

const Match = (job, jobSeekerProfile, employerProfile) => {
  if (!jobSeekerProfile) return { totalScore: 0, breakdown: {}, details: [] }

  const skillsMatch = calculateSkillsMatch(job, jobSeekerProfile)
  const experienceMatch = calculateExperienceMatch(job, jobSeekerProfile)
  const educationMatch = calculateEducationMatch(job, jobSeekerProfile)
  const locationMatch = calculateLocationMatch(job, jobSeekerProfile)
  const industryMatch = calculateIndustryMatch(
    job,
    jobSeekerProfile,
    employerProfile
  )
  const compensationMatch = calculateCompensationMatch(job, jobSeekerProfile)

  // Normalize scores by dividing by total weight
  const totalWeight =
    MATCH_WEIGHTS.skills.weight +
    MATCH_WEIGHTS.experience.weight +
    MATCH_WEIGHTS.education.weight +
    MATCH_WEIGHTS.location.weight +
    MATCH_WEIGHTS.industry.weight +
    MATCH_WEIGHTS.compensation.weight

  const totalScore =
    (skillsMatch.score * MATCH_WEIGHTS.skills.weight +
      experienceMatch.score * MATCH_WEIGHTS.experience.weight +
      educationMatch.score * MATCH_WEIGHTS.education.weight +
      locationMatch.score * MATCH_WEIGHTS.location.weight +
      industryMatch.score * MATCH_WEIGHTS.industry.weight +
      compensationMatch.score * MATCH_WEIGHTS.compensation.weight) /
    totalWeight

  return {
    totalScore: Math.round(totalScore * 100),
    breakdown: {
      skills: skillsMatch.score,
      experience: experienceMatch.score,
      education: educationMatch.score,
      location: locationMatch.score,
      industry: industryMatch.score,
      compensation: compensationMatch.score
    },
    details: [
      ...skillsMatch.details,
      ...experienceMatch.details,
      ...educationMatch.details,
      ...locationMatch.details,
      ...industryMatch.details,
      ...compensationMatch.details
    ]
  }
}

const calculateSkillsMatch = (job, profile) => {
  const details = []
  const profileSkills = new Set(
    [
      ...(profile.technical_skills || []),
      ...(profile.other_technical_skills || [])
    ].map((s) => s.toLowerCase())
  )

  const technicalSkills = new Set(
    (job.technical_skills || []).map((s) => s.toLowerCase())
  )
  const recommendedSkills = new Set(
    (job.recommended_skills || []).map((s) => s.toLowerCase())
  )

  const matchedSkills = [...profileSkills].filter((s) => technicalSkills.has(s))
  const missingSkills = [...technicalSkills].filter(
    (s) => !profileSkills.has(s)
  )

  // Randomized feedback for matched skills
  const matchedSkillsFeedback = [
    'You have a strong alignment with the required skills for this role.',
    'Your skill set matches many of the key requirements for this position.',
    'You bring the technical expertise needed to excel in this job.',
    'Your skills align well with the expectations of this role.',
    'You meet a significant portion of the technical skills required for this job.',
    'You demonstrate the necessary skills for success in this position.',
    'Your skills make you a great candidate for this role.',
    'You are well-prepared for this job based on your technical expertise.',
    'Your skill set is a strong match for what the employer is seeking.',
    'You bring key skills that are essential for this position.'
  ]

  if (matchedSkills.length > 0) {
    details.push(
      matchedSkillsFeedback[
        Math.floor(Math.random() * matchedSkillsFeedback.length)
      ] + ` Skills matched: ${matchedSkills.join(', ')}.`
    )
  }

  // Randomized feedback for missing skills
  const missingSkillsFeedback = [
    'Some of the required skills for this role are missing from your profile.',
    'Your skill set does not fully cover the requirements for this position.',
    'You lack some critical skills needed for this job.',
    'There are specific skills mentioned in the job description that you do not possess.',
    'Your skill set has some gaps compared to the role requirements.',
    'This position requires expertise in areas where you are less experienced.',
    'You may need to develop additional skills to meet the job requirements.',
    'Certain skills mentioned in the job description are not reflected in your profile.',
    'Your skill set does not fully align with the technical needs of this role.',
    'You are missing some of the key skills required for this job.'
  ]

  if (missingSkills.length > 0) {
    details.push(
      missingSkillsFeedback[
        Math.floor(Math.random() * missingSkillsFeedback.length)
      ] + ` Missing skills: ${missingSkills.join(', ')}.`
    )
  }

  // Apply a penalty for missing skills
  const missingPenalty = missingSkills.length > 0 ? 0.8 : 1 // 20% penalty for missing skills
  const technicalScore =
    (matchedSkills.length / (technicalSkills.size || 1)) * missingPenalty

  return { score: technicalScore, details }
}

const calculateExperienceMatch = (job, profile) => {
  const details = []
  const profileYears = parseInt(profile.years_experience?.split('-')[0]) || 0
  const requiredYears =
    parseInt(job.requirements?.match(/(\d+)\+?\s*years?/)?.[1]) || 0

  // Randomized feedback for meeting or exceeding experience
  const experienceFeedback = [
    'You have the right amount of experience for this role.',
    'Your years of experience meet the requirements for this position.',
    'Your experience aligns well with the expectations for this job.',
    'You meet the required years of experience for this role.',
    'Your background demonstrates sufficient experience for this job.',
    'You bring the exact amount of experience needed for this position.',
    "Your experience makes you a strong candidate for this role's requirements.",
    'You bring a solid level of experience that matches the job expectations.'
  ]

  // Randomized feedback for insufficient experience
  const insufficientExperienceFeedback = [
    'You do not meet the required years of experience for this role.',
    'Your experience is less than what is needed for this position.',
    'This job requires more experience than you currently have.',
    'You may need additional years of experience to fully match this job.',
    "Your level of experience does not align with the job's requirements.",
    'The years of experience required for this job exceed your current experience.'
  ]

  if (requiredYears === 0) {
    details.push(
      'This job does not specify how many years of experience are required.'
    )
  } else if (profileYears >= requiredYears) {
    details.push(
      experienceFeedback[Math.floor(Math.random() * experienceFeedback.length)]
    )
  } else {
    details.push(
      insufficientExperienceFeedback[
        Math.floor(Math.random() * insufficientExperienceFeedback.length)
      ]
    )
  }

  const jobLevel = CAREER_LEVELS[job.level] || 1
  const profileLevel = CAREER_LEVELS[profile.current_level] || 1

  // Randomized feedback for overqualification
  const overqualifiedFeedback = [
    'Your career level is higher than what is required for this role, which may result in a lower fit.',
    'You may be overqualified for this position based on your career level.',
    'Your seniority exceeds what is necessary for this job, which could affect your match score.',
    'Your level of experience might make you overqualified for this position.',
    'Your background suggests you are more senior than the job demands.'
  ]

  // Randomized feedback for perfect match
  const perfectMatchFeedback = [
    'Your career level perfectly matches the expectations for this role.',
    'You are exactly at the right career level for this position.',
    'Your current level aligns well with what the job is looking for.',
    'You match the career level needed for this position.',
    'You bring the exact level of seniority required for this job.'
  ]

  // Randomized feedback for underqualification
  const underqualifiedFeedback = [
    'Your career level is below what is required for this job.',
    'This job requires a higher career level than you currently have.',
    'Your background does not meet the required career level for this role.',
    'Your current career level does not align with the role’s expectations.',
    'You may need to advance to a higher career level to fully match this job.'
  ]

  if (profileLevel > jobLevel) {
    details.push(
      overqualifiedFeedback[
        Math.floor(Math.random() * overqualifiedFeedback.length)
      ]
    )
  } else if (profileLevel === jobLevel) {
    details.push(
      perfectMatchFeedback[
        Math.floor(Math.random() * perfectMatchFeedback.length)
      ]
    )
  } else if (jobLevel) {
    details.push(
      underqualifiedFeedback[
        Math.floor(Math.random() * underqualifiedFeedback.length)
      ]
    )
  }

  const yearsScore =
    requiredYears === 0 ? 1 : Math.min(1, profileYears / requiredYears)

  // Adjusted penalty for overqualification
  const levelScore =
    profileLevel > jobLevel
      ? 0.5 // Stronger penalty for overqualification
      : profileLevel >= jobLevel
      ? 1
      : profileLevel / jobLevel

  // Penalty for underqualification or overqualification is baked into levelScore
  const experienceScore = Math.min(
    1,
    yearsScore * MATCH_WEIGHTS.experience.years +
      levelScore * MATCH_WEIGHTS.experience.level
  )

  return { score: experienceScore, details }
}

const calculateEducationMatch = (job, profile) => {
  const details = []
  const requiredLevel = EDUCATION_LEVELS[job.required_education] || 0
  const profileLevel = EDUCATION_LEVELS[profile.highest_education] || 0

  // Randomized feedback for matching education
  const educationMatchFeedback = [
    "Your education meets the job's requirements.",
    'Your academic background is well-suited for this role.',
    'You have the required education level for this position.',
    'Your education aligns perfectly with what the employer is looking for.',
    'Your qualifications match the education level needed for this job.'
  ]

  // Randomized feedback for insufficient education
  const insufficientEducationFeedback = [
    'Your education level is below what is required for this job.',
    'The role expects a higher level of education than you currently have.',
    "You may need additional qualifications to fully match this job's expectations.",
    'Your academic background does not fully meet the requirements for this role.',
    'This job requires a higher level of education than what you currently hold.'
  ]

  // Check if the job specifies an education requirement
  if (!job.required_education) {
    return { score: 1, details } // Return a perfect score with no feedback if no education requirement is provided
  }

  // If profile meets or exceeds the required level
  if (profileLevel >= requiredLevel) {
    details.push(
      educationMatchFeedback[
        Math.floor(Math.random() * educationMatchFeedback.length)
      ]
    )
  } else {
    // If profile is below the required level
    details.push(
      insufficientEducationFeedback[
        Math.floor(Math.random() * insufficientEducationFeedback.length)
      ]
    )
  }

  // Calculate the score based on education level match
  const score = profileLevel >= requiredLevel ? 1 : profileLevel / requiredLevel

  return { score, details }
}

const calculateLocationMatch = (job, profile) => {
  const details = []
  if (!job.work_policy || !profile.location) {
    return { score: 1, details } // No feedback if location or work policy is unspecified
  }

  // Randomized feedback for matching location
  const matchingLocationFeedback = [
    "Your location matches the job's requirements.",
    "The job's location aligns with your current location.",
    'This position is a good fit for your location.',
    "Your geographic location is compatible with the job's requirements.",
    'You are located where this job is available.'
  ]

  // Randomized feedback for mismatched location
  const mismatchedLocationFeedback = [
    "Your location does not match the job's requirements.",
    'This position requires you to be in a different location.',
    "The job's location is not compatible with your current location.",
    "You may need to relocate to meet the job's requirements.",
    "Your location is not aligned with the job's requirements."
  ]

  const policy = job.work_policy.toLowerCase()
  if (
    policy === 'remote' &&
    profile.work_preference?.toLowerCase().includes('remote')
  ) {
    details.push('This job is remote, which aligns with your work preference.')
    return { score: 1, details }
  }

  if (policy === 'hybrid') {
    if (profile.location === job.location) {
      details.push(
        matchingLocationFeedback[
          Math.floor(Math.random() * matchingLocationFeedback.length)
        ]
      )
      return { score: 1, details }
    } else {
      details.push(
        mismatchedLocationFeedback[
          Math.floor(Math.random() * mismatchedLocationFeedback.length)
        ]
      )
      return { score: MATCH_WEIGHTS.location.hybrid, details }
    }
  }

  if (policy === 'onsite') {
    if (profile.location === job.location) {
      details.push(
        matchingLocationFeedback[
          Math.floor(Math.random() * matchingLocationFeedback.length)
        ]
      )
      return { score: 1, details }
    } else {
      details.push(
        mismatchedLocationFeedback[
          Math.floor(Math.random() * mismatchedLocationFeedback.length)
        ]
      )
      return { score: MATCH_WEIGHTS.location.onsite, details }
    }
  }

  return { score: 1, details }
}

const calculateIndustryMatch = (job, profile, employerProfile) => {
  const details = []
  const seekerIndustries = new Set(
    [...(profile.industries || [])].map((i) => i.toLowerCase())
  )

  const workHistoryIndustries = new Set(
    (profile.work_history || [])
      .flatMap((job) => job.industry || [])
      .map((i) => i.toLowerCase())
  )

  const employerIndustry = employerProfile?.industry
    ? Array.isArray(employerProfile.industry)
      ? employerProfile.industry.map((i) => i.toLowerCase())
      : [employerProfile.industry.toLowerCase()]
    : []

  // Randomized feedback for matching industries
  const matchingIndustryFeedback = [
    'You have experience in industries that align with this job.',
    'Your background matches the industries relevant to this position.',
    'Your professional experience aligns well with the industry of this role.',
    'You have worked in industries that are a good fit for this job.',
    'Your industry experience makes you a strong candidate for this position.',
    'You have relevant experience in the industries associated with this job.',
    "The industries you have worked in match well with the job's focus.",
    'Your industry expertise aligns closely with the requirements of this role.',
    'You bring experience from industries that are highly relevant to this job.',
    'Your industry background is a great match for this position.'
  ]

  // Randomized feedback for missing industries
  const missingIndustryFeedback = [
    'You do not have experience in the industries relevant to this job.',
    'Your background does not align with the industries associated with this position.',
    'The industries you have worked in differ from those required for this role.',
    'Your industry experience may not match the focus of this job.',
    'You do not have significant experience in the industries connected to this job.',
    'Your professional background does not align with the industries this role targets.',
    'This job requires experience in industries you may not be familiar with.',
    'Your industry expertise does not overlap with the requirements of this role.',
    'You lack experience in the industries that are key for this job.',
    'Your work history does not include industries relevant to this position.'
  ]

  const industryMatches = new Set(
    employerIndustry.filter(
      (i) => seekerIndustries.has(i) || workHistoryIndustries.has(i)
    )
  )

  if (employerIndustry.length === 0) {
    details.push('This job does not specify any relevant industries.')
  } else if (industryMatches.size > 0) {
    details.push(
      matchingIndustryFeedback[
        Math.floor(Math.random() * matchingIndustryFeedback.length)
      ]
    )
  } else {
    details.push(
      missingIndustryFeedback[
        Math.floor(Math.random() * missingIndustryFeedback.length)
      ]
    )
  }

  const score = industryMatches.size > 0 ? 1 : 0
  return { score, details }
}

const calculateCompensationMatch = (job, profile) => {
  const details = []
  if (
    !job.salary?.range_start ||
    !job.salary?.range_end ||
    !profile.minimum_salary
  ) {
    return { score: 1, details } // No feedback if salary details are missing
  }

  const jobMin = job.salary.range_start
  const jobMax = job.salary.range_end
  const minRequired = profile.minimum_salary

  // Randomized feedback for matching salary
  const matchingSalaryFeedback = [
    'The salary range for this job meets your expectations.',
    'This role offers compensation that aligns with your minimum requirements.',
    "The job's salary range is a great fit for your expectations.",
    'This position offers a salary that matches your desired range.',
    'The compensation for this job aligns with what you are looking for.'
  ]

  // Randomized feedback for mismatched salary
  const mismatchedSalaryFeedback = [
    'The salary for this job is below your expectations.',
    'This position does not meet your minimum salary requirements.',
    "The job's compensation is lower than what you are seeking.",
    'Your minimum salary requirement is higher than what this role offers.',
    'The salary range for this job does not align with your expectations.'
  ]

  if (jobMax < minRequired) {
    details.push(
      mismatchedSalaryFeedback[
        Math.floor(Math.random() * mismatchedSalaryFeedback.length)
      ]
    )
    return { score: 0, details }
  }

  if (jobMin >= minRequired) {
    details.push(
      matchingSalaryFeedback[
        Math.floor(Math.random() * matchingSalaryFeedback.length)
      ]
    )
    return { score: 1, details }
  }

  details.push('The salary partially meets your expectations.')
  return { score: 0.7, details }
}

export default Match
