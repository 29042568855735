import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { auth } from '../../firebase'
import {
  getJobseekerApplications,
  updateJobApplication
} from '../../models/JobApplication'
import { Building, Calendar, XCircle } from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { getDoc, doc } from 'firebase/firestore'
import { db } from '../../firebase'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const ContentWrapper = styled.div`
  flex: 1;
  padding: 2rem;
  max-width: 900px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const PageTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #1a202c;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: none;

  ${({ active, status }) => {
    let baseColors
    switch (status) {
      case 'submitted':
        baseColors =
          'background-color: #EBF8FF; color: #3182CE; border: 1px solid #3182CE;'
        break
      case 'reviewed':
        baseColors =
          'background-color: #EAE2F8; color: #805AD5; border: 1px solid #805AD5;'
        break
      case 'shortlisted':
        baseColors =
          'background-color: #FFEDD5; color: #DD6B20; border: 1px solid #DD6B20;'
        break
      case 'interview':
        baseColors =
          'background-color: #FEFCBF; color: #D69E2E; border: 1px solid #D69E2E;'
        break
      case 'offer':
        baseColors =
          'background-color: #F0FFF4; color: #38A169; border: 1px solid #38A169;'
        break
      case 'rejected':
        baseColors =
          'background-color: #FFF5F5; color: #E53E3E; border: 1px solid #E53E3E;'
        break
      case 'withdrawn':
        baseColors =
          'background-color: #EDF2F7; color: #4A5568; border: 1px solid #4A5568;'
        break
      case 'all':
        baseColors =
          'background-color: #EDF2F7; color: #1A202C; border: 1px solid #1A202C;'
        break
      default:
        baseColors =
          'background-color: #EDF2F7; color: #4A5568; border: 1px solid #4A5568;'
    }

    return `
      ${baseColors}
      opacity: ${active ? 1 : 0.6};
      transform: ${active ? 'scale(1.05)' : 'scale(1)'};
      box-shadow: ${active ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'};

      &:hover {
        opacity: 1;
        transform: scale(1.05);
      }
    `
  }}
`

const FilterStats = styled.span`
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.125rem 0.5rem;
  border-radius: 9999px;
  margin-left: 0.5rem;
  font-size: 0.75rem;
`

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 1fr 150px 120px 100px;
  gap: 1rem;
  background-color: #edf2f7;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #4a5568;
  @media (max-width: 768px) {
    display: none;
  }
`

const ApplicationCard = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 1fr 150px 120px 100px;
  gap: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    grid-template-columns: auto;
    justify-items: center;
  }
`

const CompanyLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: contain;
`

const JobTitle = styled.div`
  font-size: 1.125rem;
  color: #2d3748;
  font-weight: bold;
`

const CompanyName = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a5568;
`

const ApplicationDate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #718096;
`

const Tooltip = styled.div`
  position: absolute;
  background-color: #1a202c;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: normal;
  max-width: 250px;
  z-index: 50;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: all 0.2s ease-in-out;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  white-space: normal;
  text-align: center;
  text-transform: none;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #1a202c transparent transparent transparent;
  }
`

// Update the ApplicationStatus component
const ApplicationStatus = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative; // Add this for tooltip positioning

  ${({ status }) => {
    switch (status) {
      case 'submitted':
        return 'background-color: #EBF8FF; color: #3182CE;'
      case 'reviewed':
        return 'background-color: #EAE2F8; color: #805AD5;'
      case 'shortlisted':
        return 'background-color: #FFEDD5; color: #DD6B20;'
      case 'interview':
        return 'background-color: #FEFCBF; color: #D69E2E;'
      case 'offer':
        return 'background-color: #F0FFF4; color: #38A169;'
      case 'rejected':
        return 'background-color: #FFF5F5; color: #E53E3E;'
      case 'withdrawn':
        return 'background-color: #EDF2F7; color: #4A5568;'
      default:
        return 'background-color: #EDF2F7; color: #4A5568;'
    }
  }}
`

const WithdrawButton = styled.button`
  background: none;
  border: none;
  color: #e53e3e;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  transition: all 0.2s ease-in-out;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    background-color: #fff5f5;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const NoApplicationsMessage = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  color: #4a5568;
`

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

const ModalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 1rem;
`

const ModalText = styled.p`
  color: #4a5568;
  margin-bottom: 1.5rem;
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const ModalButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;

  ${({ variant }) =>
    variant === 'primary'
      ? `
        background-color: #E53E3E;
        color: white;
        border: none;
        &:hover {
          background-color: #C53030;
        }
      `
      : `
        background-color: white;
        color: #4a5568;
        border: 1px solid #E2E8F0;
        &:hover {
          background-color: #F7FAFC;
        }
      `}
`
const ProBanner = styled.div`
  background-color: #d29856;
  padding: 6px 16px;
  position: fixed;
  top: 0;
  margin-top: 10px;
  right: 0;
  z-index: 1001;
  border-bottom-left-radius: 8px;
  font-family: 'Roboto', sans-serif;
`

const ProText = styled.span`
  color: white;
  font-size: 14px;
`

const ApplicationTracking = () => {
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeFilter, setActiveFilter] = useState('all')
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [withdrawApplicationId, setWithdrawApplicationId] = useState(null)
  const [isPaidUser, setIsPaidUser] = useState(false) // Add this state

  const navigate = useNavigate()

  const statusCounts = applications.reduce((acc, app) => {
    acc[app.status] = (acc[app.status] || 0) + 1
    return acc
  }, {})

  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'submitted', label: 'Submitted' },
    { value: 'reviewed', label: 'Reviewed' },
    { value: 'shortlisted', label: 'Shortlisted' },
    { value: 'interview', label: 'Interview' },
    { value: 'offer', label: 'Offer' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'withdrawn', label: 'Withdrawn' }
  ]

  useEffect(() => {
    const checkUserPlan = async () => {
      const userId = auth.currentUser?.uid
      if (userId) {
        try {
          const userDoc = await getDoc(
            doc(db, 'users', userId, 'profiles', 'jobseeker')
          )
          if (userDoc.exists()) {
            setIsPaidUser(userDoc.data()?.plan === 'paid')
          }
        } catch (error) {
          console.error('Error checking user plan:', error)
        }
      }
    }

    checkUserPlan()
  }, [])

  useEffect(() => {
    const fetchApplications = async () => {
      const userId = auth.currentUser?.uid
      if (userId) {
        try {
          const userApplications = await getJobseekerApplications(userId)
          setApplications(userApplications)
          setLoading(false)
        } catch (error) {
          console.error('Error fetching applications:', error)
          setLoading(false)
        }
      }
    }

    fetchApplications()
  }, [])

  const handleCardClick = (event, application) => {
    if (event.target.closest('button')) {
      return
    }
    navigate(`/job-opening/${application.job_posting_id}`, {
      state: {
        companyName: application.company_name || 'Unknown Company',
        companyLogoUrl: application.company_logo_url || '/api/placeholder/50/50'
      }
    })
  }
  const handleWithdraw = (event, applicationId) => {
    event.stopPropagation()
    setWithdrawApplicationId(applicationId)
    setShowWithdrawModal(true)
  }

  const ApplicationStatusWithTooltip = ({ status, rejectionReason }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    return (
      <ApplicationStatus
        status={status}
        onMouseEnter={() => status === 'rejected' && setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {status.replace('_', ' ')}
        {status === 'rejected' && rejectionReason && (
          <Tooltip show={showTooltip}>Reason: {rejectionReason}</Tooltip>
        )}
      </ApplicationStatus>
    )
  }

  const confirmWithdraw = async () => {
    try {
      await updateJobApplication(withdrawApplicationId, { status: 'withdrawn' })
      setApplications(
        applications.map((app) =>
          app.id === withdrawApplicationId
            ? { ...app, status: 'withdrawn' }
            : app
        )
      )
      setShowWithdrawModal(false)
      setWithdrawApplicationId(null)
    } catch (error) {
      console.error('Error withdrawing application:', error)
      alert('Failed to withdraw application. Please try again.')
    }
  }

  const filteredApplications = applications.filter((app) =>
    activeFilter === 'all' ? true : app.status === activeFilter
  )

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <PageWrapper>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>
      <ContentWrapper>
        {isPaidUser && (
          <ProBanner>
            <ProText>Arena Pro✨</ProText>
          </ProBanner>
        )}
        <PageTitle>Your Job Applications</PageTitle>

        <FilterContainer>
          {filterOptions.map((option) => (
            <FilterButton
              key={option.value}
              active={activeFilter === option.value}
              status={option.value}
              onClick={() => setActiveFilter(option.value)}
            >
              {option.label}
              <FilterStats>
                {option.value === 'all'
                  ? applications.length
                  : statusCounts[option.value] || 0}
              </FilterStats>
            </FilterButton>
          ))}
        </FilterContainer>

        <TableHeader>
          <div>Logo</div>
          <div>Title</div>
          <div>Company</div>
          <div>Date Applied</div>
          <div>Status</div>
          <div>Actions</div>
        </TableHeader>

        {filteredApplications.length === 0 ? (
          <NoApplicationsMessage>
            {applications.length === 0
              ? "You haven't applied to any jobs yet."
              : `No applications with status "${activeFilter}"`}
          </NoApplicationsMessage>
        ) : (
          filteredApplications.map((application) => (
            <ApplicationCard
              key={application.id}
              onClick={(e) => handleCardClick(e, application)}
            >
              <CompanyLogo
                src={application.company_logo_url || '/api/placeholder/50/50'}
                alt={`${application.company_name} logo`}
              />
              <JobTitle>{application.job_title || 'N/A'}</JobTitle>
              <CompanyName>
                <Building size={16} />
                {application.company_name || 'Unknown Company'}
              </CompanyName>
              <ApplicationDate>
                <Calendar size={16} />
                {application.created_at.toDate().toLocaleDateString()}
              </ApplicationDate>
              <ApplicationStatusWithTooltip
                status={application.status}
                rejectionReason={application.rejection_reasoning}
              />
              <WithdrawButton
                onClick={(e) => handleWithdraw(e, application.id)}
                disabled={
                  application.status === 'withdrawn' ||
                  application.status === 'rejected'
                }
                title={
                  application.status === 'withdrawn'
                    ? 'Application already withdrawn'
                    : application.status === 'rejected'
                    ? 'Cannot withdraw rejected application'
                    : 'Withdraw application'
                }
              >
                <XCircle size={16} />
                {application.status === 'withdrawn'
                  ? 'Withdrawn'
                  : application.status === 'rejected'
                  ? 'Withdraw'
                  : 'Withdraw'}
              </WithdrawButton>
            </ApplicationCard>
          ))
        )}

        {showWithdrawModal && (
          <ModalBackdrop onClick={() => setShowWithdrawModal(false)}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <ModalTitle>Withdraw Application</ModalTitle>
              <ModalText>
                Are you sure you want to withdraw this application? This action
                cannot be undone.
              </ModalText>
              <ModalButtons>
                <ModalButton onClick={() => setShowWithdrawModal(false)}>
                  Cancel
                </ModalButton>
                <ModalButton variant="primary" onClick={confirmWithdraw}>
                  Withdraw Application
                </ModalButton>
              </ModalButtons>
            </ModalContent>
          </ModalBackdrop>
        )}
      </ContentWrapper>
    </PageWrapper>
  )
}

export default ApplicationTracking
