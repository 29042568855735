import React, { useState, useEffect } from 'react'
import {
  Inbox,
  Search,
  Building,
  FileCheck,
  Settings,
  MessageSquare,
  LogOut,
  User,
  HelpCircle,
  Lightbulb
} from 'lucide-react'
import { useNavigate, Link } from 'react-router-dom'
import { auth } from '../../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import styled from 'styled-components'
import { db } from '../../firebase'

const USERS_COLLECTION = 'users'
const PROFILE_SUBCOLLECTION = 'profiles'
const JOBSEEKER_PROFILE_DOC = 'jobseeker'

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 16rem;
  background-color: #f3f4f6;
  border-right: 1px solid #e5e7eb;

  @media (max-width: 768px) {
    width: 3.5rem;
  }
`

const LogoContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
`

const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #ef4444;
  border-radius: 50%;
  position: absolute;
  left: ${({ isMobile }) => (isMobile ? '-60%' : '600%')};
  top: ${({ isMobile }) => (isMobile ? 'auto' : '8px')};
`

const IconContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width: 120px;
  height: 100px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

const Nav = styled.nav`
  flex: 1;
`

const NavButtonWrapper = styled.div`
  position: relative;
  width: 85%;
  &:hover div {
    visibility: visible;
    opacity: 1;
  }
  @media (max-width: 768px) {
    width: auto;
  }
`

const NavButton = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: #e5e7eb;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: auto;
    padding: 0.5rem;
  }
`

const IconWrapper = styled.span`
  margin-right: 0.75rem;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap; // This will keep "Resource Hub" on one line

  @media (max-width: 768px) {
    display: none;
  }
`

const BottomSection = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Tooltip = styled.div`
  position: absolute;
  left: 105%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  color: #dc2626;
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  width: 80%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #fee2e2;
    color: #b91c1c;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
`

const HelpDropDown = styled.div`
  position: relative;
`

const HelpDropDownContent = styled.div`
  position: absolute;
  background-color: #f1f1f1;
  width: 85%;
  padding: 0rem 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;

  @media (max-width: 768px) {
    width: 200px;
    left: 100%;
    top: 0;
  }
`

const DropDownLink = styled.a`
  padding: 10px 0px;
  display: block;
  color: #4a90e2;
  font-weight: 600;
  font-size: 0.87em;
  text-decoration: none;
  transition: color 0.2s;
`

const LinkText = styled.span`
  &:hover {
    color: #63b3ed;
    text-decoration: underline;
  }
`

const ProBadge = styled.img`
  width: 60px; // Increased from 20px
  height: 60px; // Keeping height proportional
  margin-left: 8px;
  object-fit: contain;

  @media (max-width: 768px) {
    display: none;
  }
`

function JobSeekerNav() {
  const [isHelpDropDownOpen, setIsHelpDropDownOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [hasSeenWelcome, setHasSeenWelcome] = useState(false)
  const [intakeCompleted, setIntakeCompleted] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isPaidUser, setIsPaidUser] = useState(false)
  const [resourceIntakeCompleted, setResourceIntakeCompleted] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const profileRef = doc(db, 'users', user.uid, 'profiles', 'jobseeker')

        const profileListener = onSnapshot(
          profileRef,
          {
            includeMetadataChanges: true
          },
          (doc) => {
            if (doc.exists()) {
              const profile = doc.data()
              setIntakeCompleted(profile?.intake_completed || false)
              setHasSeenWelcome(profile?.has_seen_welcome || false)
              setIsPaidUser(profile?.plan === 'paid')
              setResourceIntakeCompleted(
                profile?.resource_intake_completed || false
              )

              console.log('Profile data:', {
                plan: profile?.plan,
                isPaid: profile?.plan === 'paid',
                resourceIntakeCompleted: profile?.resource_intake_completed
              })
            } else {
              setIntakeCompleted(false)
              setHasSeenWelcome(false)
              setIsPaidUser(false)
              setResourceIntakeCompleted(false)
            }
            setLoading(false)
          }
        )

        return () => profileListener()
      } else {
        setLoading(false)
        setIntakeCompleted(false)
        setHasSeenWelcome(false)
        setIsPaidUser(false)
        setResourceIntakeCompleted(false)
        navigate('/login')
      }
    })

    return () => unsubscribe()
  }, [navigate])

  const handleHelpDropDown = () => {
    setIsHelpDropDownOpen((prev) => !prev)
  }

  const handleLogout = async () => {
    try {
      await auth.signOut()
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  // Custom handler for Resource Hub navigation
  const handleResourceHubClick = (e) => {
    e.preventDefault()
    if (!isPaidUser) {
      return // Do nothing if not paid user
    }

    if (!resourceIntakeCompleted) {
      navigate('/resource-intake')
    } else {
      navigate('/resource-hub')
    }
  }

  const baseNavItems = [
    {
      to: '/jobseeker-dashboard',
      icon: <Inbox size={20} />,
      text: 'Dashboard',
      requiresProfile: false
    },
    {
      to: '/jobseeker-profile',
      id: 'profile-button',

      icon: <User size={20} />,
      text: 'Profile',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to view your profile.'
    },
    {
      to: '/job-search',
      id: 'job-search-button',
      icon: <Search size={20} />,
      text: 'Search Jobs',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to search for jobs'
    },
    {
      to: '/company-search',
      id: 'company-search-button',
      icon: <Building size={20} />,
      text: 'Search Companies',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to browse companies'
    },
    {
      to: '/application-tracking',
      id: 'application-tracking-button',
      icon: <FileCheck size={20} />,
      text: 'Application Tracking',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to track applications'
    },
    {
      to: '/jobseeker-messaging',
      id: 'jobseeker-messaging-button',

      icon: (
        <IconContainer>
          <MessageSquare size={20} />
          {!hasSeenWelcome && <NotificationDot isMobile={isMobile} />}
        </IconContainer>
      ),
      text: 'Messaging',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to message employers'
    }
  ]

  const navItems = [...baseNavItems]
  if (isPaidUser) {
    navItems.splice(5, 0, {
      to: '#',
      icon: <Lightbulb size={20} />,
      text: (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '-16px',
            marginBottom: '-16px'
          }}
        >
          Resource Hub
          <ProBadge
            src="/images/arena-pro.png"
            alt="Pro"
            style={{ marginLeft: '10px' }}
          />
        </span>
      ),
      requiresProfile: true,
      onClick: handleResourceHubClick,
      tooltip:
        'Complete your profile on the dashboard to access the resource hub'
    })
  }

  if (loading) {
    return (
      <NavContainer>
        <LogoContainer>
          <Logo src="/images/logo-black.png" alt="Company Logo" />
        </LogoContainer>
        <div className="flex items-center justify-center h-full">
          Loading...
        </div>
      </NavContainer>
    )
  }

  return (
    <NavContainer>
      <LogoContainer>
        <Logo src="/images/logo-black.png" alt="Company Logo" />
      </LogoContainer>
      <Nav>
        {navItems.map((item) => {
          const isDisabled = !intakeCompleted && item.requiresProfile
          return (
            <NavButtonWrapper key={item.to}>
              <NavButton
                id={item.id}
                to={item.to}
                disabled={isDisabled}
                onClick={item.onClick}
              >
                <IconWrapper>{item.icon}</IconWrapper>
                <ButtonText>{item.text}</ButtonText>
              </NavButton>
              {isDisabled && item.tooltip && <Tooltip>{item.tooltip}</Tooltip>}
            </NavButtonWrapper>
          )
        })}
      </Nav>
      <BottomSection>
        <HelpDropDown>
          <NavButton onClick={handleHelpDropDown}>
            <IconWrapper>
              <HelpCircle size={20} />
            </IconWrapper>
            <ButtonText>Need Help?</ButtonText>
          </NavButton>
          {isHelpDropDownOpen && (
            <HelpDropDownContent>
              <DropDownLink
                href="mailto:support@arenatalent.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkText>Email us</LinkText>
              </DropDownLink>
            </HelpDropDownContent>
          )}
        </HelpDropDown>
        <NavButton to="/jobseeker-account">
          <IconWrapper>
            <Settings size={20} />
          </IconWrapper>
          <ButtonText>Account</ButtonText>
        </NavButton>
        <LogoutButton onClick={handleLogout}>
          <IconWrapper>
            <LogOut size={20} />
          </IconWrapper>
          <ButtonText>Logout</ButtonText>
        </LogoutButton>
      </BottomSection>
    </NavContainer>
  )
}

export default JobSeekerNav
