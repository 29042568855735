import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { auth } from './firebase'
import HomePage from './components/HomePage'
import EmployerDashboard from './components/Employers/EmployerDashboard'
import AdminDashboard from './components/Admin/AdminDashboard'
import { ADMIN_EMAILS } from './utils/adminAuth'
import CandidateSourcing from './components/Employers/CandidateSourcing'
import Pipelines from './components/Employers/Pipelines'
import EmployerIntakeForm from './components/Employers/EmployerIntakeForm'
import EmployerProfile from './components/Employers/EmployerProfile'
import ProfileProtection from './components/Employers/ProfileProtection'
import JobPostForm from './components/Employers/PostJob'
import JobPostings from './components/Employers/JobPostings'
import JobDetails from './components/Employers/JobDetails'
import Messaging from './components/Messaging'
import AdminLogin from './components/Admin/AdminLogin'
import EmployerAccount from './components/Employers/EmployerAccount'
import EmployerPricing from './components/EmployerPricing'
import JobSeekerPricing from './components/JobSeekerPricing'
import JobSeekerDash from './components/JobSeekers/JobSeekerDashboard'
import JobseekerIntake from './components/JobSeekers/JobSeekerIntake'
import JobSeekerProfile from './components/JobSeekers/JobSeekerProfile'
import CompanySearch from './components/JobSeekers/CompanySearch'
import JobSearch from './components/JobSeekers/JobSearch'
import JobSeekerJobView from './components/JobSeekers/JobSeekerJobView'
import ApplicationTracking from './components/JobSeekers/ApplicationTracking'
import EmployerProfileJobSeekerView from './components/JobSeekers/EmployerProfileJobSeekerView'
import JobSeekerMessaging from './components/JobSeekers/JobSeekerMessaging'
import JobSeekerProfileView from './components/Employers/JobSeekerProfileEmployerview'
import JobSeekerAccount from './components/JobSeekers/JobSeekerAccount'
import ResourceHub from './components/JobSeekers/ResourceHub'
import ResourceIntake from './components/JobSeekers/ResourceIntake'
import EventPage from './components/JobSeekers/EventPage'
import ResourceForm from './components/ResourceForm' // Adjust path as needed
import PublicJobView from './components/Employers/PublicJobView'
import JobseekerSelectPlan from './components/JobSeekers/JobseekerSelectPlan'
import CheckoutSuccess from './components/CheckoutSuccess'

function App() {
  const [authLoading, setAuthLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user)
      setAuthLoading(false)
    })

    return () => unsubscribe()
  }, [])

  if (authLoading) {
    return <div>Loading...</div>
  }

  // Updated ProtectedRoute to handle admin routes
  function ProtectedRoute({ children, requireAdmin = false }) {
    if (!user) {
      return <Navigate to="/" />
    }

    if (requireAdmin && !ADMIN_EMAILS.includes(user.email?.toLowerCase())) {
      return <Navigate to="/" />
    }
    return React.cloneElement(children, { employerId: user.uid })
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route
            path="/employer-dashboard"
            element={
              <ProtectedRoute>
                <EmployerDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/candidate-sourcing"
            element={
              <ProtectedRoute>
                <CandidateSourcing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pipelines"
            element={
              <ProtectedRoute>
                <Pipelines />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-intake"
            element={
              <ProtectedRoute>
                <EmployerIntakeForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-profile"
            element={
              <ProtectedRoute>
                <ProfileProtection>
                  <EmployerProfile />
                </ProfileProtection>
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-profile-view/:employerId"
            element={
              <ProtectedRoute>
                <EmployerProfileJobSeekerView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-profile-view/:jobseekerId"
            element={
              <ProtectedRoute>
                <JobSeekerProfileView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-post-form"
            element={
              <ProtectedRoute>
                <JobPostForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-postings"
            element={
              <ProtectedRoute>
                <JobPostings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-details/:jobId"
            element={
              <ProtectedRoute>
                <JobDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messaging"
            element={
              <ProtectedRoute>
                <Messaging />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-account"
            element={
              <ProtectedRoute>
                <EmployerAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-dashboard"
            element={
              <ProtectedRoute>
                <JobSeekerDash />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-intake"
            element={
              <ProtectedRoute>
                <JobseekerIntake />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-profile"
            element={
              <ProtectedRoute>
                <JobSeekerProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/company-search"
            element={
              <ProtectedRoute>
                <CompanySearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-search"
            element={
              <ProtectedRoute>
                <JobSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/application-tracking"
            element={
              <ProtectedRoute>
                <ApplicationTracking />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-opening/:jobpostingId"
            element={
              <ProtectedRoute>
                <JobSeekerJobView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-messaging"
            element={
              <ProtectedRoute>
                <JobSeekerMessaging />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-account"
            element={
              <ProtectedRoute>
                <JobSeekerAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resource-hub"
            element={
              <ProtectedRoute>
                <ResourceHub />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resource-intake"
            element={
              <ProtectedRoute>
                <ResourceIntake />
              </ProtectedRoute>
            }
          />
          <Route
            path="/event-page"
            element={
              <ProtectedRoute>
                <EventPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resource-form"
            element={
              <ProtectedRoute>
                <ResourceForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-select-plan"
            element={
              <ProtectedRoute>
                <JobseekerSelectPlan />
              </ProtectedRoute>
            }
          />
          <Route path="/employer-pricing" element={<EmployerPricing />} />
          <Route path="/checkout-success" element={<CheckoutSuccess />} />

          <Route path="/jobseeker-pricing" element={<JobSeekerPricing />} />
          <Route path="/job/:jobId" element={<PublicJobView />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
