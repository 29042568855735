import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { auth, db } from '../firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { updateJobseekerProfile } from '../models/JobSeekerProfile'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const PageContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const ContentContainer = styled.div`
  flex: 1;
  padding: 1rem 3rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`
const CardContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const BulletPoint = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  line-height: 1.6;
  font-size: 9pt;
  margin-top: 3px;
  padding: 3px;
  font-weight: 500;
  padding-right: 10px;
  border-radius: 4px;
  color: ${(props) => (props.highlight ? '#6b46c1' : '#4a5568')};
  //background-color: ${(props) =>
    props.highlight ? '#c471ed' : 'transparent'};
  background-color: transparent;

  svg {
    margin-right: 10px;
    margin-left: 4px;
  }
`
const TableTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0rem;
`
const MostPopularTag = styled.span`
  position: absolute;
  border-radius: 0px 0px 0px 8px;
  top: 0px;
  right: 0px;
  background-color: #c471ed;
  color: #ffffff;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
  width: 5rem;
`
const PricingCardContainer = styled.div`
  display: flex;
  gap: 2rem;
  text-align: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const PricingCard = styled.div`
  width: 500px; // Set a fixed width for consistency
  //padding: 1rem;
  border-radius: 8px;
  background-color: #ffffff;
  //background-color: rgba(74, 85, 104, 0.1);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  border: ${(props) => (props.grey ? props.grey : '2px solid #c471ed')};

  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  @media (max-width: 768px) {
    align-self: center;
    width: 90%; // Full width on mobile for better stacking
    margin-bottom: 0.5rem;
  }
`

const CardDescription = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
  height: 30px;
`
const CardPrice = styled.p`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 16px;
`
const StyledButton = styled.button`
  background-color: ${(props) => (props.grey ? '#4a5568' : '#c471ed')};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  width: 100%;

  &:hover {
    background-color: ${(props) =>
      props.grey
        ? 'rgba(74, 85, 104, 0.9)'
        : 'rgba(196, 113, 237, 0.9)'}; //same colors just 90% opacity on hover
  }
`

const DescriptionPriceContainer = styled.div`
  min-height: 110px;
  margin-bottom: 0.5rem;
`
const BulletContainerTitle = styled.h3`
  text-align: left;
  font-size: 18px;
  color: #4a5568;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 8px;
`
const BulletPointContainer = styled.div`
  text-align: left;
  padding: 10px;
`
const ShadeContainer = styled.div`
  padding: 1rem;

  background-color: ${(props) =>
    props.grey ? 'rgba(74, 85, 104, 0.1)' : 'rgba(196, 113, 237, 0.1)'};
`
const Emoji = styled.img`
  width: 1.5rem;
  padding-right: 0.1rem;
`
const Button = ({ children, to, onClick, ...props }) => {
  const navigate = useNavigate()

  const handleClick = async (e) => {
    if (onClick) {
      await onClick(e)
    } else if (to?.startsWith('http')) {
      window.open(to, '_blank', 'noopener,noreferrer')
    } else if (to) {
      navigate(to)
    }
  }

  return (
    <StyledButton {...props} onClick={handleClick}>
      {children}
    </StyledButton>
  )
}

export default function JobSeekerPricing() {
  const [currentTab, setCurrentTab] = useState('every3months')
  const [selectedCard, setSelectedCard] = useState(null)
  const navigate = useNavigate()

  const handleFreePlanSelection = async () => {
    try {
      const userId = auth.currentUser?.uid
      if (!userId) {
        console.error('No user found')
        return
      }

      // Update main user document
      await updateDoc(doc(db, 'users', userId), {
        plan_selected: true
      })

      // Update profile document
      await updateJobseekerProfile(userId, {
        plan_selected: true,
        plan: 'free'
      })

      // Navigate with refresh parameter
      window.location.href = `/jobseeker-dashboard?refresh=${Date.now()}`
    } catch (error) {
      console.error('Error updating profile:', error)
    }
  }

  const handleProPlanSelection = () => {
    const userId = auth.currentUser?.uid
    if (!userId) {
      console.error('No user found')
      return
    }

    // Define success and cancel URLs for local testing
    const successUrl = encodeURIComponent(
      'http://app.arenatalent.com/checkout-success'
    )
    const cancelUrl = encodeURIComponent(
      'http://app.arenatalent.com/jobseeker-select-plan'
    )

    // Redirect to Stripe Checkout with encoded URLs
    window.location.href = `https://buy.stripe.com/28o03yc5z1DcdpK4gu?success_url=${successUrl}&cancel_url=${cancelUrl}`
  }

  return (
    <PageWrapper>
      <PageContainer>
        <ContentContainer>
          <PricingCardContainer>
            <PricingCard
              grey="2px solid #4a5568"
              onClick={() => setSelectedCard('basic')}
            >
              <ShadeContainer grey={true}>
                <TableTitle>Arena</TableTitle>
                <DescriptionPriceContainer>
                  <CardDescription style={{ marginBottom: '50px' }}>
                    {' '}
                    Apply to top jobs in sports, media and entertainment. Free
                    forever.{' '}
                  </CardDescription>
                  <CardPrice>$0 USD</CardPrice>
                </DescriptionPriceContainer>
                <Button onClick={handleFreePlanSelection} grey="true">
                  Get Started
                </Button>
              </ShadeContainer>

              <BulletPointContainer>
                <BulletContainerTitle>Includes:</BulletContainerTitle>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Create an Arena profile to be discoverable by recruiters
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Search for unlimited jobs
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Apply to unlimited jobs
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Track your progress on applications
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Know when your application is viewed on Arena
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Receive unlimited messages from recruiters
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Save job searches and get alerts
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Gain salary insights
                </BulletPoint>
              </BulletPointContainer>
            </PricingCard>

            <PricingCard onClick={() => setSelectedCard('pro')}>
              {/* Monthly or Every 3 Months Flag */}

              {/* {currentTab === 'monthly' && (<MostPopularTag>Most Popular</MostPopularTag>)} */}
              {currentTab === 'every3months' && (
                <MostPopularTag>Save 20%</MostPopularTag>
              )}

              <ShadeContainer>
                <TableTitle>
                  Arena Pro{' '}
                  <Emoji src="/images/sparkle.png" alt="Sparkle Emoji" />
                </TableTitle>
                <DescriptionPriceContainer>
                  <CardDescription style={{ marginBottom: '16px' }}>
                    Accelerate your career growth with personalized insights &
                    resources
                  </CardDescription>
                  {/* Toggle Buttons */}
                  <div style={{ textAlign: 'center' }}>
                    <button
                      onClick={() => setCurrentTab('monthly')}
                      style={{
                        fontSize: '6pt',
                        fontWeight: '600',
                        padding: '8px 10px',
                        width: '75px',
                        backgroundColor:
                          currentTab === 'monthly' ? '#e5e7eb' : '#ffffff',
                        border: 'none',
                        borderRadius: '8px 0 0 8px',
                        color: currentTab === 'monthly' ? 'black' : 'black',
                        cursor: 'pointer',
                        transition:
                          'background-color 0.3s ease, color 0.3s ease, border-radius 0.3s ease'
                      }}
                    >
                      Monthly
                    </button>
                    <button
                      onClick={() => setCurrentTab('every3months')}
                      style={{
                        fontSize: '6pt',
                        fontWeight: '600',
                        padding: '8px 10px',
                        width: '80px',
                        backgroundColor:
                          currentTab === 'every3months' ? '#e5e7eb' : '#ffffff',
                        border: 'none',
                        borderRadius: '0 8px 8px 0',
                        color:
                          currentTab === 'every3months' ? 'black' : 'black',
                        cursor: 'pointer',
                        transition:
                          'background-color 0.3s ease, color 0.3s ease, border-radius 0.3s ease'
                      }}
                    >
                      Every 3 months
                    </button>
                  </div>

                  {/* Monthly Pricing or 3 Months Pricing Toggle*/}
                  {currentTab === 'monthly' && (
                    <CardPrice style={{ marginTop: '10px' }}>
                      $14.99 USD<span style={{ fontSize: '16px' }}>/month</span>
                    </CardPrice>
                  )}
                  {currentTab === 'every3months' && (
                    <CardPrice style={{ marginTop: '10px' }}>
                      $34.99 USD
                      <span style={{ fontSize: '16px' }}>/3 months</span>
                    </CardPrice>
                  )}
                </DescriptionPriceContainer>
                <Button onClick={handleProPlanSelection}>Get Started</Button>
                <Button style={{ display: 'none' }}>Subscribed</Button>{' '}
                {/* For users currently subscribed */}
              </ShadeContainer>
              <BulletPointContainer>
                <BulletContainerTitle>Includes:</BulletContainerTitle>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Create an Arena profile to be discoverable by recruiters
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Search for unlimited jobs
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Apply to unlimited jobs
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Track your progress on applications
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Know when your application is viewed on Arena
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Receive unlimited messages from recruiters
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Save job searches and get alerts
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Gain salary insights
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color="#c471ed" icon={faCheckCircle} />
                  <Emoji
                    style={{ width: '1rem', marginLeft: '-0.5rem' }}
                    src="/images/sparkle.png"
                    alt="Sparkle Emoji"
                  />
                  <strong> View AI-powered job matches for every role</strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color="#c471ed" icon={faCheckCircle} />
                  <Emoji
                    style={{ width: '1rem', marginLeft: '-0.5rem' }}
                    src="/images/sparkle.png"
                    alt="Sparkle Emoji"
                  />
                  <strong>
                    {' '}
                    Access to Career Resources on industry events, courses &
                    more
                  </strong>
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon color="#c471ed" icon={faCheckCircle} />
                  <Emoji
                    style={{ width: '1rem', marginLeft: '-0.5rem' }}
                    src="/images/sparkle.png"
                    alt="Sparkle Emoji"
                  />
                  <strong>
                    {' '}
                    Get first access to AI Resume Builder (coming early 2025)
                  </strong>
                </BulletPoint>
              </BulletPointContainer>
            </PricingCard>
          </PricingCardContainer>
        </ContentContainer>
      </PageContainer>
    </PageWrapper>
  )
}
