import { db } from '../firebase'
import { storage } from '../firebase'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from 'firebase/storage'
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit
} from 'firebase/firestore'

const RESOURCES_COLLECTION = 'resources'
const RESOURCE_IMAGES_STORAGE = 'resources/images'

// Create Resource Object
export const createResourceObject = (resourceData) => ({
  resourceType: resourceData.resourceType || null,
  heading: resourceData.heading || null,
  subheading: resourceData.subheading || null,
  startDate: resourceData.startDate || null,
  endDate: resourceData.endDate || null,
  description: resourceData.description || null,
  learnMore: resourceData.learnMore || null,
  headerImage: resourceData.headerImage || null,
  industry: resourceData.industry || null,
  topic: Array.isArray(resourceData.topic) ? resourceData.topic : [],
  subcategories: Array.isArray(resourceData.subcategories)
    ? resourceData.subcategories
    : [],
  careerLevel: Array.isArray(resourceData.careerLevel)
    ? resourceData.careerLevel
    : [],
  location: Array.isArray(resourceData.location) ? resourceData.location : [],
  targetAudience: Array.isArray(resourceData.targetAudience)
    ? resourceData.targetAudience
    : [],
  deliveryFormat: Array.isArray(resourceData.deliveryFormat)
    ? resourceData.deliveryFormat
    : [],
  city: resourceData.city || null,
  state: resourceData.state || null,
  country: resourceData.country || null,
  created_at: resourceData.created_at || serverTimestamp(),
  updated_at: serverTimestamp()
})

// Upload Resource Image to Firebase Storage
export const uploadResourceImage = async (resourceId, imageFile) => {
  try {
    if (!imageFile) return null

    const fileExtension = imageFile.name.split('.').pop()
    const fileName = `${resourceId}_${Date.now()}.${fileExtension}`
    const storageRef = ref(storage, `${RESOURCE_IMAGES_STORAGE}/${fileName}`)

    await uploadBytes(storageRef, imageFile)
    const imageUrl = await getDownloadURL(storageRef)

    // Update Firestore with the new image URL
    const resourceRef = doc(db, RESOURCES_COLLECTION, resourceId)
    await updateDoc(resourceRef, {
      headerImage: imageUrl,
      updated_at: serverTimestamp()
    })

    return imageUrl
  } catch (error) {
    console.error('Error uploading resource image:', error.message)
    throw error
  }
}

// Delete Resource Image
export const deleteResourceImage = async (imageUrl) => {
  try {
    if (!imageUrl) return

    const storageRef = ref(storage, imageUrl)
    await deleteObject(storageRef)
  } catch (error) {
    console.error('Error deleting resource image:', error.message)
    throw error
  }
}

// Create or Update Resource
export const createOrUpdateResource = async (
  resourceId,
  resourceData,
  imageFile = null
) => {
  try {
    const resourceRef = doc(db, RESOURCES_COLLECTION, resourceId)

    // Update resource document in Firestore
    await setDoc(
      resourceRef,
      {
        ...createResourceObject(resourceData)
      },
      { merge: true }
    )

    // Handle image upload
    if (imageFile) {
      if (resourceData.headerImage) {
        await deleteResourceImage(resourceData.headerImage)
      }
      await uploadResourceImage(resourceId, imageFile)
    }

    const savedDoc = await getDoc(resourceRef)
    return savedDoc.data()
  } catch (error) {
    console.error('Error in createOrUpdateResource:', error.message)
    throw error
  }
}

// Get a Resource by ID
export const getResource = async (resourceId) => {
  try {
    const resourceRef = doc(db, RESOURCES_COLLECTION, resourceId)
    const resourceSnap = await getDoc(resourceRef)

    if (resourceSnap.exists()) {
      return resourceSnap.data()
    } else {
      console.warn(`No resource found with ID: ${resourceId}`)
      return null
    }
  } catch (error) {
    console.error('Error fetching resource:', error.message)
    throw error
  }
}

// Update Resource
export const updateResource = async (
  resourceId,
  updateData,
  imageFile = null
) => {
  try {
    const resourceRef = doc(db, RESOURCES_COLLECTION, resourceId)

    // Handle image update
    if (imageFile) {
      const currentData = (await getDoc(resourceRef)).data()
      if (currentData?.headerImage) {
        await deleteResourceImage(currentData.headerImage)
      }
      const imageUrl = await uploadResourceImage(resourceId, imageFile)
      updateData.headerImage = imageUrl
    }

    await updateDoc(resourceRef, {
      ...updateData,
      updated_at: serverTimestamp()
    })
  } catch (error) {
    console.error('Error in updateResource:', error.message)
    throw error
  }
}

// Get All Resources
export const getResources = async () => {
  try {
    const resourcesQuery = collection(db, RESOURCES_COLLECTION)
    const resourcesSnapshot = await getDocs(resourcesQuery)

    const resources = resourcesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))

    if (resources.length === 0) {
      console.warn('No resources found.')
    }

    console.log('Fetched Resources:', resources) // Debug log
    return resources
  } catch (error) {
    if (error.code === 'permission-denied') {
      console.error(
        'Permission denied. Check Firestore rules to ensure proper access.'
      )
    } else {
      console.error('Error in getResources:', error.message)
    }
    return []
  }
}

// Validate Description Length
export const validateDescription = (description) => {
  if (description && description.length > 200) {
    throw new Error('Description must be less than 200 characters')
  }
  return true
}
