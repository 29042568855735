import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import JobSeekerNav from './JobSeekerNav'
import styled from 'styled-components'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { auth, db } from '../../firebase'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'

const careerGoalOptions = [
  'Get a new job',
  'Grow my skills and expertise',
  'Get promoted',
  'Explore career paths & opportunities',
  'Learn from experts',
  'Other/none'
]

const resourceOptions = [
  'Books',
  'Courses',
  'Coaching',
  'Certifications',
  'Events',
  'Media',
  'Newsletters',
  'Podcasts',
  'Schools'
]

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f8f9fa;
  background: linear-gradient(
    170deg,
    rgba(183, 176, 255, 0.2) 45%,
    #f8f9fa 45%
  );
  overflow-y: auto;
`

const TitleContainer = styled.div`
  border-bottom: 1px solid #e5e7eb;
`

const TitleBar = styled.div`
  display: flex;
  padding: 2rem 4rem;
  gap: 2rem;
  align-items: center;
  @media (max-width: 768px) {
    padding: 1rem;
    flex-direction: column;
    gap: 0rem;
  }
`

const PageTitle = styled.h1`
  font-size: ${(props) => props.fontSize || '1.25rem'};
  font-family: 'Open Sans', sans-serif;
  color: #252641;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const FormContainer = styled.div`
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const FormCard = styled.div`
  justify-self: center;
  max-width: 800px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`
const Description = styled.p`
  color: #718096;
  font-size: 0.875rem;
  margin-bottom: 2rem;
`
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`
const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: ${softColors.text};
  font-size: 0.9rem;
`

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 0.75rem;
  color: ${softColors.text};
  transition: all 0.2s;
  cursor: pointer;
  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }
`
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #caaae1;
  color: white;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 0.375rem;
  padding: 0.6rem 2rem;

  &:hover {
    background-color: #b799d4;
  }
  @media (max-width: 768px) {
    padding: 8px 15px;
  }
`
const BackButton = styled(Button)`
  background-color: transparent;
  color: #4a5568;
  border: none;
  cursor: pointer;
  font-size: 10px;
  padding: 1rem;
  &:hover {
    color: #2d3748;
    background-color: transparent;
  }
  @media (max-width: 768px) {
    align-self: flex-start;
  }
`
const ProgressTag = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: none;
  background-color: #eae2f8;
  color: #805ad5;
`
const MultiSelect = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.5);
  @media (max-width: 768px) {
    padding: 0.25rem;
  }
`
const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.border};
  }
  @media (max-width: 768px) {
    font-size: 0.65rem;
    padding: 0.5rem;
  }
`
const Checkbox = styled.input.attrs({ type: 'checkbox' })`
margin-right: 0.75rem;
width: 1rem;
height: 1rem;
cursor: pointer;
accent-color: ${softColors.primary};
}
`

// Resource Protection HOC
const withResourceProtection = (WrappedComponent) => {
  return function ProtectedResourceRoute(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [isAuthorized, setIsAuthorized] = useState(false)

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          const profileRef = doc(db, 'users', user.uid, 'profiles', 'jobseeker')

          const profileListener = onSnapshot(profileRef, (doc) => {
            if (doc.exists()) {
              const profile = doc.data()
              if (profile?.plan === 'paid') {
                setIsAuthorized(true)
              } else {
                navigate('/jobseeker-dashboard')
              }
            } else {
              navigate('/jobseeker-dashboard')
            }
            setLoading(false)
          })

          return () => profileListener()
        } else {
          navigate('/login')
          setLoading(false)
        }
      })

      return () => unsubscribe()
    }, [navigate])

    if (loading) {
      return <div>Loading...</div>
    }

    return isAuthorized ? <WrappedComponent {...props} /> : null
  }
}

function ResourceIntake() {
  const navigate = useNavigate()
  const [selectedCareerGoals, setSelectedCareerGoals] = useState([])
  const [selectedResourceTypes, setSelectedResourceTypes] = useState([])
  const [inPersonPreference, setInPersonPreference] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      const user = auth.currentUser

      if (!user) {
        console.error('No user found')
        return
      }

      const profileRef = doc(db, 'users', user.uid, 'profiles', 'jobseeker')

      await updateDoc(profileRef, {
        resource_intake_completed: true,
        resource_career_goal: selectedCareerGoals,
        resource_types: selectedResourceTypes,
        resource_in_person: inPersonPreference,
        updated_at: new Date()
      })

      navigate('/resource-hub')
    } catch (error) {
      console.error('Error updating profile:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCareerGoalChange = (option) => {
    setSelectedCareerGoals((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option)
      } else {
        return [...prev, option]
      }
    })
  }

  const handleResourceTypeChange = (option) => {
    setSelectedResourceTypes((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option)
      } else {
        return [...prev, option]
      }
    })
  }

  return (
    <PageWrapper>
      <NavbarWrapper>
        <JobSeekerNav />
      </NavbarWrapper>
      <PageContainer>
        <TitleContainer>
          <TitleBar>
            <BackButton onClick={() => navigate(-1)}>
              <ArrowLeft size={18} />
            </BackButton>
            <PageTitle>Personalize Your Resource Recommendations</PageTitle>
            <ProgressTag>In Progress</ProgressTag>
          </TitleBar>
        </TitleContainer>
        <FormContainer>
          <FormCard>
            <PageTitle
              style={{
                borderTop: '1px solid #e5e7eb',
                fontWeight: '500',
                marginBottom: '1rem'
              }}
            ></PageTitle>
            <PageTitle fontSize="1.5rem">
              Personalize Your Resource Recommendations
            </PageTitle>
            <Description>
              Tell us about your goals to receive tailored resources just for
              you.
            </Description>
            <FormGroup>
              <Label>What is your primary career goal today?</Label>
              <MultiSelect>
                <CheckboxGroup>
                  {careerGoalOptions.map((option) => (
                    <CheckboxLabel key={option}>
                      <Checkbox
                        checked={selectedCareerGoals.includes(option)}
                        onChange={() => handleCareerGoalChange(option)}
                      />
                      <span>{option}</span>
                    </CheckboxLabel>
                  ))}
                </CheckboxGroup>
              </MultiSelect>
            </FormGroup>
            <FormGroup>
              <Label>What types of resources are you most interested in?</Label>
              <MultiSelect>
                <CheckboxGroup>
                  {resourceOptions.map((option) => (
                    <CheckboxLabel key={option}>
                      <Checkbox
                        checked={selectedResourceTypes.includes(option)}
                        onChange={() => handleResourceTypeChange(option)}
                      />
                      <span>{option}</span>
                    </CheckboxLabel>
                  ))}
                </CheckboxGroup>
              </MultiSelect>
            </FormGroup>
            <FormGroup>
              <Label>Are you interested in attending in-person events?</Label>
              <Select
                value={inPersonPreference}
                onChange={(e) => setInPersonPreference(e.target.value)}
              >
                <option value="">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Select>
            </FormGroup>
            <Button
              onClick={handleSubmit}
              disabled={
                isSubmitting ||
                !selectedCareerGoals.length ||
                !selectedResourceTypes.length ||
                !inPersonPreference
              }
              style={{
                marginTop: '3rem',
                width: '100%',
                opacity:
                  isSubmitting ||
                  !selectedCareerGoals.length ||
                  !selectedResourceTypes.length ||
                  !inPersonPreference
                    ? 0.5
                    : 1
              }}
            >
              {isSubmitting ? 'Submitting...' : 'Get your resources'}
              {!isSubmitting && <ArrowRight size={14} />}
            </Button>
          </FormCard>
        </FormContainer>
      </PageContainer>
    </PageWrapper>
  )
}

// Export the protected version of the component
export default withResourceProtection(ResourceIntake)
