import React, { useEffect, useState, useRef } from 'react';
import JobSeekerNav from './JobSeekerNav';
import styled from 'styled-components';
import {
    ArrowLeft,
    Bookmark,
    TicketCheck,
    CalendarDays,
    Clock,
    MapPin,
    Star,
    ArrowRight,
  } from 'lucide-react'

const softColors = {
    background: '#f0f4f8',
    card: '#ffffff',
    primary: '#4a90e2',
    secondary: '#f6e05e',
    text: '#2d3748',
    textLight: '#718096',
    border: '#e2e8f0',
    success: '#68d391',
    warning: '#f6ad55',
    danger: '#fc8181',
    info: '#63b3ed',
    icons: '#12C2E8',
    icontext: '#C859FF',
    yellow: '#f6e05e'
}

const testData = {
    title: 'New Year Career Workshop',
    date: 'May 30 - 31, 2024',
    time: '10:00AM CT',
    mode: 'In-Person',
    address: '12345 Address',
    host: 'Parul Khosla',
    target: ['Entry Level Job Seekers', 'Job Seekers looking to pivot'],
    description: "Mapping out your 2025 Job Search Strategy (we’re all about productivity + intention), Get insights into the job market and what recruiters/hiring managers are looking for in top talent - yep, no gatekeeping. You’ll get full transparency from our founders who know the BTS of how companies in these industries often make their hiring decisions. Learn how Arena can help you speed up your job search to secure offers and overall career growth in the industry."
}

const testCards = [
    { month: 'May', day: '30', title: 'New Year Career Workshop', venue: 'Madison Square Garden, New York', time: '10:00AM CT', flag: 'Technology', ratings: '10 Interested'},
    { month: 'June', day: '12-13', title: 'Event 2', venue: 'Venue B', time: '2:00PM CT', flag: 'Networking' },
    { month: 'July', day: '9', title: 'Event 3', venue: 'Venue C', time: '6:00PM CT', flag: 'Technology & Innovation', ratings: '5 Interested'},
    { month: 'May', day: '30', title: 'Event 1', venue: 'Venue A', time: '10:00AM CT', flag: 'Media', ratings: '10 Interested'},
    { month: 'June', day: '12-13', title: 'Event 2', venue: 'Venue B', flag: 'Professional Baseball', time: '2:00PM CT' },
    { month: 'July', day: '9', title: 'Event 3', venue: 'Venue C', time: '6:00PM CT', flag: 'Technology', ratings: '5 Interested'},
    { month: 'May', day: '30', title: 'Event 1', venue: 'Venue A', time: '10:00AM CT', flag: 'Media', ratings: '10 Interested'},
    { month: 'June', day: '12-13', title: 'Event 2', venue: 'Venue B', flag: 'Professional Baseball', time: '2:00PM CT' },
    { month: 'July', day: '9', title: 'Event 3', venue: 'Venue C', time: '6:00PM CT', flag: 'Technology', ratings: '5 Interested'},
  ]

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f8f9fa;
  overflow-y: auto;
  padding: 3rem 5rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
 
`
const EventImage = styled.div`
  width: 60%;
  aspect-ratio: 2;
  border-radius: 8px;
  background-image: url(${(props) => props.src});
  background-color: #e2e8f0; // for no picture
  background-size: cover;
  background-position: center;
  position: relative;
  border: 4px solid white;
  justify-self: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`
const EventTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #2D2C3C;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`
const SubTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${softColors.text};
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`
const EventDescription = styled.div`
  font-size: 1rem;
  color: #4a5568;
  padding-bottom: 1.5rem;
`
const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${(props) => props.backgroundColor || '#caaae1'};
  color: ${(props) => props.color || 'white'};
  border: ${(props) => props.border || 'none'};

  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease; 
  border-radius: ${(props) => props.borderRadius || '0.375rem'};
  padding: ${(props) => props.padding || '0.6rem 2rem'};
  
  &:hover {
    background-color: #b799d4;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
  }
`
const NavigateButton = styled(Button)`
  flex: 0 0 auto;
  padding: 0.5rem;
  color: #4a5568;
  background-color: #f8f9fa;
  border: 1px solid #4a5568;

  &:hover {
    background-color: #e5e7eb;
  }

  @media (max-width: 768px) {
    padding: 0.25rem;
  }
`
const BackButton = styled(Button)`
  background-color: rgba(202, 170, 225, 0.3);
  margin-bottom: 0.5rem;
  color: #805AD5;
  border: none;
  cursor: pointer;
  font-size: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: rgba(202, 170, 225, 0.5);
  }
`
const EventDetail = styled.p`
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
const CardGrid = styled.div`
  display: grid;
  padding: 0.5rem;
  margin: 0.5rem;

  grid-auto-flow: column;
  grid-auto-columns: 18rem;
  grid-gap: 1rem;

  overflow-x: auto;
  scroll-behavior: smooth;
  
  /* TO HIDE SCROLLBAR */
   scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
    }
  @media (max-width: 768px) {
    grid-auto-columns: 14rem;
  }
`
const EventCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  width: 18rem;
 

  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  @media (max-width: 768px) {
    width: 14rem;
  }
`
const CardImage = styled.div`
  width: 100%;
  aspect-ratio: 2;
  border-radius: 8px 8px 0px 0px;
  background-image: url(${(props) => props.src});

  background-color: #e2e8f0; // for no picture
  background-size: cover;
  background-position: center;
  position: relative;
  justify-self: center;
`
const CardInfo = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem 2rem;
  gap: 1rem;
`
const CardTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  color: #1a202c;
`
const CardDate = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0rem;
  color: ${(props) => props.color || 'black'};
  text-align: center;
  
`
const CardDetail = styled.p`
  color: #4a5568;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
const CardFlag = styled.span`
  position: absolute; 
  border-radius: 0px 8px 0px 0px; 
  bottom: 0px ; 
  left: 0px ; 
  background-color: #CAAAE1; 
  color: #805AD5; 
  padding: 4px 12px; 
  font-size: 10px; 
  font-weight: bold;
`

export default function EventPage() {
    const cardGridRef = useRef(null);

    const scrollLeft = () => {
    cardGridRef.current.scrollBy({ left: -300, behavior: 'smooth' })
    }

    const scrollRight = () => {
    cardGridRef.current.scrollBy({ left: 300, behavior: 'smooth' })
    }

    return (
        <PageWrapper>
            <NavbarWrapper>
                <JobSeekerNav />
            </NavbarWrapper>
            <PageContainer>
                <BackButton><ArrowLeft size={14}/> Back </BackButton>
                <EventImage></EventImage>
                <InfoContainer>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <EventTitle>{testData.title}</EventTitle>
                        <Bookmark size={24} color='#caaae1'/>
                    </div>

                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <InfoContainer>
                            <SubTitle>Date and Time</SubTitle>
                            <EventDetail>
                                <CalendarDays size={18}/>{testData.date}
                            </EventDetail>
                            <EventDetail>
                                <Clock size={18}/>{testData.time}
                            </EventDetail>
                        </InfoContainer>
                        <Button style={{alignSelf: 'flex-start'}}><TicketCheck size={18}/>Register</Button>

                    </div>
                    <InfoContainer>
                        <SubTitle>Location</SubTitle>
                        <EventDetail>{testData.mode}</EventDetail>
                        <EventDetail>
                                <MapPin size={18}/> {testData.address}
                        </EventDetail>
                    </InfoContainer>
                    <InfoContainer>
                        <SubTitle>Hosted by</SubTitle>
                       
                            
                            <EventDetail>
                                <EventImage style={{width: '3rem', aspectRatio: '1', justifySelf: 'start', boxShadow: 'none'}}></EventImage>
                                {testData.host}
                            </EventDetail>
                    
                    </InfoContainer>
                    <InfoContainer>
                        <SubTitle>Target Audience</SubTitle>
                        <EventDetail>This event is for</EventDetail>
                        <EventDetail>
                            <ul style={{marginTop: '0rem', marginBottom: '0rem'}}>
                                {testData.target.map((targetAudience) => (
                                    <li key={targetAudience}>{targetAudience}</li>
                                ))}
                            </ul>
                        </EventDetail>
                    </InfoContainer>
                    <InfoContainer>
                        <SubTitle>Event Description</SubTitle>
                        <EventDescription style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', borderBottom: '1px solid #e5e7eb' }}>
                            {testData.description}
                        </EventDescription>
                    </InfoContainer>
                </InfoContainer>


                <SubTitle>Other Events You May Like</SubTitle>
                <div style={{display: 'flex', alignItems: 'center',}}>
                    <NavigateButton onClick={scrollLeft}><ArrowLeft size={12}/></NavigateButton>

                    <InfoContainer> 
                        <CardGrid ref={cardGridRef}>
                            {testCards.map((testCard, index) => (
                                <EventCard key={index}>
                                    <CardImage>
                                        <CardFlag>{testCard.flag}</CardFlag>
                                    </CardImage>
                                    <CardInfo>
                                        <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        >
                                            <CardDate color="#805AD5">{testCard.month}</CardDate>
                                            <CardDate>{testCard.day}</CardDate>
                                        </div>
                                        <div>
                                            <CardTitle>{testCard.title}</CardTitle>
                                            <CardDetail>{testCard.venue}</CardDetail>
                                            <CardDetail>{testCard.time}</CardDetail>
                                            {testCard.ratings && (
                                                <CardDetail>
                                                    <Star size={12} color="#805AD5" fill="#805AD5" />
                                                    {testCard.ratings}
                                                </CardDetail>
                                            )}
                                        </div>
                                    </CardInfo>
                                </EventCard>
                            ))}
                        </CardGrid>
                    </InfoContainer>

                    <NavigateButton onClick={scrollRight}><ArrowRight size={12}/></NavigateButton>

                </div>
    
                
                

            </PageContainer>
        </PageWrapper>

    )}