import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  Mail,
  MapPin,
  Linkedin,
  FileText,
  Edit,
  Ski,
  Landmark,
  Save,
  LineChart,
  Briefcase,
  Inbox,
  Search,
  Building,
  FileCheck,
  MessageSquare,
  Settings,
  LogOut,
  Image,
  Video,
  Github,
  Flag,
  Globe,
  Clock,
  MapPinIcon,
  Upload,
  X,
  VideotapeIcon,
  Instagram,
  Users,
  TrophyIcon,
  ChevronLeft,
  GraduationCap,
  ChevronRight
} from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'
import { getUserByFirebaseUid } from '../../models/User'
import { auth, db } from '../../firebase'
import { storage } from '../../firebase'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { toast } from 'react-toastify'

const leagues = [
  'NCAA Division I (National Collegiate Athletics Association)',
  'NCAA Division II (National Collegiate Athletics Association)',
  'NCAA Division III (National Collegiate Athletics Association)',
  'NAIA (National Association of Intercollegiate Athletics)',
  'NJCAA (National Junior College Athletic Association)',
  'AHL (American Hockey League)',
  'APP (Association of Pickleball Professionals)',
  'ATP (Association of Tennis Professionals)',
  'AUDL (American Ultimate Disc League)',
  'ECHL (East Coast Hockey League)',
  'IFL (Indoor Football League)',
  'LOVB (League One Volleyball)',
  'LPGA (Ladies Professional Golf Association)',
  'MLB (Major League Baseball)',
  'MLL (Major League Lacrosse)',
  'MLP (Major League Pickleball)',
  'MLR (Major League Rugby)',
  'MLS (Major League Soccer)',
  'NBA (National Basketball Association)',
  'G League (NBA G League)',
  'NFL (National Football League)',
  'NHL (National Hockey League)',
  'NISA (National Independent Soccer Association)',
  'NLL (National Lacrosse League)',
  'NPF (National Pro Fastpitch)',
  'NPL (National Pickleball League)',
  "NWSL (National Women's Soccer League)",
  'PBA (Professional Bowlers Association)',
  'PBR (Professional Bull Riders)',
  'PGA (Professional Golf Association)',
  'PHF (Premier Hockey Federation)',
  'PLL (Premier Lacrosse League)',
  'PPA (Professional Pickleball Association)',
  'PVL (Pro Volleyball League)',
  "PWBA (Professional Women's Bowling Association)",
  'USL (United Soccer League)',
  "WFA (Women's Football Alliance)",
  "WNBA (Women's National Basketball Association)",
  "WPLL (Women's Professional Lacrosse League)",
  "WTA (Women's Tennis Association)",
  'XFL (Xtreme Football League)',
  'Olympic Team Member',
  'Olympic Trials Qualifier',
  'Olympic National Team Member',
  'Paralympic Team Member',
  'Paralympic National Team Member',
  'Paralympic Trials Qualifier',
  'Olympic Junior National Team',
  'Youth Olympic Games',
  'Other'
]

const INDUSTRIES = [
  'Entertainment',
  'Fashion',
  'Gaming',
  'Health/Wellness',
  'Media',
  'Music',
  'Sports',
  'TV/Film'
]

const COMPANY_TYPES = [
  'Agency',
  'Apparel Manufacturer',
  'Audio/Podcasting',
  'Broadcast Network',
  'College/University',
  'Collegiate Athletics',
  'Department Stores/Retailers',
  'Digital Media',
  'DTC Brands',
  'E-Commerce',
  'Esports',
  'Fast Fashion',
  'Food/Beverage',
  'Footwear',
  'Gambling/Casino',
  'Gym/Training',
  'Jewelry/Accessories',
  'Live Events',
  'Luxury Fashion',
  'Music Label',
  'Nonprofit',
  'Pro League',
  'Pro Team',
  'Publishing/Print Media',
  'Recording Studio/Production',
  'Social Media',
  'Startup',
  'Streaming Platform',
  'Studios/Productions',
  'Tech/Analytics',
  'Theme Parks',
  'Venue/Stadium',
  'Video Games/AR/VR',
  'Youth Sports',
  'Other'
]

const JOB_FUNCTIONS = [
  'Accounting',
  'Account Management',
  'Advertising',
  'Animation/Visual Effects',
  'Athlete',
  'Athletic Training/Medical',
  'Broadcasting/On-Air Talent',
  'Business Development',
  'Casting',
  'Celebrity/Athlete Representation',
  'Choreography',
  'Communications',
  'Community Relations/Philanthropy',
  'Creative/Design',
  'Customer Experience',
  'Cybersecurity',
  'Data Analytics',
  'Diversity, Equity & Inclusion',
  'Editorial/Journalism',
  'Events/Hospitality',
  'Facility/Venue Operations',
  'Fan Engagement',
  'Finance',
  'Human Resources',
  'Information Technology',
  'Instructor/Teacher',
  'Legal/Compliance',
  'Licensing/Merchandise',
  'Marketing',
  'Mental Health/Wellness',
  'Nutrition/Dietician',
  'Partnerships/Sponsorships',
  'Performer',
  'Photo/Video',
  'Player Development',
  'Private Equity/Venture Capital',
  'Production',
  'Product Management',
  'Public Relations',
  'Referee/Umpire',
  'Retail Buying',
  'Sales',
  'Scouting/Player Personnel',
  'Security',
  'Social Media/Content Creation',
  'Software Engineering',
  'Sports Coach',
  'Stage Management',
  'Strategy/Operations',
  'Supply Chain/Inventory',
  'Team Operations',
  'User Experience',
  'Voice/Acting Coach',
  'Web Development',
  'Other (specify)'
]

const experienceLevels = [
  'None/Student',
  '1-2 years',
  '3-5 years',
  '6-10 years',
  '11-15 years',
  '16+ years'
]
const currentLevels = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]
const languageOptions = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'French',
  'German',
  'Japanese',
  'Russian',
  'Portuguese',
  'Arabic',
  'Hindi',
  'Italian',
  'Korean',
  'Dutch',
  'Turkish',
  'Swedish',
  'Polish',
  'Greek',
  'Vietnamese',
  'Hebrew',
  'Thai',
  'Indonesian',
  'Danish',
  'Finnish',
  'Norwegian',
  'Czech'
].sort()
const workPreferences = [
  'Fully In-Office',
  'Fully Remote',
  'Hybrid (1 day a week from home)',
  'Hybrid (2 days a week from home)',
  'Hybrid (3 days a week from home)',
  'Hybrid (4 days a week from home)'
]

const strengthsOptions = [
  'Communication',
  'Leadership',
  'Problem Solving',
  'Teamwork',
  'Adaptability',
  'Creativity',
  'Time Management',
  'Critical Thinking',
  'Attention to Detail',
  'Emotional Intelligence',
  'Strategic Planning',
  'Networking',
  'Decision Making',
  'Conflict Resolution',
  'Analytical Skills',
  'Project Management',
  'Negotiation',
  'Public Speaking',
  'Customer Service',
  'Technical Proficiency',
  'Data Analysis',
  'Research',
  'Writing',
  'Multitasking',
  'Sales',
  'Marketing',
  'Budgeting',
  'Coaching',
  'Innovation',
  'Interpersonal Skills'
]

const technicalSkillsOptions = [
  'Adobe Creative Suite',
  'Adobe XD',
  'Airtable',
  'Amplitude',
  'Applicant Tracking Systems/ATS',
  'Asana',
  'AutoCAD',
  'AWS',
  'Azure',
  'C++',
  'Calendly',
  'CI/CD',
  'ClickUp',
  'Confluence',
  'Docker',
  'Figma',
  'G Suite',
  'Git',
  'GitHub/GitLab',
  'Google Analytics',
  'HRIS Systems',
  'HubSpot',
  'Java',
  'JavaScript',
  'Jenkins',
  'Jira',
  'Kubernetes',
  'Linear',
  'Looker',
  'Microsoft Dynamics',
  'Microsoft Excel',
  'Microsoft PowerPoint',
  'Microsoft SharePoint',
  'Microsoft Teams',
  'Microsoft Word',
  'Miro',
  'Mixpanel',
  'Monday.com',
  'MongoDB',
  'MySQL',
  'NetSuite',
  'Node.js',
  'Notion',
  'Oracle',
  'Oracle ERP',
  'PostgreSQL',
  'Postman',
  'Python',
  'QuickBooks',
  'React.js',
  'Redis',
  'REST APIs',
  'Revit',
  'SAP',
  'Salesforce',
  'ServiceNow',
  'Sketch',
  'Slack',
  'SQL',
  'Tableau',
  'Trello',
  'TypeScript',
  'Unity',
  'Workday',
  'Zendesk',
  'Zoom',
  'Other'
]
const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100vh;
  background-color: #f0f4f8;
  @media (max-width: 768px) {
  }
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
  @media (max-width: 768px) {
    height: 100vh;
  }
`

const PageContainer = styled.div`
  flex: 1;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 30px auto;
  padding: 2rem;
  position: relative;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const ProfileContent = styled.div`
  display: flex;
  margin-top: -5rem;
  position: relative;
  margin-left: 40px;
  z-index: 20;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
    margin-left: 0px;
    margin-top: -5px;
  }
`

const MainContent = styled.div`
  width: 65%;
  padding-right: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`

const MainContent2 = styled.main`
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
`

// const CoverPhoto = styled.div`
//   height: 250px;
//   background-image: url(${(props) => props.src});
//   background-size: cover;
//   background-position: center;
//   border-radius: 8px 8px 0 0;
//   position: relative;

//   @media (max-width: 768px) {
//     height: 60px; // Reduced height for mobile devices
//   }
// `

const EditButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`

const ProfileCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
`

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ProfileInfo = styled.div``

const ProfileName = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const ProfileTitle = styled.p`
  font-size: 1.1rem;
  color: #4b5563;
  margin-top: -3px;
`

const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0.35rem 0.8rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${(props) =>
    props.variant === 'success' ? '#10B981' : '#E5E7EB'};
  color: ${(props) => (props.variant === 'success' ? 'white' : '#4B5563')};
  margin-bottom: 1rem;
`

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  font-size: 1rem;
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  min-height: 100px;
  font-size: 1rem;
`

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  color: #4b5563;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #1f2937;
  }

  svg {
    margin-right: 0.5rem;
  }
`

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
`

const DetailParagraph = styled.p`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const FlagText = styled.span`
  margin-left: 0.5rem;
`

const SkillTag = styled(Badge)`
  background-color: #caaae1;
  color: white;
`

const FileInput = styled.input`
  display: none;
`

const ModernInput = styled.input`
  width: 95%;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-family: 'Open sans', sans-serif;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  outline: none;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.2);
  }
`

const ModernTextArea = styled.textarea`
  width: 95%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Open sans', sans-serif;
  transition: border-color 0.3s ease;
  outline: none;
  min-height: 100px;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.2);
  }
`
const Sidebar = styled.div`
  width: 35%;
  margin-right: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`

const ModernSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  outline: none;
  margin-top: 10px;
  margin-bottom: 15px;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.2);
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 0.15rem;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CheckboxInput = styled.input`
  margin-right: 0.5rem;
`

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ef4444;
  padding: 0.25rem;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  &:hover {
    color: #dc2626;
  }
`

const AddButton = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    background-color: #b799d4;
  }
`

const AthleteStatusEntry = styled.div`
  border-bottom: 1px solid #d1d5db;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const IconWrapper = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`

const SectionTitle = styled.h2`
  font-size: 1.75rem; /* Increased font size for larger section title */
  font-weight: 700;
  margin-bottom: 0;
  color: #333;
`

const WorkHistoryItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  position: relative;
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const WorkDetails = styled.div`
  flex-grow: 1;
  font-size: 1rem;
`

const TitleEmployer = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const DateRange = styled.p`
  font-size: 0.95rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
`

const JobDetails = styled.p`
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
`

const EditIconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #caaae1;
  display: flex;
  align-items: center;

  &:hover {
    color: #b799d4;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 1.5rem; /* Space between items */
  margin-bottom: 2rem;
  @media (max-width: 1024px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
`

const GridItemFullWidth = styled.div`
  grid-column: span 2; /* Spans across both columns */
  display: flex;
  flex-direction: column;
`

const EditLabel = styled.label`
  font-size: 0.9rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`

const EditInput = styled.input`
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.3);
  }
`

const EditTextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 1rem;
  min-height: 120px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.3);
  }
`

const ButtonRow = styled.div`
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const SaveButton = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #b799d4;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
  }
`

const CancelButton = styled.button`
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #dc2626;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
  }
`
const StrengthBubble = styled.div`
  background-color: #caaae1; // Your specified bubble color
  border-radius: 15px;
  padding: 8px 12px;
  margin: 5px;
  color: white;
  display: inline-flex;
  align-items: center;
`

const ScrollButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(202, 170, 225, 0.8);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background-color: rgba(202, 170, 225, 1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const LeftScrollButton = styled(ScrollButton)`
  left: 0;
`

const RightScrollButton = styled(ScrollButton)`
  right: 0;
`

const FileInputLabel = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #caaae1;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 0.5rem;

  &:hover {
    background-color: #b799d4;
  }

  svg {
    margin-right: 0.5rem;
  }
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: none; // Hide the toggle container on mobile devices
  }
`

const ToggleLabel = styled.label`
  margin-right: 0.5rem;
  font-size: 1rem;
  color: #4b5563;
`

const ToggleSwitch = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.checked ? '#10B981' : '#E5E7EB')};
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`

const ToggleSlider = styled.div`
  position: absolute;
  top: 2px;
  left: ${(props) => (props.checked ? '22px' : '2px')};
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.3s ease;
`

const ResumePageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f3f4f6;
  padding: 1rem;
  position: relative;
`

const ResumeViewer = styled.div`
  width: 100%;
  height: calc(100vh - 80px); // Account for padding and button height
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`

const ResumeFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

const UploadButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;

  &:hover {
    background-color: #b799d4;
  }
`

const NoResumeMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: #6b7280;
  font-size: 1.1rem;
`

const UploadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
`
const ResumeContainer = styled.div`
  min-height: calc(100vh - 120px); // Account for navbar and toggle
  width: 100%;
  background-color: #f3f4f6;
  position: relative;
`

const ExperienceSection = styled.div`
  margin-bottom: 2rem;
`

const ExperienceTitle = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1.5rem;
`

const ExperienceCard = styled(ProfileCard)`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const SmallPill = styled(StrengthBubble)`
  font-size: 0.8rem;
  padding: 4px 8px;
  margin: 3px;
  background-color: #caaae1;
  opacity: 0.9;
`

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  margin: 0.5rem 0;
`

const PillSection = styled.div`
  margin: 0.75rem 0;
`

const PillLabel = styled.div`
  font-size: 0.85rem;
  color: #666;
  margin-bottom: 0.3rem;
  font-weight: 500;
`

const MultiSelectContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding: 0.5rem;
  margin-top: 0.3rem;
  background: white;
`

const MultiSelectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
`
const PortfolioContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 0 1rem;
`

const PortfolioScroller = styled.div`
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
  padding: 1rem 0;
  scroll-behavior: smooth;

  /* Hide scrollbar but keep functionality */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const PortfolioItem = styled.div`
  min-width: 250px;
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const PortfolioImageContainer = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 0.375rem;
  overflow: hidden;
  background-color: #f3f4f6;
  margin-bottom: 1rem;
`

const PortfolioImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`

const PortfolioTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #caaae1;
  text-decoration: underline;
`
const ProfilePicture = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  position: relative;
  border: 4px solid white;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`

const EditProfilePictureButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`
const ProBadgeCorner = styled.img`
  position: absolute;
  top: 0;
  margin-top: -20px;
  right: 20px;
  width: 100px;
  height: 100px;
  z-index: 1;

  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
  }
`

const CoverPhoto = styled.div`
  height: 250px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 8px 8px 0 0;
  position: relative;

  @media (max-width: 768px) {
    height: 60px;
  }
`
const ProBanner = styled.div`
  background-color: #d29856;
  padding: 6px 16px;
  position: fixed;
  top: 1;
  margin-top: 10px;
  right: 0;
  z-index: 1001;
  border-bottom-left-radius: 8px;
  font-family: 'Roboto', sans-serif;
`

const ProText = styled.span`
  color: white;
  font-size: 14px;
`

export default function JobSeekerProfile() {
  const [editingSections, setEditingSections] = useState({})
  const [profile, setProfile] = useState({
    education_history: [],
    work_history: [],
    athletic_history: [],
    portfolio_profile_links: [],
    other_technical_skills: [],
    community_groups: [],

    top_strengths: [],
    videos: []
  })
  const [user, setUser] = useState(null)

  const [editingAboutMe, setEditingAboutMe] = useState(false)
  const [tempAboutMe, setTempAboutMe] = useState(profile?.about || '')
  const [editingProfilePicture, setEditingProfilePicture] = useState(false)
  const [tempEducationHistory, setTempEducationHistory] = useState([
    ...(profile.education || [])
  ])
  const [isUploading, setIsUploading] = useState(false)

  const [tempProfilePicture, setTempProfilePicture] = useState(
    profile?.profile_picture || ''
  )
  const [editingEducation, setEditingEducation] = useState(false)
  const [editingPortfolio, setEditingPortfolio] = useState(false)
  const portfolioRef = useRef(null)
  const [editingTechnicalSkills, setEditingTechnicalSkills] = useState(false)
  const [technical_skills, setTechnicalSkills] = useState([])

  const [portfolioItems, setPortfolioItems] = useState([])
  const [editingAdditionalDetails, setEditingAdditionalDetails] =
    useState(false)

  const hasWorkHistory = profile?.work_history?.length > 0
  const [showResume, setShowResume] = useState(false)
  const [resumeUrl, setResumeUrl] = useState('')
  const [editingResume, setEditingResume] = useState(false)
  const [tempResumeFile, setTempResumeFile] = useState(null)
  const firstJob = profile?.work_history?.[0]
  const [editing, setEditing] = useState(false)
  const [uploading, setUploading] = useState(false)

  const [tempWorkHistory, setTempWorkHistory] = useState([])
  const [tempCommunityGroups, setTempCommunityGroups] = useState([])
  const [newGroup, setNewGroup] = useState('') //for adding new groups
  const [editingCommunityGroups, setEditingCommunityGroups] = useState(false)
  const [tempAthleticHistory, setTempAthleticHistory] = useState([])
  const [selectedStrengths, setSelectedStrengths] = useState([])
  const [editingStrengths, setEditingStrengths] = useState(false)
  const [editingVideo, setEditingVideo] = useState(false)
  const [tempVideoUrl, setTempVideoUrl] = useState(
    profile?.videos?.[0]?.url || ''
  )
  const [tempVideoTitle, setTempVideoTitle] = useState(
    profile?.videos?.[0]?.title || ''
  )
  const [tempVideoFile, setTempVideoFile] = useState(null)

  const fileInputRef = useRef(null)

  useEffect(() => {
    const fetchProfileData = async () => {
      const currentUser = auth.currentUser
      if (currentUser) {
        try {
          const userData = await getUserByFirebaseUid(currentUser.uid)
          setUser(userData)

          const jobseekerProfileData = await getJobseekerProfile(
            currentUser.uid
          )

          // Get user location from their address
          const { city, state, country } = getLocationFromAddress(
            jobseekerProfileData?.address
          )

          // Ensure all arrays are initialized even if data is missing
          const profileWithDefaults = {
            education: [],
            work_history: [],
            athletic_history: [],
            portfolio_profile_links: [],
            top_strengths: [],
            technical_skills: [],
            community_groups: [],

            videos: [],
            ...jobseekerProfileData,
            // only load city, state, and country from address if they arent yet populated
            city: jobseekerProfileData.city || city,
            state: jobseekerProfileData.state || state,
            country: jobseekerProfileData.country || country
          }

          setProfile(profileWithDefaults)

          setTempWorkHistory(profileWithDefaults.work_history || [])
          setTempAthleticHistory(profileWithDefaults.athletic_history || [])
          setPortfolioItems(profileWithDefaults.portfolio_profile_links || [])
          setSelectedStrengths(profileWithDefaults.top_strengths || [])
          setResumeUrl(profileWithDefaults.resume_url || '')

          // Set initial states for temporary values
          setTempAboutMe(profileWithDefaults.about || '')
          setTempEducationHistory([...profileWithDefaults.education_history])
          setTempCommunityGroups(profileWithDefaults.community_groups || [])
          setTempProfilePicture(profileWithDefaults.profile_picture || '')
          setTempVideoUrl(profileWithDefaults.videos?.[0]?.url || '')
          setTempVideoTitle(profileWithDefaults.videos?.[0]?.title || '')
          console.log(profile)
        } catch (error) {
          console.error('Error fetching profile data:', error)
        }
      }
    }

    fetchProfileData()
  }, [])

  const formatDate = (dateString) => {
    if (!dateString) return 'Present'
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
  }

  const handleImageUpload = async (e) => {
    const file = e.target.files[0]

    if (file) {
      const fileRef = storage
        .ref()
        .child(`profile-photos/${user.id}/${file.name}`)

      try {
        await fileRef.put(file)
        const newProfilePictureUrl = await fileRef.getDownloadURL()
        setTempProfilePicture(newProfilePictureUrl)
      } catch (error) {
        console.error('Error uploading profile picture:', error)
      }
    }
  }

  const handleEditClick = () => {
    setEditing(true)
  }

  const handleCancel = () => {
    setEditing(false)
    setTempWorkHistory(profile?.work_history || []) // Reset changes
  }

  const handleEditAboutMeClick = () => {
    setEditingAboutMe(true)
    setTempAboutMe(profile?.about || '')
  }
  const handleEditProfilePicture = () => {
    setEditingProfilePicture(true)
  }

  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0]
    if (file) {
      const storageRef = ref(
        storage,
        `profile-pictures/${user.id}/${file.name}`
      )
      try {
        await uploadBytes(storageRef, file)
        const downloadURL = await getDownloadURL(storageRef)
        setTempProfilePicture(downloadURL)
      } catch (error) {
        console.error('Error uploading profile picture:', error)
      }
    }
  }
  const handleProfilePictureUpload = async (e) => {
    const file = e.target.files[0]
    if (file && user?.id) {
      setIsUploading(true)

      try {
        // Create a reference with the same path structure as the intake form
        const fileName = `profile-${user.id}-${Date.now()}-${file.name}`
        const photoPath = `profile-photos/${user.id}/${fileName}` // Notice profile-photos instead of profile-pictures
        const photoRef = ref(storage, photoPath)

        // Upload the file
        await uploadBytes(photoRef, file)
        const downloadURL = await getDownloadURL(photoRef)

        // Update the profile in the database
        await updateJobseekerProfile(user.id, {
          profile_picture: downloadURL
        })

        // Update local state
        setProfile((prev) => ({
          ...prev,
          profile_picture: downloadURL
        }))

        toast.success('Profile picture updated successfully!')
      } catch (error) {
        console.error('Error uploading profile picture:', error)
        toast.error('Failed to upload profile picture. Please try again.')
      } finally {
        setIsUploading(false)
      }
    }
  }

  const handleSaveProfilePicture = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          profile_picture: tempProfilePicture
        })
        setProfile({ ...profile, profile_picture: tempProfilePicture })
        setEditingProfilePicture(false)
      } catch (error) {
        console.error('Error updating profile picture:', error)
      }
    }
  }

  const handleCancelProfilePicture = () => {
    setEditingProfilePicture(false)
    setTempProfilePicture(profile?.profile_picture || '')
  }

  const handleSaveAboutMe = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, { about: tempAboutMe })
        setProfile({ ...profile, about: tempAboutMe })
        setEditingAboutMe(false)
      } catch (error) {
        console.error('Error updating About Me:', error)
      }
    } else {
      console.error('User ID is undefined')
    }
  }

  const handleCancelAboutMe = () => {
    setEditingAboutMe(false)
    setTempAboutMe(profile?.about || '')
  }

  const handlePresentToggle = (index) => {
    const updatedWorkHistory = [...tempWorkHistory]
    updatedWorkHistory[index].currentJob = !updatedWorkHistory[index].currentJob
    // Set end_date to null if currentJob is true, or keep the existing date
    updatedWorkHistory[index].end_date = updatedWorkHistory[index].currentJob
      ? null
      : updatedWorkHistory[index].end_date
    setTempWorkHistory(updatedWorkHistory)
  }

  const formatDateMonthYear = (dateString) => {
    if (!dateString) return ''
    const [year, month] = dateString.split('-')
    return `${new Date(year, month - 1).toLocaleString('en-US', {
      month: 'short'
    })} ${year}`
  }

  const handleSave = async () => {
    if (user && user.id) {
      // Check if user and user ID exist
      try {
        await updateJobseekerProfile(user.id, {
          work_history: tempWorkHistory,
          athletic_history: tempAthleticHistory
        })
        setProfile({
          ...profile,
          work_history: tempWorkHistory,
          athletic_history: tempAthleticHistory
        })
        setEditing(false) // Exit editing mode
      } catch (error) {
        console.error('Error updating profile:', error)
      }
    } else {
      console.error('User ID is undefined') // Log if user ID is missing
    }
  }

  const handleChange = (index, type, field, value) => {
    if (type === 'work') {
      const updatedWorkHistory = [...tempWorkHistory]
      updatedWorkHistory[index][field] = value
      setTempWorkHistory(updatedWorkHistory)
    } else if (type === 'athletic') {
      const updatedAthleticHistory = [...tempAthleticHistory]
      updatedAthleticHistory[index][field] = value
      setTempAthleticHistory(updatedAthleticHistory)
    }
  }

  const addWorkHistory = () => {
    const newWorkHistory = {
      title: '',
      employer: '',
      start_date: '',
      end_date: '',
      details: ''
    }
    setTempWorkHistory([...tempWorkHistory, newWorkHistory])
  }

  const addAthleticHistory = () => {
    const newAthleticHistory = {
      sport: '',
      league: '',
      start_date: '',
      end_date: '',
      achievements: ''
    }
    setTempAthleticHistory([...tempAthleticHistory, newAthleticHistory])
  }

  const handleSaveTechnicalSkills = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          technical_skills: profile.technical_skills,
          other_technical_skills: profile.other_technical_skills
        })
        setEditingTechnicalSkills(false)
      } catch (error) {
        console.error('Error updating technical skills:', error)
      }
    } else {
      console.error('User ID is undefined')
    }
  }
  const removeHistoryEntry = (index, type) => {
    if (type === 'work') {
      const updatedWorkHistory = [...tempWorkHistory]
      updatedWorkHistory.splice(index, 1)
      setTempWorkHistory(updatedWorkHistory)
    } else if (type === 'athletic') {
      const updatedAthleticHistory = [...tempAthleticHistory]
      updatedAthleticHistory.splice(index, 1)
      setTempAthleticHistory(updatedAthleticHistory)
    }
  }
  const renderProfilePictureSection = () => {
    return (
      <ProfilePicture src={profile?.profile_picture || '/default-profile.png'}>
        <EditProfilePictureButton
          onClick={() => fileInputRef.current?.click()}
          disabled={isUploading}
        >
          <Upload size={16} />
        </EditProfilePictureButton>

        <FileInput
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleProfilePictureUpload}
        />

        {isUploading && (
          <div
            style={{
              position: 'absolute',
              inset: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontSize: '0.75rem'
            }}
          >
            Uploading...
          </div>
        )}
      </ProfilePicture>
    )
  }

  const handleAthleticChange = (index, field, value) => {
    const updatedAthleticHistory = [...tempAthleticHistory] // Make a copy of the current athletic history state
    updatedAthleticHistory[index][field] = value // Update the specific field for the athletic history at the given index
    setTempAthleticHistory(updatedAthleticHistory) // Update the state with the new data
  }

  const renderExperienceSection = () => {
    return (
      <ExperienceSection>
        <ExperienceTitle>Experience</ExperienceTitle>

        {/* Work History Card */}
        <ExperienceCard>
          <HeaderRow>
            <SectionTitle>Work History</SectionTitle>
            {!editing && (
              <EditIconButton onClick={handleEditClick}>
                <Edit size={16} /> Edit
              </EditIconButton>
            )}
          </HeaderRow>

          {editing ? (
            <GridContainer>
              {tempWorkHistory.map((job, index) => (
                <div
                  key={index}
                  style={{
                    // Container for editing Jobs
                    backgroundColor: '#f9fafb',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    padding: '0.25rem'
                  }}
                >
                  {/* Basic Info */}
                  <GridItem>
                    <EditLabel>Job Title</EditLabel>
                    <EditInput
                      type="text"
                      value={job.title || ''}
                      onChange={(e) =>
                        handleChange(index, 'work', 'title', e.target.value)
                      }
                      placeholder="Title"
                    />
                  </GridItem>

                  <GridItem>
                    <EditLabel>Employer</EditLabel>
                    <EditInput
                      type="text"
                      value={job.employer || ''}
                      onChange={(e) =>
                        handleChange(index, 'work', 'employer', e.target.value)
                      }
                      placeholder="Employer"
                    />
                  </GridItem>

                  <GridItem>
                    <EditLabel>Start Date</EditLabel>
                    <EditInput
                      type="date"
                      value={job.start_date || ''}
                      onChange={(e) =>
                        handleChange(
                          index,
                          'work',
                          'start_date',
                          e.target.value
                        )
                      }
                    />
                  </GridItem>

                  <GridItem>
                    <EditLabel>End Date</EditLabel>
                    {!job.currentJob ? (
                      <EditInput
                        type="date"
                        value={job.end_date || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'work',
                            'end_date',
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      <p>Present</p>
                    )}
                    <CheckboxLabel>
                      <CheckboxInput
                        type="checkbox"
                        checked={job.currentJob || false}
                        onChange={() => handlePresentToggle(index)}
                      />
                      Present
                    </CheckboxLabel>
                  </GridItem>

                  {/* Industry Section */}
                  <GridItemFullWidth>
                    <PillSection>
                      <EditLabel>Industry</EditLabel>
                      <MultiSelectContainer>
                        <MultiSelectGrid>
                          {[
                            'Entertainment',
                            'Fashion',
                            'Gaming',
                            'Health/Wellness',
                            'Media',
                            'Music',
                            'Sports',
                            'TV/Film'
                          ].map((industry) => (
                            <CheckboxLabel key={industry}>
                              <CheckboxInput
                                type="checkbox"
                                checked={
                                  job.industry?.includes(industry) || false
                                }
                                onChange={(e) => {
                                  const newIndustries = e.target.checked
                                    ? [...(job.industry || []), industry]
                                    : job.industry?.filter(
                                        (i) => i !== industry
                                      )
                                  handleChange(
                                    index,
                                    'work',
                                    'industry',
                                    newIndustries
                                  )
                                }}
                              />
                              {industry}
                            </CheckboxLabel>
                          ))}
                        </MultiSelectGrid>
                      </MultiSelectContainer>
                    </PillSection>
                  </GridItemFullWidth>

                  {/* Company Type Section */}
                  <GridItemFullWidth>
                    <PillSection>
                      <EditLabel>Company Type</EditLabel>
                      <MultiSelectContainer>
                        <MultiSelectGrid>
                          {COMPANY_TYPES.map((type) => (
                            <CheckboxLabel key={type}>
                              <CheckboxInput
                                type="checkbox"
                                checked={
                                  job.company_type?.includes(type) || false
                                }
                                onChange={(e) => {
                                  const newTypes = e.target.checked
                                    ? [...(job.company_type || []), type]
                                    : job.company_type?.filter(
                                        (t) => t !== type
                                      )
                                  handleChange(
                                    index,
                                    'work',
                                    'company_type',
                                    newTypes
                                  )
                                }}
                              />
                              {type}
                            </CheckboxLabel>
                          ))}
                        </MultiSelectGrid>
                      </MultiSelectContainer>
                    </PillSection>
                  </GridItemFullWidth>

                  {/* Job Function Section */}
                  <GridItemFullWidth>
                    <PillSection>
                      <EditLabel>Job Function</EditLabel>
                      <MultiSelectContainer>
                        <MultiSelectGrid>
                          {JOB_FUNCTIONS.map((func) => (
                            <CheckboxLabel key={func}>
                              <CheckboxInput
                                type="checkbox"
                                checked={
                                  job.job_function?.includes(func) || false
                                }
                                onChange={(e) => {
                                  const newFunctions = e.target.checked
                                    ? [...(job.job_function || []), func]
                                    : job.job_function?.filter(
                                        (f) => f !== func
                                      )

                                  handleChange(
                                    index,
                                    'work',
                                    'job_function',
                                    newFunctions
                                  )

                                  // Clear other_job_function when unchecking "Other (specify)"
                                  if (
                                    func === 'Other (specify)' &&
                                    !e.target.checked
                                  ) {
                                    handleChange(
                                      index,
                                      'work',
                                      'other_job_function',
                                      ''
                                    )
                                  }
                                }}
                              />
                              {func === 'Other (specify)' ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    flex: 1
                                  }}
                                >
                                  {func}
                                  {job.job_function?.includes(
                                    'Other (specify)'
                                  ) && (
                                    <ModernInput
                                      type="text"
                                      value={job.other_job_function || ''}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          'work',
                                          'other_job_function',
                                          e.target.value
                                        )
                                      }
                                      placeholder="Please specify"
                                      style={{
                                        marginLeft: '0.5rem',
                                        width: '200px',
                                        height: '32px', // Make it more compact
                                        padding: '0.25rem 0.5rem' // Smaller padding
                                      }}
                                    />
                                  )}
                                </div>
                              ) : (
                                func
                              )}
                            </CheckboxLabel>
                          ))}
                        </MultiSelectGrid>
                      </MultiSelectContainer>
                    </PillSection>
                  </GridItemFullWidth>

                  <DeleteButton
                    onClick={() => removeHistoryEntry(index, 'work')}
                  >
                    <X size={12} /> Remove
                  </DeleteButton>
                </div>
              ))}
            </GridContainer>
          ) : (
            <>
              {tempWorkHistory?.map((job, index) => (
                <WorkHistoryItem key={index}>
                  <IconWrapper>
                    <Briefcase size={20} />
                  </IconWrapper>
                  <WorkDetails>
                    <TitleEmployer>
                      {job.title || 'Title not provided'} |{' '}
                      {job.employer || 'Employer not provided'}
                    </TitleEmployer>
                    <DateRange>
                      {formatDate(job.start_date)} - {formatDate(job.end_date)}
                    </DateRange>

                    {/* Industry Pills */}
                    {job.industry?.length > 0 && (
                      <PillSection>
                        <PillLabel>Industry</PillLabel>
                        <PillContainer>
                          {job.industry.map((ind) => (
                            <SmallPill key={ind}>{ind}</SmallPill>
                          ))}
                        </PillContainer>
                      </PillSection>
                    )}

                    {/* Company Type Pills */}
                    {job.company_type?.length > 0 && (
                      <PillSection>
                        <PillLabel>Company Type</PillLabel>
                        <PillContainer>
                          {job.company_type.map((type) => (
                            <SmallPill key={type}>{type}</SmallPill>
                          ))}
                        </PillContainer>
                      </PillSection>
                    )}

                    {/* Job Function Pills */}
                    {job.job_function?.length > 0 && (
                      <PillSection>
                        <PillLabel>Job Function</PillLabel>
                        <PillContainer>
                          {job.job_function.map((func) => (
                            <SmallPill key={func}>
                              {func === 'Other (specify)'
                                ? job.other_job_function || 'Other'
                                : func}
                            </SmallPill>
                          ))}
                        </PillContainer>
                      </PillSection>
                    )}
                  </WorkDetails>
                </WorkHistoryItem>
              ))}
            </>
          )}

          {editing && (
            <AddButton onClick={addWorkHistory}>Add Work History</AddButton>
          )}
        </ExperienceCard>

        {/* Athletic History Card */}
        <ExperienceCard>
          <HeaderRow>
            <SectionTitle>Athletic History</SectionTitle>
            {!editing && (
              <EditIconButton onClick={handleEditClick}>
                <Edit size={16} /> Edit
              </EditIconButton>
            )}
          </HeaderRow>

          {editing ? (
            <GridContainer>
              {tempAthleticHistory.map((history, index) => (
                <div
                  key={index}
                  style={{
                    // Container for editing athletic history
                    backgroundColor: '#f9fafb',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    padding: '0.25rem'
                  }}
                >
                  <GridItem>
                    <EditLabel>Sport</EditLabel>
                    <EditInput
                      type="text"
                      value={history.sport || ''}
                      onChange={(e) =>
                        handleChange(index, 'athletic', 'sport', e.target.value)
                      }
                      placeholder="Sport"
                    />
                  </GridItem>

                  <GridItem>
                    <EditLabel>Position</EditLabel>
                    <EditInput
                      type="text"
                      value={history.position || ''}
                      onChange={(e) =>
                        handleChange(
                          index,
                          'athletic',
                          'position',
                          e.target.value
                        )
                      }
                      placeholder="Position"
                    />
                  </GridItem>

                  <GridItem>
                    <EditLabel>Team</EditLabel>
                    <EditInput
                      type="text"
                      value={history.team || ''}
                      onChange={(e) =>
                        handleChange(index, 'athletic', 'team', e.target.value)
                      }
                      placeholder="Team Name"
                    />
                  </GridItem>

                  <GridItem>
                    <EditLabel style={{ marginTop: '5px' }}>
                      League/Division
                    </EditLabel>
                    <ModernSelect
                      value={history.league || ''}
                      onChange={(e) =>
                        handleChange(
                          index,
                          'athletic',
                          'league',
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select League/Division</option>
                      {leagues.map((league) => (
                        <option key={league} value={league}>
                          {league}
                        </option>
                      ))}
                    </ModernSelect>
                  </GridItem>

                  <GridItem>
                    <EditLabel>Start Date</EditLabel>
                    <EditInput
                      type="month"
                      value={history.start_date || ''}
                      onChange={(e) =>
                        handleChange(
                          index,
                          'athletic',
                          'start_date',
                          e.target.value
                        )
                      }
                    />
                  </GridItem>

                  <GridItem>
                    <EditLabel>End Date</EditLabel>
                    {!history.currentTeam ? (
                      <EditInput
                        type="month"
                        value={history.end_date || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'end_date',
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      <p>Present</p>
                    )}
                    <CheckboxLabel>
                      <CheckboxInput
                        type="checkbox"
                        checked={history.currentTeam || false}
                        onChange={() => {
                          const updatedHistory = [...tempAthleticHistory]
                          updatedHistory[index].currentTeam =
                            !updatedHistory[index].currentTeam
                          updatedHistory[index].end_date = updatedHistory[index]
                            .currentTeam
                            ? null
                            : updatedHistory[index].end_date
                          setTempAthleticHistory(updatedHistory)
                        }}
                      />
                      Present
                    </CheckboxLabel>
                  </GridItem>

                  <GridItemFullWidth>
                    <EditLabel>Achievements</EditLabel>
                    <EditTextArea
                      value={history.achievements || ''}
                      onChange={(e) =>
                        handleChange(
                          index,
                          'athletic',
                          'achievements',
                          e.target.value
                        )
                      }
                      placeholder="Add achievements, highlights, awards, records, or notable experiences"
                    />
                  </GridItemFullWidth>

                  <DeleteButton
                    onClick={() => removeHistoryEntry(index, 'athletic')}
                  >
                    <X size={12} /> Remove
                  </DeleteButton>
                </div>
              ))}
            </GridContainer>
          ) : (
            <>
              {tempAthleticHistory?.map((history, index) => (
                <WorkHistoryItem key={index}>
                  <IconWrapper>
                    <TrophyIcon size={20} />
                  </IconWrapper>
                  <WorkDetails>
                    <TitleEmployer>
                      {history.sport || ''} | {history.position || ''} |{' '}
                      {history.team || ''}
                    </TitleEmployer>
                    <div
                      style={{
                        color: '#6B7280',
                        fontSize: '0.95rem',
                        marginBottom: '0.5rem'
                      }}
                    >
                      {history.league || 'League not provided'}
                    </div>
                    <DateRange>
                      {formatDateMonthYear(history.start_date)} -{' '}
                      {history.currentTeam
                        ? 'Present'
                        : formatDateMonthYear(history.end_date)}
                    </DateRange>
                    {history.achievements && (
                      <JobDetails>{history.achievements}</JobDetails>
                    )}
                  </WorkDetails>
                </WorkHistoryItem>
              ))}
            </>
          )}

          {editing && (
            <AddButton onClick={addAthleticHistory}>
              Add Athletic History
            </AddButton>
          )}
        </ExperienceCard>

        {editing && (
          <ButtonRow>
            <SaveButton onClick={handleSave}>
              <Save size={16} /> Save
            </SaveButton>
            <CancelButton onClick={handleCancel}>
              <X size={16} /> Cancel
            </CancelButton>
          </ButtonRow>
        )}
      </ExperienceSection>
    )
  }
  const renderAboutMeSection = () => {
    if (editingAboutMe) {
      return (
        <div>
          <ModernTextArea
            value={tempAboutMe}
            onChange={(e) => setTempAboutMe(e.target.value)}
            placeholder="Tell us about yourself"
          />
          <ButtonRow>
            <SaveButton onClick={handleSaveAboutMe}>
              <Save size={16} /> Save
            </SaveButton>
            <CancelButton onClick={handleCancelAboutMe}>
              <X size={16} /> Cancel
            </CancelButton>
          </ButtonRow>
        </div>
      )
    }

    return (
      <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        <p>{profile?.about || 'Add an about me paragraph'}</p>
        <EditIconButton onClick={handleEditAboutMeClick}>
          <Edit size={16} /> Edit
        </EditIconButton>
      </div>
    )
  }

  const renderWorkHistory = () => {
    if (editing) {
      return (
        <>
          <GridContainer>
            {/* Render Work History */}
            {tempWorkHistory.map((job, index) => (
              <div key={index}>
                <GridItem>
                  <EditLabel>Job Title</EditLabel>
                  <EditInput
                    type="text"
                    value={job.title || ''}
                    onChange={(e) =>
                      handleChange(index, 'work', 'title', e.target.value)
                    }
                    placeholder="Title"
                  />
                </GridItem>
                <GridItem>
                  <EditLabel>Employer</EditLabel>
                  <EditInput
                    type="text"
                    value={job.employer || ''}
                    onChange={(e) =>
                      handleChange(index, 'work', 'employer', e.target.value)
                    }
                    placeholder="Employer"
                  />
                </GridItem>
                <GridItem>
                  <EditLabel>Start Date</EditLabel>
                  <EditInput
                    type="date"
                    value={job.start_date || ''}
                    onChange={(e) =>
                      handleChange(index, 'work', 'start_date', e.target.value)
                    }
                  />
                </GridItem>
                <GridItem>
                  <EditLabel>End Date</EditLabel>
                  {!job.currentJob ? (
                    <EditInput
                      type="date"
                      value={job.end_date || ''}
                      onChange={(e) =>
                        handleChange(index, 'work', 'end_date', e.target.value)
                      }
                    />
                  ) : (
                    <p>Present</p>
                  )}
                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={job.currentJob || false}
                      onChange={() => handlePresentToggle(index)}
                    />
                    Present
                  </CheckboxLabel>
                </GridItem>
                <GridItemFullWidth>
                  <EditLabel>Job Details</EditLabel>
                  <EditTextArea
                    value={job.details || ''}
                    onChange={(e) =>
                      handleChange(index, 'work', 'details', e.target.value)
                    }
                    placeholder="Add Job Details Here"
                  />
                </GridItemFullWidth>
                {/* Red "X" button to remove work history */}
                <DeleteButton onClick={() => removeHistoryEntry(index, 'work')}>
                  <X size={12} /> Remove
                </DeleteButton>
              </div>
            ))}
          </GridContainer>

          {/* Add Work History button */}
          <AddButton onClick={addWorkHistory}>Add Work History</AddButton>

          {/* Render Athletic History */}
          {tempAthleticHistory?.length > 0 && (
            <>
              <h3>Athletic Experience</h3> {/* Title for Athletic Experience */}
              <GridContainer>
                {tempAthleticHistory.map((history, index) => (
                  <div key={index}>
                    <GridItem>
                      <EditLabel>Sport</EditLabel>
                      <EditInput
                        type="text"
                        value={history.sport || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'sport',
                            e.target.value
                          )
                        }
                        placeholder="Sport"
                      />
                    </GridItem>
                    <GridItem>
                      <EditLabel>League</EditLabel>
                      <EditInput
                        type="text"
                        value={history.league || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'league',
                            e.target.value
                          )
                        }
                        placeholder="League"
                      />
                    </GridItem>
                    <GridItem>
                      <EditLabel>Start Date</EditLabel>
                      <EditInput
                        type="date"
                        value={history.start_date || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'start_date',
                            e.target.value
                          )
                        }
                      />
                    </GridItem>
                    <GridItem>
                      <EditLabel>End Date</EditLabel>
                      <EditInput
                        type="date"
                        value={history.end_date || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'end_date',
                            e.target.value
                          )
                        }
                      />
                    </GridItem>
                    <GridItemFullWidth>
                      <EditLabel>Achievements</EditLabel>
                      <EditTextArea
                        value={history.achievements || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'achievements',
                            e.target.value
                          )
                        }
                        placeholder="Add Achievements Here"
                      />
                    </GridItemFullWidth>
                    {/* Red "X" button to remove athletic history */}
                    <DeleteButton
                      onClick={() => removeHistoryEntry(index, 'athletic')}
                    >
                      <X size={12} /> Remove
                    </DeleteButton>
                  </div>
                ))}
              </GridContainer>
            </>
          )}

          {/* Option to add athletic history */}
          {editing && (
            <AddButton onClick={addAthleticHistory}>
              Add Athletic History
            </AddButton>
          )}

          {/* Save and Cancel buttons */}
          <ButtonRow>
            <SaveButton onClick={handleSave}>
              <Save size={16} /> Save
            </SaveButton>
            <CancelButton onClick={handleCancel}>
              <X size={16} /> Cancel
            </CancelButton>
          </ButtonRow>
        </>
      )
    }

    // Normal view when not editing
    return (
      <>
        {tempWorkHistory?.map((job, index) => (
          <WorkHistoryItem key={index}>
            <IconWrapper>
              <Briefcase size={20} />
            </IconWrapper>
            <WorkDetails>
              <TitleEmployer>
                {job.title || 'Title not provided'} |{' '}
                {job.employer || 'Employer not provided'}
              </TitleEmployer>
              <DateRange>
                {formatDate(job.start_date)} - {formatDate(job.end_date)}
              </DateRange>
              <JobDetails>{job.details || 'Add Job Details Here'}</JobDetails>
            </WorkDetails>
          </WorkHistoryItem>
        ))}

        {/* Render athletic history if it exists */}
        {tempAthleticHistory?.length > 0 && (
          <>
            <h3>Athletic Experience</h3> {/* Title for Athletic Experience */}
            {tempAthleticHistory.map((history, index) => (
              <WorkHistoryItem key={index}>
                <IconWrapper>
                  <TrophyIcon size={20} />
                </IconWrapper>
                <WorkDetails>
                  <TitleEmployer>
                    {history.sport || 'Sport not provided'} |{' '}
                    {history.league || 'League not provided'}
                  </TitleEmployer>
                  <DateRange>
                    {formatDate(history.start_date)} -{' '}
                    {formatDate(history.end_date)}
                  </DateRange>
                  <JobDetails>
                    {history.achievements || 'Add Achievements Here'}
                  </JobDetails>
                </WorkDetails>
              </WorkHistoryItem>
            ))}
          </>
        )}
      </>
    )
  }

  const addEducation = () => {
    const newEducation = {
      degree_type: '',
      field_of_study: '',
      institution: '',
      graduation_date: '' // Add this field
    }
    setProfile((prev) => ({
      ...prev,
      education: [...(prev.education || []), newEducation]
    }))
  }
  const handleEditEducation = () => {
    setEditingEducation((prev) => !prev)
  }
  const removeEducation = (index) => {
    setProfile((prev) => ({
      ...prev,
      education: prev.education.filter((_, i) => i !== index)
    }))
  }
  const handleEducationHistoryChange = (index, field, value) => {
    const updatedEducationHistory = [...tempEducationHistory]

    if (field === 'graduation_date' && value) {
      // Store date in "YYYY-MM" format to avoid timezone issues
      const [year, month] = value.split('-')
      updatedEducationHistory[index][field] = `${year}-${month}`
    } else {
      updatedEducationHistory[index][field] = value
    }

    setTempEducationHistory(updatedEducationHistory)
  }

  const handleSaveEducation = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          education_history: tempEducationHistory
        })
        setProfile((prev) => ({
          ...prev,
          education_history: tempEducationHistory
        }))
        setEditingEducation(false)
      } catch (error) {
        console.error('Error updating education:', error)
      }
    } else {
      console.error('User ID is undefined')
    }
  }

  const handleEditStrengths = () => {
    setEditingStrengths((prev) => !prev)
  }

  const addStrength = (strength) => {
    if (selectedStrengths.length < 3 && !selectedStrengths.includes(strength)) {
      setSelectedStrengths([...selectedStrengths, strength])
    }
  }
  const addCommunityGroup = (group) => {
    if (!tempCommunityGroups.includes(group)) {
      setTempCommunityGroups([...tempCommunityGroups, newGroup])
      setNewGroup('')
    }
  }

  const getLocationFromAddress = (address) => {
    if (!address) return { city: '', state: '', country: '' }
    const parts = address.split(',')
    const city = parts.length > 1 ? parts[1].trim() : ''
    const state = parts.length > 2 ? parts[2].trim().split(' ')[0] : ''
    const country = parts.length > 3 ? parts[3].trim() : ''

    return { city, state, country }
  }

  const removeStrength = (strength) => {
    setSelectedStrengths(selectedStrengths.filter((s) => s !== strength))
  }

  const removeCommunityGroup = (group) => {
    setTempCommunityGroups(tempCommunityGroups.filter((s) => s !== group))
  }
  const handleSaveStrengths = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          top_strengths: selectedStrengths
        })
        setEditingStrengths(false)
      } catch (error) {
        console.error('Error updating strengths:', error)
      }
    } else {
      console.error('User ID is undefined')
    }
  }

  const addPortfolioItem = () => {
    if (portfolioItems.length < 3) {
      setPortfolioItems([
        ...portfolioItems,
        { imageUrl: '', title: '', link: '' }
      ])
    }
  }
  const handleEditSection = (section) => {
    setEditingSections((prev) => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  const handleCheckboxChange = (e, field) => {
    const { value, checked } = e.target

    if (!Array.isArray(profile[field])) {
      setProfile((prev) => ({
        ...prev,
        [field]: []
      }))
    }

    const updatedList = checked
      ? [...(profile[field] || []), value]
      : profile[field].filter((item) => item !== value)

    setProfile((prev) => ({
      ...prev,
      [field]: updatedList
    }))
  }

  const updatePortfolioItem = (index, field, value) => {
    if (field === 'link' && value && !/^https?:\/\//i.test(value)) {
      // If the value does not start with http:// or https://, prepend https://
      value = `https://${value}`
    }

    setPortfolioItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [field]: value
      }
      return updatedItems
    })
  }
  const handleProfileDetailChange = (e, field) => {
    const { value } = e.target

    if (field === 'location') {
      // Assuming location is entered as "City, State, Country"
      const [city, state, country] = value.split(',').map((s) => s.trim())
      setProfile((prev) => ({
        ...prev,
        city: city || '', // Set city and state separately
        state: state || '',
        country: country || ''
      }))
    } else {
      setProfile((prev) => ({
        ...prev,
        [field]: value // General case for fields like email
      }))
    }
  }

  const handlePortfolioImageUpload = async (e, index) => {
    const file = e.target.files[0]
    if (file) {
      const storageRef = ref(
        storage,
        `portfolio-photos/${user.id}/${file.name}`
      )

      try {
        // Upload the file
        await uploadBytes(storageRef, file)

        // Get the download URL
        const newImageUrl = await getDownloadURL(storageRef)

        // Update the portfolio item with the new image URL
        updatePortfolioItem(index, 'imageUrl', newImageUrl)
      } catch (error) {
        console.error('Error uploading portfolio image:', error)
      }
    }
  }

  const removePortfolioItem = (index) => {
    const newItems = [...portfolioItems]
    newItems.splice(index, 1)
    setPortfolioItems(newItems)
  }
  useEffect(() => {
    if (profile && Array.isArray(profile.portfolio_profile_links)) {
      setPortfolioItems(profile.portfolio_profile_links)
    } else {
      setPortfolioItems([]) // Ensure it's always an array
    }
  }, [profile])

  const handleSavePortfolio = async () => {
    try {
      await updateJobseekerProfile(user.id, {
        portfolio_profile_links: portfolioItems
      })
      setProfile({ ...profile, portfolio_profile_links: portfolioItems })
      setEditingPortfolio(false)
    } catch (error) {
      console.error('Error updating portfolio:', error)
    }
  }

  const handlePortfolioScroll = (direction) => {
    if (portfolioRef.current) {
      const scrollAmount = direction === 'left' ? -220 : 220
      portfolioRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    }
  }

  const handleEditVideo = () => {
    setEditingVideo(true)
    setTempVideoUrl(profile?.videos?.[0]?.url || '')
    setTempVideoTitle(profile?.videos?.[0]?.title || '')
    setTempVideoFile(null) // Reset any previously selected video
  }
  const handleCancelVideoEdit = () => {
    setEditingVideo(false)
    setTempVideoUrl(profile?.videos?.[0]?.url || '')
    setTempVideoTitle(profile?.videos?.[0]?.title || '')
    setTempVideoFile(null) // Clear selected video
  }
  const handleSaveVideo = async () => {
    if (!tempVideoFile) {
      console.error('No video file selected')
      return
    }

    if (tempVideoFile.size > 10 * 1024 * 1024) {
      // Limit the file size to 10MB
      alert('Video must be less than 10MB.')
      return
    }

    const videoDuration = await getVideoDuration(tempVideoFile)
    if (videoDuration > 60) {
      alert('Video must be less than 1 minute.')
      return
    }

    const storageRef = ref(
      storage,
      `profile-videos/${user.id}/${tempVideoFile.name}`
    )

    try {
      // Upload the video file
      await uploadBytes(storageRef, tempVideoFile)
      const videoUrl = await getDownloadURL(storageRef)

      // Save video URL and title to Firestore
      const updatedVideos = [{ url: videoUrl, title: tempVideoTitle }]
      await updateJobseekerProfile(user.id, { videos: updatedVideos })

      // Update local state
      setProfile({ ...profile, videos: updatedVideos })
      setEditingVideo(false)
      alert('Video uploaded and saved successfully!')
    } catch (error) {
      console.error('Error uploading video:', error)
    }
  }

  // Function to get the duration of the video
  const getVideoDuration = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src)
        resolve(video.duration)
      }

      video.src = URL.createObjectURL(file)
    })
  }
  const handleCancelVideo = () => {
    setEditingVideo(false)
    setTempVideoUrl(profile?.videos?.[0]?.url || '')
    setTempVideoTitle(profile?.videos?.[0]?.title || '')
    setTempVideoFile(null)
  }
  const handleProfileChange = (field, value) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      [field]: value
    }))
  }
  const handleEditAdditionalDetails = () => {
    setEditingAdditionalDetails(true)
  }

  const handleSaveLinks = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          linkedin: profile?.linkedin,
          gondola: profile?.gondola,
          github: profile?.github,
          personal_website: profile?.personal_website,
          additional_website_2: profile?.additional_website_2
        })
        setEditingSections((prev) => ({ ...prev, links: false })) // Exit editing mode
      } catch (error) {
        console.error('Error saving links:', error)
      }
    }
  }

  const handleSaveAdditionalDetails = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, profile)
        setEditingAdditionalDetails(false) // Exit edit mode after saving
      } catch (error) {
        console.error('Error saving additional details:', error)
      }
    }
  }

  const handleSaveCommunityGroups = async () => {
    if (user && user.id) {
      try {
        //update user community groups data
        setEditingCommunityGroups(false)
      } catch (error) {
        console.error('Error saving community groups:', error)
      }
    }
  }
  const handleCancelAdditionalDetails = () => {
    setEditingAdditionalDetails(false)
  }

  const handleToggle = () => {
    setShowResume(!showResume)
  }

  const handleResumeUpload = async (e) => {
    const file = e.target.files[0]
    if (!file || file.type !== 'application/pdf') {
      alert('Please upload a PDF file.')
      return
    }

    try {
      setUploading(true)
      const storageRef = ref(storage, `resumes/${user.id}/${file.name}`)
      await uploadBytes(storageRef, file)
      const newResumeUrl = await getDownloadURL(storageRef)

      // Update profile in database
      await updateJobseekerProfile(user.id, { resume_url: newResumeUrl })

      // Update local state
      setResumeUrl(newResumeUrl)
      setProfile((prev) => ({ ...prev, resume_url: newResumeUrl }))

      alert('Resume uploaded successfully!')
    } catch (error) {
      console.error('Error uploading resume:', error)
      alert('Error uploading resume. Please try again.')
    } finally {
      setUploading(false)
    }
  }

  const handleSaveResume = async () => {
    if (!tempResumeFile) return

    try {
      await updateJobseekerProfile('userId', { resume_url: resumeUrl })
      setEditingResume(false)
      alert('Resume uploaded and saved successfully!')
    } catch (error) {
      console.error('Error saving resume:', error)
    }
  }

  return (
    <PageWrapper>
      <NavbarWrapper>
        <JobSeekerNav />
      </NavbarWrapper>
      <PageContainer>
        {profile?.plan === 'paid' && (
          <ProBanner>
            <ProText>Arena Pro✨</ProText>
          </ProBanner>
        )}
        <ContentContainer>
          <ToggleContainer>
            <ToggleLabel htmlFor="resume-toggle">
              {showResume ? 'Back to Profile' : 'View and Update Resume'}
            </ToggleLabel>
            <ToggleSwitch checked={showResume} onClick={handleToggle}>
              <ToggleSlider checked={showResume} />
            </ToggleSwitch>
          </ToggleContainer>

          {showResume ? (
            <ResumePageContainer>
              <ResumeViewer>
                {resumeUrl ? (
                  <ResumeFrame
                    src={`${resumeUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                    title="Resume Viewer"
                  />
                ) : (
                  <NoResumeMessage>
                    <Upload size={48} color="#6b7280" />
                    <p>No resume uploaded yet</p>
                  </NoResumeMessage>
                )}
              </ResumeViewer>

              <FileInput
                type="file"
                ref={fileInputRef}
                accept="application/pdf"
                onChange={handleResumeUpload}
              />

              <UploadButton
                onClick={() => fileInputRef.current?.click()}
                title="Upload new resume"
              >
                <Upload size={20} />
                {resumeUrl ? 'Update Resume' : 'Upload Resume'}
              </UploadButton>

              {uploading && (
                <UploadingOverlay>Uploading resume...</UploadingOverlay>
              )}
            </ResumePageContainer>
          ) : (
            <>
              <CoverPhoto src="/images/cover-photo.png.jpg">
                {profile?.plan === 'paid' && (
                  <ProBadgeCorner src="images/arena-pro.png" alt="Pro Badge" />
                )}
              </CoverPhoto>{' '}
              <ProfileContent>
                <MainContent>
                  <ProfileCard>
                    <ProfileHeader>
                      {renderProfilePictureSection()}
                      <ProfileInfo>
                        <ProfileName>
                          {user?.first_name} {user?.last_name}{' '}
                          {profile?.pronouns && (
                            <small
                              style={{ fontSize: '0.85rem', color: '#6B7280' }}
                            >
                              ({profile?.pronouns})
                            </small>
                          )}
                        </ProfileName>
                        <ProfileTitle>
                          {hasWorkHistory
                            ? `${firstJob?.title || 'Title not provided'} at ${
                                firstJob?.employer || 'Employer not provided'
                              }`
                            : 'Student'}
                        </ProfileTitle>
                      </ProfileInfo>
                    </ProfileHeader>

                    <SectionTitle>About Me</SectionTitle>
                    {renderAboutMeSection()}
                  </ProfileCard>
                  {renderExperienceSection()}

                  <ProfileCard>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <SectionTitle>Education</SectionTitle>
                      {!editingEducation && (
                        <EditIconButton onClick={handleEditEducation}>
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </div>
                    {editingEducation ? (
                      <>
                        <GridContainer>
                          {(profile?.education_history || []).map(
                            (edu, index) => (
                              <div key={index}>
                                <WorkHistoryItem>
                                  <IconWrapper>
                                    <GraduationCap size={20} />
                                  </IconWrapper>
                                  <div style={{ width: '100%' }}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '1rem'
                                      }}
                                    >
                                      <h4>Education {index + 1}</h4>
                                      <DeleteButton
                                        onClick={() => removeEducation(index)}
                                      >
                                        <X size={16} />
                                      </DeleteButton>
                                    </div>

                                    <GridItem>
                                      <EditLabel>Degree Type</EditLabel>
                                      <ModernInput
                                        name="degree_type"
                                        value={edu.degree_type || ''}
                                        onChange={(e) =>
                                          handleEducationHistoryChange(
                                            index,
                                            'degree_type',
                                            e.target.value
                                          )
                                        }
                                        placeholder="e.g., Bachelor's, Master's, PhD"
                                      />
                                    </GridItem>

                                    <GridItem>
                                      <EditLabel>Field of Study</EditLabel>
                                      <ModernInput
                                        name="field_of_study"
                                        value={edu.field_of_study || ''}
                                        onChange={(e) =>
                                          handleEducationHistoryChange(
                                            index,
                                            'field_of_study',
                                            e.target.value
                                          )
                                        }
                                        placeholder="e.g., Computer Science, Business"
                                      />
                                    </GridItem>

                                    <GridItem>
                                      <EditLabel>Institution</EditLabel>
                                      <ModernInput
                                        name="institution"
                                        value={edu.institution || ''}
                                        onChange={(e) =>
                                          handleEducationHistoryChange(
                                            index,
                                            'institution',
                                            e.target.value
                                          )
                                        }
                                        placeholder="e.g., University of Michigan"
                                      />
                                    </GridItem>

                                    <GridItem>
                                      <EditLabel>Graduation Date</EditLabel>
                                      <ModernInput
                                        name="graduation_date"
                                        type="month"
                                        value={edu.graduation_date || ''}
                                        onChange={(e) =>
                                          handleEducationHistoryChange(
                                            index,
                                            'graduation_date',
                                            e.target.value
                                          )
                                        }
                                      />
                                    </GridItem>
                                  </div>
                                </WorkHistoryItem>
                              </div>
                            )
                          )}
                        </GridContainer>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px',
                            marginTop: '10px'
                          }}
                        >
                          <CancelButton onClick={handleEditEducation}>
                            Cancel
                          </CancelButton>
                          <AddButton onClick={addEducation}>
                            Add Education
                          </AddButton>
                          <SaveButton onClick={handleSaveEducation}>
                            Save
                          </SaveButton>
                        </div>
                      </>
                    ) : (
                      <>
                        {(profile?.education_history || []).map(
                          (edu, index) => (
                            <WorkHistoryItem key={index}>
                              <IconWrapper>
                                <GraduationCap size={20} />
                              </IconWrapper>
                              <div>
                                <p>
                                  {edu.degree_type} in {edu.field_of_study} from{' '}
                                  {edu.institution}
                                </p>
                                {edu.graduation_date && (
                                  <DateRange>
                                    {formatDateMonthYear(edu.graduation_date)}
                                  </DateRange>
                                )}
                              </div>
                            </WorkHistoryItem>
                          )
                        )}
                      </>
                    )}
                  </ProfileCard>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Top Strengths</SectionTitle>
                      {!editingStrengths && (
                        <EditIconButton
                          onClick={() => setEditingStrengths(true)}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    {editingStrengths ? (
                      <div>
                        <p style={{ marginBottom: '1rem', color: '#6b7280' }}>
                          Select up to 3 strengths that best represent you:
                        </p>

                        {/* Available strengths */}
                        <div style={{ marginBottom: '1.5rem' }}>
                          <h4
                            style={{ marginBottom: '0.5rem', color: '#4b5563' }}
                          >
                            Available Strengths:
                          </h4>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '0.5rem'
                            }}
                          >
                            {strengthsOptions.map((strength) => (
                              <StrengthBubble
                                key={strength}
                                onClick={() => addStrength(strength)}
                                style={{
                                  cursor:
                                    selectedStrengths.length >= 3 ||
                                    selectedStrengths.includes(strength)
                                      ? 'not-allowed'
                                      : 'pointer',
                                  opacity: selectedStrengths.includes(strength)
                                    ? 0.5
                                    : 1,
                                  backgroundColor: selectedStrengths.includes(
                                    strength
                                  )
                                    ? '#b799d4'
                                    : '#caaae1'
                                }}
                              >
                                {strength}
                              </StrengthBubble>
                            ))}
                          </div>
                        </div>

                        {/* Selected strengths */}
                        <div style={{ marginBottom: '1.5rem' }}>
                          <h4
                            style={{ marginBottom: '0.5rem', color: '#4b5563' }}
                          >
                            Your Selected Strengths ({selectedStrengths.length}
                            /3):
                          </h4>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '0.5rem'
                            }}
                          >
                            {Array.isArray(selectedStrengths) &&
                              selectedStrengths.map((strength) => (
                                <StrengthBubble
                                  key={strength}
                                  style={{
                                    backgroundColor: '#caaae1',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  {strength}
                                  <DeleteButton
                                    onClick={() => removeStrength(strength)}
                                    style={{ marginLeft: '0.5rem' }}
                                  >
                                    <X size={16} />
                                  </DeleteButton>
                                </StrengthBubble>
                              ))}
                            {selectedStrengths.length === 0 && (
                              <p
                                style={{
                                  color: '#6b7280',
                                  fontStyle: 'italic'
                                }}
                              >
                                No strengths selected yet
                              </p>
                            )}
                          </div>
                        </div>

                        <ButtonRow>
                          <CancelButton
                            onClick={() => setEditingStrengths(false)}
                          >
                            <X size={16} />
                            Cancel
                          </CancelButton>
                          <SaveButton
                            onClick={handleSaveStrengths}
                            disabled={selectedStrengths.length === 0}
                            style={{
                              opacity: selectedStrengths.length === 0 ? 0.5 : 1,
                              cursor:
                                selectedStrengths.length === 0
                                  ? 'not-allowed'
                                  : 'pointer'
                            }}
                          >
                            <Save size={16} />
                            Save
                          </SaveButton>
                        </ButtonRow>
                      </div>
                    ) : (
                      <div>
                        {Array.isArray(selectedStrengths) &&
                        selectedStrengths.length > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '0.5rem'
                            }}
                          >
                            {selectedStrengths.map((strength) => (
                              <StrengthBubble key={strength}>
                                {strength}
                              </StrengthBubble>
                            ))}
                          </div>
                        ) : (
                          <p style={{ color: '#6b7280', fontStyle: 'italic' }}>
                            No strengths added yet. Click edit to add your top
                            strengths.
                          </p>
                        )}
                      </div>
                    )}
                  </ProfileCard>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Technical Skills</SectionTitle>
                      {!editingTechnicalSkills && (
                        <EditIconButton
                          onClick={() => setEditingTechnicalSkills(true)}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    {editingTechnicalSkills ? (
                      <div>
                        <p style={{ marginBottom: '1rem', color: '#6b7280' }}>
                          Select your technical skills:
                        </p>

                        <div style={{ marginBottom: '1.5rem' }}>
                          <h4
                            style={{ marginBottom: '0.5rem', color: '#4b5563' }}
                          >
                            Available Technical Skills:
                          </h4>
                          <MultiSelectContainer>
                            <MultiSelectGrid>
                              {technicalSkillsOptions.map((skill) => (
                                <CheckboxLabel key={skill}>
                                  <CheckboxInput
                                    type="checkbox"
                                    checked={
                                      profile?.technical_skills?.includes(
                                        skill
                                      ) || false
                                    }
                                    onChange={(e) => {
                                      let newSkills
                                      if (e.target.checked) {
                                        newSkills = [
                                          ...(profile?.technical_skills || []),
                                          skill
                                        ]
                                        // Initialize other_technical_skills array when Other is checked
                                        if (skill === 'Other') {
                                          handleProfileChange(
                                            'other_technical_skills',
                                            ['']
                                          )
                                        }
                                      } else {
                                        newSkills =
                                          profile?.technical_skills?.filter(
                                            (s) => s !== skill
                                          )
                                        // Only clear other_technical_skills when unchecking "Other"
                                        if (skill === 'Other') {
                                          handleProfileChange(
                                            'other_technical_skills',
                                            []
                                          )
                                        }
                                      }
                                      handleProfileChange(
                                        'technical_skills',
                                        newSkills
                                      )
                                    }}
                                  />
                                  {skill === 'Other' ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        flex: 1
                                      }}
                                    >
                                      {skill}
                                      {profile?.technical_skills?.includes(
                                        'Other'
                                      ) && (
                                        <div style={{ paddingLeft: '1.5rem' }}>
                                          {(
                                            profile?.other_technical_skills || [
                                              ''
                                            ]
                                          ).map((customSkill, index) => (
                                            <div
                                              key={index}
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                                marginBottom: '0.5rem'
                                              }}
                                            >
                                              <ModernInput
                                                type="text"
                                                value={customSkill}
                                                onChange={(e) => {
                                                  const updatedSkills = [
                                                    ...(profile?.other_technical_skills ||
                                                      [])
                                                  ]
                                                  updatedSkills[index] =
                                                    e.target.value
                                                  handleProfileChange(
                                                    'other_technical_skills',
                                                    updatedSkills
                                                  )
                                                }}
                                                placeholder="Enter custom skill"
                                                style={{
                                                  width: '200px',
                                                  height: '32px',
                                                  padding: '0.25rem 0.5rem'
                                                }}
                                              />
                                              <AddButton
                                                onClick={() => {
                                                  const currentSkills =
                                                    profile?.other_technical_skills || [
                                                      ''
                                                    ]
                                                  const updatedSkills = [
                                                    ...currentSkills,
                                                    ''
                                                  ]
                                                  handleProfileChange(
                                                    'other_technical_skills',
                                                    updatedSkills
                                                  )
                                                }}
                                                style={{
                                                  padding: '0.25rem 0.5rem'
                                                }}
                                              >
                                                +
                                              </AddButton>
                                              {(profile?.other_technical_skills
                                                ?.length || 0) > 1 && (
                                                <DeleteButton
                                                  onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    const newSkills =
                                                      profile?.other_technical_skills?.filter(
                                                        (_, i) => i !== index
                                                      ) || []

                                                    // Update the other_technical_skills array
                                                    handleProfileChange(
                                                      'other_technical_skills',
                                                      newSkills
                                                    )

                                                    // If we've deleted all other skills, also remove 'Other' from technical_skills
                                                    if (
                                                      newSkills.length === 0
                                                    ) {
                                                      const updatedTechnicalSkills =
                                                        profile?.technical_skills?.filter(
                                                          (skill) =>
                                                            skill !== 'Other'
                                                        ) || []
                                                      handleProfileChange(
                                                        'technical_skills',
                                                        updatedTechnicalSkills
                                                      )
                                                    }
                                                  }}
                                                  style={{ padding: '0.25rem' }}
                                                >
                                                  <X size={12} />
                                                </DeleteButton>
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    skill
                                  )}
                                </CheckboxLabel>
                              ))}
                            </MultiSelectGrid>
                          </MultiSelectContainer>
                        </div>

                        <ButtonRow>
                          <CancelButton
                            onClick={() => setEditingTechnicalSkills(false)}
                          >
                            <X size={16} />
                            Cancel
                          </CancelButton>
                          <SaveButton onClick={handleSaveTechnicalSkills}>
                            <Save size={16} />
                            Save
                          </SaveButton>
                        </ButtonRow>
                      </div>
                    ) : (
                      <div>
                        {profile?.technical_skills?.length > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '0.5rem'
                            }}
                          >
                            {profile.technical_skills.map((skill) => {
                              if (skill === 'Other') {
                                // Map each "Other" skill to its own bubble
                                return profile?.other_technical_skills
                                  ?.filter((s) => s.trim() !== '') // Filter out empty strings
                                  .map((otherSkill, index) => (
                                    <SmallPill key={`other-${index}`}>
                                      {otherSkill}
                                    </SmallPill>
                                  ))
                              }
                              // Regular skills get their own bubble
                              return <SmallPill key={skill}>{skill}</SmallPill>
                            })}
                          </div>
                        ) : (
                          <p style={{ color: '#6b7280', fontStyle: 'italic' }}>
                            No technical skills added yet. Click edit to add
                            your technical skills.
                          </p>
                        )}
                      </div>
                    )}
                  </ProfileCard>
                  {/* <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Community Groups</SectionTitle>
                      {!editingCommunityGroups && (
                        <EditIconButton
                          onClick={() => setEditingCommunityGroups(true)}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    {editingCommunityGroups ? (
                      <div>
                        <EditLabel>Manage community groups.</EditLabel>
                        <div style={{display: 'flex', gap: '0.5rem'}}>

                          <ModernInput
                            style={{marginTop: '0.5rem',}}
                            placeholder="Community group name"
                            value={newGroup}
                            onChange={(e) => setNewGroup(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                addCommunityGroup()
                              }
                            }}
                          />
                          <SaveButton
                            style={{ margin: '0.5rem', whiteSpace: 'nowrap' }}
                            onClick={addCommunityGroup}
                          >
                            Add Group
                          </SaveButton>
                        </div>

                        {Array.isArray(tempCommunityGroups) &&
                        tempCommunityGroups.length > 0 ? (
                          <div>
                            <EditLabel>Selected groups:</EditLabel>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '0.5rem'
                              }}
                            >
                              {tempCommunityGroups.map((group) => (

                                <StrengthBubble key={group}>
                                  {group}
                                  <DeleteButton onClick={() => removeCommunityGroup(group)}><X size={14}/></DeleteButton>
                                </StrengthBubble>

                              ))}
                            </div>
                          </div>

                        ) : (
                          <p style={{ color: '#6b7280', fontStyle: 'italic' }}>
                            No community groups added yet.
                          </p>
                        )}

                        <ButtonRow style={{marginTop: '0.5rem'}}>
                          <CancelButton
                            onClick={() => setEditingCommunityGroups(false)}
                          >
                            <X size={16} />
                            Cancel
                          </CancelButton>
                          <SaveButton
                            onClick={handleSaveCommunityGroups}
                          >
                            <Save size={16} />
                            Save
                          </SaveButton>
                        </ButtonRow>
                      </div>
                    ) : (
                      <div>
                        {Array.isArray(tempCommunityGroups) &&
                        tempCommunityGroups.length > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '0.5rem'
                            }}
                          >
                            {tempCommunityGroups.map((group) => (
                              <StrengthBubble key={group}>
                                {group}
                              </StrengthBubble>
                            ))}
                          </div>
                        ) : (
                          <p style={{ color: '#6b7280', fontStyle: 'italic' }}>
                            No community groups added yet. Click edit to add community groups.
                          </p>
                        )}
                      </div>
                    )}
                  </ProfileCard> */}
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Portfolio</SectionTitle>
                      {!editingPortfolio && (
                        <EditIconButton
                          onClick={() => setEditingPortfolio(true)}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    {editingPortfolio ? (
                      <>
                        <PortfolioContainer>
                          <PortfolioScroller>
                            {portfolioItems.map((item, index) => (
                              <PortfolioItem key={index}>
                                <PortfolioImageContainer>
                                  {item.imageUrl ? (
                                    <PortfolioImage src={item.imageUrl} />
                                  ) : (
                                    <FileInputLabel
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: '#f9fafb'
                                      }}
                                    >
                                      <Upload size={24} />
                                      <span style={{ marginTop: '0.5rem' }}>
                                        Upload Image
                                      </span>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                          handlePortfolioImageUpload(e, index)
                                        }
                                        style={{ display: 'none' }}
                                      />
                                    </FileInputLabel>
                                  )}
                                </PortfolioImageContainer>

                                <ModernInput
                                  value={item.title || ''}
                                  onChange={(e) =>
                                    updatePortfolioItem(
                                      index,
                                      'title',
                                      e.target.value
                                    )
                                  }
                                  placeholder="Project Title"
                                  style={{ marginBottom: '0.5rem' }}
                                />

                                <ModernInput
                                  value={item.link || ''}
                                  onChange={(e) =>
                                    updatePortfolioItem(
                                      index,
                                      'link',
                                      e.target.value
                                    )
                                  }
                                  placeholder="Project URL"
                                />

                                <DeleteButton
                                  onClick={() => removePortfolioItem(index)}
                                  style={{ marginTop: '0.5rem' }}
                                >
                                  <X size={16} /> Remove
                                </DeleteButton>
                              </PortfolioItem>
                            ))}

                            {portfolioItems.length < 3 && (
                              <PortfolioItem
                                as="button"
                                onClick={addPortfolioItem}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '300px',
                                  border: '2px dashed #caaae1',
                                  background: 'transparent',
                                  cursor: 'pointer'
                                }}
                              >
                                <Upload
                                  size={24}
                                  style={{ marginBottom: '0.5rem' }}
                                />
                                Add Portfolio Item
                              </PortfolioItem>
                            )}
                          </PortfolioScroller>
                        </PortfolioContainer>

                        <ButtonRow>
                          <SaveButton onClick={handleSavePortfolio}>
                            <Save size={16} /> Save
                          </SaveButton>
                          <CancelButton
                            onClick={() => setEditingPortfolio(false)}
                          >
                            <X size={16} /> Cancel
                          </CancelButton>
                        </ButtonRow>
                      </>
                    ) : (
                      <PortfolioContainer>
                        <PortfolioScroller>
                          {portfolioItems.map((item, index) => (
                            <PortfolioItem key={index}>
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                              >
                                <PortfolioImageContainer>
                                  <PortfolioImage src={item.imageUrl} />
                                </PortfolioImageContainer>
                                <PortfolioTitle>{item.title}</PortfolioTitle>
                              </a>
                            </PortfolioItem>
                          ))}
                          {portfolioItems.length === 0 && (
                            <div
                              style={{
                                padding: '2rem',
                                textAlign: 'center',
                                color: '#6b7280'
                              }}
                            >
                              No portfolio items added yet.
                            </div>
                          )}
                        </PortfolioScroller>
                      </PortfolioContainer>
                    )}
                  </ProfileCard>
                </MainContent>
                <Sidebar>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Additional Details</SectionTitle>
                      {!editingAdditionalDetails && (
                        <EditIconButton
                          onClick={() => setEditingAdditionalDetails(true)}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    <DetailParagraph>
                      <IconContainer>
                        <Mail size={18} />
                      </IconContainer>
                      {user?.email} {/* Email is no longer editable */}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <MapPinIcon size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Location</EditLabel>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <ModernInput
                              style={{ width: '90%' }}
                              name="city"
                              value={profile?.city || ''}
                              onChange={(e) =>
                                handleProfileDetailChange(e, 'city')
                              } // Pass 'city' as field
                              placeholder="City"
                            />
                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                              <ModernInput
                                name="state"
                                value={profile?.state || ''}
                                onChange={(e) =>
                                  handleProfileDetailChange(e, 'state')
                                } // Pass 'state' as field
                                placeholder="State"
                              />
                              <ModernInput
                                name="country"
                                value={profile?.country || ''}
                                onChange={(e) =>
                                  handleProfileDetailChange(e, 'country')
                                } // Pass 'country' as field
                                placeholder="Country"
                              />
                            </div>
                          </div>
                        </>
                      ) : profile?.city &&
                        profile?.state &&
                        profile?.country ? (
                        `${profile?.city}, ${profile?.state}, ${profile?.country}`
                      ) : (
                        'Location not provided'
                      )}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <Globe size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Languages</EditLabel>
                          <CheckboxContainer>
                            {languageOptions.map((lang) => (
                              <CheckboxLabel key={lang}>
                                <CheckboxInput
                                  type="checkbox"
                                  value={lang}
                                  checked={
                                    profile?.languages?.includes(lang) || false
                                  } // Fallback to false
                                  onChange={(e) =>
                                    handleCheckboxChange(e, 'languages')
                                  }
                                />
                                {lang}
                              </CheckboxLabel>
                            ))}
                          </CheckboxContainer>
                        </>
                      ) : (
                        `Languages: ${
                          profile?.languages?.join(', ') || 'Not specified'
                        }` // Fallback if undefined
                      )}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <Clock size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Years of Experience</EditLabel>
                          <ModernSelect
                            name="yearsExperience"
                            value={profile?.years_experience || ''} // Fallback to empty string
                            onChange={(e) =>
                              handleProfileDetailChange(e, 'years_experience')
                            }
                          >
                            <option value="">Select experience</option>
                            {experienceLevels.map((exp) => (
                              <option key={exp} value={exp}>
                                {exp} experience
                              </option>
                            ))}
                          </ModernSelect>
                        </>
                      ) : (
                        `${
                          profile?.years_experience || 'Not specified'
                        } experience`
                      )}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <Landmark size={18} />
                      </IconContainer>

                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Work Preference</EditLabel>
                          <ModernSelect
                            name="workPreference"
                            value={profile?.work_preference || ''} // Fallback to empty string
                            onChange={(e) =>
                              handleProfileDetailChange(e, 'work_preference')
                            }
                          >
                            <option value="">Select Preference</option>
                            {workPreferences.map((pref) => (
                              <option key={pref} value={pref}>
                                {pref}
                              </option>
                            ))}
                          </ModernSelect>
                        </>
                      ) : (
                        `Prefers: ${
                          profile?.work_preference || 'Not specified'
                        }`
                      )}
                    </DetailParagraph>
                    {/* <DetailParagraph>
                      <IconContainer>
                        <Landmark size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Work Preferences</EditLabel>
                          <CheckboxContainer>
                            {workPreferences.map((option) => (
                              <CheckboxLabel key={option}>
                                <CheckboxInput
                                  type="checkbox"
                                  value={option}
                                  checked={
                                    Array.isArray(
                                      profile?.preferred_telework_policy
                                    ) &&
                                    profile.preferred_telework_policy.includes(
                                      option
                                    )
                                  } // Ensure it's an array before calling .includes()
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      'preferred_telework_policy'
                                    )
                                  }
                                />
                                {option}
                              </CheckboxLabel>
                            ))}
                          </CheckboxContainer>
                        </>
                      ) : profile?.preferred_telework_policy &&
                        Array.isArray(profile.preferred_telework_policy) ? (
                        `Prefers ${profile.preferred_telework_policy.join(
                          ', '
                        )}`
                      ) : (
                        'Not specified'
                      )}
                    </DetailParagraph> */}

                    <DetailParagraph>
                      <IconContainer>
                        <LineChart size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Startup Experience</EditLabel>
                          <ModernSelect
                            name="startupExperience"
                            value={
                              profile?.startup_experience?.toString() || ''
                            } // Fallback to empty string
                            onChange={(e) =>
                              setProfile((prev) => ({
                                ...prev,
                                startup_experience: e.target.value === 'true'
                              }))
                            }
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </ModernSelect>
                        </>
                      ) : (
                        `Startup Experience: ${
                          profile?.startup_experience ? 'Yes' : 'No'
                        }`
                      )}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <Users size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Job Level</EditLabel>
                          <ModernSelect
                            name="current_level"
                            value={profile?.current_level || ''}
                            onChange={(e) =>
                              handleProfileDetailChange(e, 'current_level')
                            }
                          >
                            <option value="">Select job level</option>
                            {currentLevels.map((level) => (
                              <option key={level} value={level}>
                                {level}
                              </option>
                            ))}
                          </ModernSelect>
                        </>
                      ) : (
                        `Job Level: ${
                          profile?.current_level || 'Not specified'
                        }`
                      )}
                    </DetailParagraph>

                    {/* Save and Cancel Buttons */}
                    {editingAdditionalDetails && (
                      <ButtonRow>
                        <SaveButton onClick={handleSaveAdditionalDetails}>
                          <Save size={16} /> Save
                        </SaveButton>
                        <CancelButton onClick={handleCancelAdditionalDetails}>
                          <X size={16} /> Cancel
                        </CancelButton>
                      </ButtonRow>
                    )}
                  </ProfileCard>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Links</SectionTitle>
                      {!editingSections.links && (
                        <EditIconButton
                          onClick={() => handleEditSection('links')}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    {editingSections.links ? (
                      <>
                        <DetailParagraph>
                          <EditLabel>LinkedIn</EditLabel>
                          <ModernInput
                            name="linkedin"
                            value={profile?.linkedin || ''}
                            onChange={(e) =>
                              handleProfileChange('linkedin', e.target.value)
                            }
                            placeholder="LinkedIn URL"
                          />
                        </DetailParagraph>
                        <DetailParagraph>
                          <EditLabel>Gondola</EditLabel>
                          <ModernInput
                            name="gondola"
                            value={profile?.gondola || ''}
                            onChange={(e) =>
                              handleProfileChange('gondola', e.target.value)
                            }
                            placeholder="Gondola URL"
                          />
                        </DetailParagraph>
                        <DetailParagraph>
                          <EditLabel>GitHub</EditLabel>
                          <ModernInput
                            name="github"
                            value={profile?.github || ''}
                            onChange={(e) =>
                              handleProfileChange('github', e.target.value)
                            }
                            placeholder="GitHub URL"
                          />
                        </DetailParagraph>

                        <DetailParagraph>
                          <EditLabel>Personal Website</EditLabel>
                          <ModernInput
                            name="personal_website"
                            value={profile?.personal_website || ''}
                            onChange={(e) =>
                              handleProfileChange(
                                'personal_website',
                                e.target.value
                              )
                            }
                            placeholder="Personal Website URL"
                          />
                        </DetailParagraph>

                        <DetailParagraph>
                          <EditLabel>Portfolio</EditLabel>{' '}
                          {/* New Portfolio link */}
                          <ModernInput
                            name="portfolio"
                            value={profile?.portfolio || ''}
                            onChange={(e) =>
                              handleProfileChange('portfolio', e.target.value)
                            }
                            placeholder="Portfolio URL"
                          />
                        </DetailParagraph>

                        <DetailParagraph>
                          <EditLabel>Additional Website</EditLabel>
                          <ModernInput
                            name="additional_website_2"
                            value={profile?.additional_website_2 || ''}
                            onChange={(e) =>
                              handleProfileChange(
                                'additional_website_2',
                                e.target.value
                              )
                            }
                            placeholder="Additional Website URL"
                          />
                        </DetailParagraph>

                        <ButtonRow>
                          <SaveButton onClick={handleSaveLinks}>
                            <Save size={16} /> Save
                          </SaveButton>
                          <CancelButton
                            onClick={() => handleEditSection('links')}
                          >
                            <X size={16} /> Cancel
                          </CancelButton>
                        </ButtonRow>
                      </>
                    ) : (
                      <>
                        {profile?.linkedin && (
                          <DetailParagraph>
                            <IconContainer>
                              <Linkedin size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.linkedin.startsWith('http')
                                  ? profile.linkedin
                                  : `https://${profile.linkedin}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              LinkedIn
                            </Link>
                          </DetailParagraph>
                        )}
                        {profile?.gondola && (
                          <DetailParagraph>
                            <IconContainer>
                              <Globe size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.gondola.startsWith('http')
                                  ? profile.gondola
                                  : `https://${profile.gondola}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Gondola
                            </Link>
                          </DetailParagraph>
                        )}
                        {profile?.github && (
                          <DetailParagraph>
                            <IconContainer>
                              <Github size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.github.startsWith('http')
                                  ? profile.github
                                  : `https://${profile.github}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              GitHub
                            </Link>
                          </DetailParagraph>
                        )}

                        {profile?.personal_website && (
                          <DetailParagraph>
                            <IconContainer>
                              <Globe size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.personal_website.startsWith('http')
                                  ? profile.personal_website
                                  : `https://${profile.personal_website}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Personal Website
                            </Link>
                          </DetailParagraph>
                        )}

                        {profile?.portfolio && (
                          <DetailParagraph>
                            <IconContainer>
                              <FileText size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.portfolio.startsWith('http')
                                  ? profile.portfolio
                                  : `https://${profile.portfolio}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Portfolio
                            </Link>
                          </DetailParagraph>
                        )}

                        {profile?.additional_website_2 && (
                          <DetailParagraph>
                            <IconContainer>
                              <Globe size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.additional_website_2.startsWith('http')
                                  ? profile.additional_website_2
                                  : `https://${profile.additional_website_2}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Additional Website
                            </Link>
                          </DetailParagraph>
                        )}
                      </>
                    )}
                  </ProfileCard>
                </Sidebar>
              </ProfileContent>
            </>
          )}
        </ContentContainer>
      </PageContainer>
    </PageWrapper>
  )
}
