import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import JobSeekerNav from './JobSeekerNav'
import { auth } from '../../firebase'
import { doc, onSnapshot, getDoc } from 'firebase/firestore'
import { getResources } from '../../models/Resource'
import {
  addSavedResource,
  removeSavedResource,
  getJobseekerProfile
} from '../../models/JobSeekerProfile'
import { db } from '../../firebase'
import styled from 'styled-components'
import {
  Search,
  Info,
  ChevronDown,
  Grid2X2,
  Bookmark,
  Check,
  ArrowRight,
  ArrowLeft,
  Ellipsis
} from 'lucide-react'

const ITEMS_PER_PAGE = 12

const allSubcategories = [
  'Esports/Gaming',
  'Events',
  'Fashion',
  'Film/TV',
  'Media',
  'Music',
  'Sports',
  'Technology'
].sort()

const topicOptions = [
  'AI',
  'Business Development',
  'Communications/Public Relations',
  'Coaching',
  'Content/Digital',
  'Creative',
  'Customer Support',
  'Data Analytics',
  'Diversity, Equity, Inclusion & Belonging',
  'Engineering',
  'Finance/Accounting',
  'Gaming',
  'Human Resources',
  'Journalism',
  'Legal',
  'Marketing',
  'Medical/Training',
  'Officiating',
  'Operations',
  'Partnerships/Sponsorships',
  'Philanthropy',
  'Production/Studios',
  'Professional Development',
  'Sales',
  'Scouting',
  'Strategy',
  'Job Searching',
  'Talent Management',
  'Tech & Engineering',
  'Venue Operations'
]
const levelOptions = ['Student', 'Early Career', 'Mid Career', 'Executive']
const locationOptions = ['Virtual']
const targetAudienceOptions = [
  'Asian Professionals',
  'Athletes',
  'Black Professionals',
  'Disabled Professionals',
  'Hispanic/Latino Professionals',
  'Indigenous Professionals',
  'LGBTQIA+',
  'Veterans',
  'Women',
  'All'
]
const deliveryFormatOptions = ['Virtual', 'In-Person', 'Hybrid']

//Test data
const numPages = 3

const resourceTypes = [
  'Books',
  'Courses',
  'Coaching',
  'Certifications',
  'Events',
  'Media',
  'Newsletters',
  'Podcasts',
  'Schools'
]
const testCards = [
  {
    title: 'Title 1: Example Long Title',
    subtitle: 'Subtitle 1',
    startDate: 'Nov 30th',
    endDate: 'Nov 31st',
    description:
      'A networking session for industry leaders and aspiring professionals to connect and share insights.',
    resourceType: resourceTypes[0],
    saved: true
  },
  {
    title: 'Title 2 (No End Date)',
    subtitle: 'Subtitle 2',
    startDate: 'Nov 30th',
    description:
      'Hands-on workshop to learn the basics of programming and build your first app.',
    resourceType: resourceTypes[1],
    saved: false
  },
  {
    title: 'Title 3 (No Date)',
    subtitle: 'Subtitle 3',
    description:
      'Panel discussion featuring experts on diversity and inclusion in the workplace.',
    resourceType: resourceTypes[2],
    saved: true
  },
  {
    title: 'Title 4',
    subtitle: 'Subtitle 4',
    startDate: 'Nov 30th',
    endDate: 'Nov 31st',
    description:
      'Exclusive seminar on leveraging AI to streamline business operations and improve decision-making.',
    resourceType: resourceTypes[3]
  }
]

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f8f9fa;
  overflow-y: auto;
`

const HeaderContainer = styled.div`
  flex: 1;
  position: relative;
  padding: 1rem 3rem;
  z-index: 99;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`
const PageTitle = styled.h1`
  font-size: 2rem;
  font-family: 'Open Sans', sans-serif;
  color: #252641;
  font-weight: 400;
`
const SearchBar = styled.div`
  display: flex;
  align-items: center;
  margin: 0rem 2rem 1rem 2rem;
  background-color: white;
  padding: 0.5rem;
  padding-left: 6rem; //for moving search icon and search over to the right
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding-left: 0.25rem;
    margin: 0.25rem;
  }
`

const SearchInput = styled.input`
  flex: 1;
  padding: 0.6rem;
  border: none;
  font-size: 1rem;
  color: #83839a;
  margin-right: 8px;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`
const Button = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: ${(props) => props.borderRadius || '0.375rem'};
  padding: ${(props) => props.padding || '0.6rem 2rem'};

  &:hover {
    background-color: #b799d4;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
  }
`
const SmallButton = styled(Button)`
  font-size: 10px;
  border-radius: 20px;
  padding: 6px 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const PageLink = styled(Link)`
  font-weight: 600;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    align-self: flex-end;
    margin-bottom: 0.5rem;
  }
`

const DropDownBar = styled.div`
  display: flex;
  flex: grow;
  position: sticky;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
const DropDownContainer = styled.div`
  flex-direction: column;
  align-items: stretch;
  position: relative;
  display: inline-block;
  cursor: pointer;
`
const DropDownHeader = styled.div`
  display: flex;
  gap: 0.1rem;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #e5e7eb;
  }
  @media (max-width: 768px) {
    font-size: 10pt;
    white-space: nowrap;
  }
`
const DropDownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0%;
  background-color: #f8f9fa;
  padding: 1rem;
  min-width: max-content;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  max-height: ${(props) => (props.isOpen ? '300px' : '0')};
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 100;
`
const Checkbox = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #caaae1;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: #caaae1;
  }

  &:checked::after {
    content: '✓';
    font-size: 12px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.5);
  }
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 4px;
  cursor: pointer;
`
const CardsContainer = styled.div`
  background-color: rgba(202, 170, 225, 0.3);
  background-color: ${(props) => props.backgroundColor || '#f8f9fa'};
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
`

const ResourceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-content: start;
  justify-content: space-between;

  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
`
const ResourceCardContent = styled.div`
  display: grid;
  align-content: start;
`
const ResourceImage = styled.div`
  width: 75%;
  aspect-ratio: 1;
  border-radius: 8px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  position: relative;
  border: 4px solid #f7fafc;
  justify-self: center;
`
const CardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0rem;
  color: #1a202c;
`
const CardSubtitle = styled.h3`
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  color: #1a202c;
`
const CardDate = styled.p`
  margin: 0rem;
  font-size: 0.75rem;
  color: #718096;
`
const CardDescription = styled.p`
  color: #718096;
  font-size: 0.875rem;
`
const CardType = styled.div`
  color: #718096;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
const CardActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`

const ButtonGroup = styled.div`
  display: flex;
  text-align: center;
  align-self: flex-start;
  margin-bottom: 2rem;
  margin-left: -0.5rem;
`

const TabButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: 7pt;
  font-weight: 600;
  padding: 8px 10px;
  width: ${(props) => props.width || '75px'};
  background-color: ${(props) =>
    props.isActive ? 'rgba(202, 170, 225, 0.3)' : '#f8f9fa'};
  border: 1px solid #718096;
  border-left: ${(props) =>
    props.first
      ? '1px solid #718096'
      : 'none'}; /* Only first button gets left border */
  border-radius: ${(props) => props.borderRadius || '20px'};
  color: #718096;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-radius 0.3s ease;
`
const PageNavigation = styled.div`
  margin-top: 2rem;
  display: flex;
  align-self: center;
  gap: 0.5rem;
  align-items: center;
`
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const PageButton = styled.button`
  border: none;
  border-radius: 8px;
  font-weight: 400;

  width: 2rem;
  aspect-ratio: 1;
  background-color: #f8f9fa;
  transition: all 0.2s ease;

  &:hover {
    color: white;
    background-color: #caaae1;
  }
`

const ProBanner = styled.div`
  background-color: #d29856;
  padding: 6px 16px;
  position: fixed;
  margin-top: 10px;
  top: 0;
  right: 0;
  z-index: 1001;
  border-bottom-left-radius: 8px;
  font-family: 'Roboto', sans-serif;
`

const ProText = styled.span`
  color: white;
  font-size: 14px;
`

const ProIcon = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
`

const TooltipText = styled.div`
  position: absolute;
  bottom: 150%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
`

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${TooltipText} {
    opacity: 1;
    visibility: visible;
  }
`

const TooltipContent = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateX(3px) translateY(-50%);
  background-color: rgba(245, 240, 255, 1);
  color: #252641;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  min-width: 200px;

  ${TooltipContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 768px) {
    left: auto;
    right: 100%;
    top: 120%;
    transform: translateX(30px) translateY(-35px);
  }
`

const DropDownSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <DropDownContainer ref={dropdownRef}>
      <DropDownHeader onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        <ChevronDown
          size={14}
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate when open
            transition: 'transform 0.3s' // Smooth transition for rotation
          }}
        />
      </DropDownHeader>
      <DropDownContent isOpen={isOpen}>{children}</DropDownContent>
    </DropDownContainer>
  )
}
const NestedDropDownSection = ({
  title,
  options,
  onChange,
  selectedValues = []
}) => {
  return (
    <DropDownSection title={title}>
      {options.map((option) => (
        <CheckboxLabel key={option}>
          <Checkbox
            type="checkbox"
            checked={selectedValues.includes(option)}
            onChange={(e) => onChange(option, e.target.checked)}
          />
          {option}
        </CheckboxLabel>
      ))}
    </DropDownSection>
  )
}

const CheckboxList = ({ options, onChange, name, selectedValues = [] }) => {
  return (
    <>
      {options.map((option) => (
        <CheckboxLabel key={option}>
          <Checkbox
            type="checkbox"
            name={name}
            value={option}
            checked={selectedValues.includes(option)}
            onChange={(e) => {
              const value = e.target.value
              const isChecked = e.target.checked
              onChange(value, isChecked)
            }}
          />
          {option}
        </CheckboxLabel>
      ))}
    </>
  )
}
const Tooltip = ({ children, content }) => (
  <TooltipContainer>
    {children}
    <TooltipContent>{content}</TooltipContent>
  </TooltipContainer>
)
const TabSwitch = ({ currentTab, setCurrentTab }) => {
  return (
    <ButtonGroup>
      <TabButton
        onClick={() => setCurrentTab('view all')}
        isActive={currentTab === 'view all'}
        width="75px"
        first
        borderRadius="20px 0 0 20px"
      >
        {currentTab === 'view all' && <Check size={10} />}
        View All
      </TabButton>
      <TabButton
        onClick={() => setCurrentTab('saved')}
        isActive={currentTab === 'saved'}
        width="80px"
        borderRadius="0 20px 20px 0"
      >
        {currentTab === 'saved' && <Check size={10} />}
        Saved
      </TabButton>
    </ButtonGroup>
  )
}
function ResourceHub() {
  const [currentTab, setCurrentTab] = useState('view all')
  const [resources, setResources] = useState([])
  const [filteredResources, setFilteredResources] = useState([])
  const [recommendedResources, setRecommendedResources] = useState([])
  const [savedResources, setSavedResources] = useState([])
  const [loading, setLoading] = useState(true)
  const [userPreferences, setUserPreferences] = useState(null)

  // Filter states for all filters
  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [selectedTopics, setSelectedTopics] = useState([])
  const [selectedCareerLevels, setSelectedCareerLevels] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState([])
  const [selectedDeliveryFormats, setSelectedDeliveryFormats] = useState([])
  const formatDate = (dateString) => {
    if (!dateString) return ''
    // Add time and set it to noon to avoid timezone issues
    const date = new Date(dateString + 'T12:00:00')
    return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(
      date.getDate()
    ).padStart(2, '0')}`
  }
  const [currentPage, setCurrentPage] = useState(1) // Pagination state

  // Calculate total pages
  const totalPages = Math.ceil(filteredResources.length / ITEMS_PER_PAGE)

  // Paginated resources
  const paginatedResources = filteredResources.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const goToPage = (page) => {
    setCurrentPage(page)
  }

  const renderPageNumbers = () => {
    const pages = []
    const delta = 2 // Number of pages to show around the current page

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // Always show the first page
        i === totalPages || // Always show the last page
        (i >= currentPage - delta && i <= currentPage + delta) // Pages near the current page
      ) {
        pages.push(i)
      }
    }

    const pageButtons = []
    let previousPage = 0

    for (const page of pages) {
      if (page - previousPage > 1) {
        pageButtons.push(<Ellipsis key={`ellipsis-${page}`} />)
      }

      pageButtons.push(
        <PageButton
          key={page}
          onClick={() => goToPage(page)}
          disabled={page === currentPage}
          isActive={page === currentPage}
        >
          {page}
        </PageButton>
      )

      previousPage = page
    }

    return pageButtons
  }

  const fetchSavedResources = async () => {
    const user = auth.currentUser
    if (user) {
      const profile = await getJobseekerProfile(user.uid)
      if (profile?.saved_resources) {
        setSavedResources(profile.saved_resources)
      }
    }
  }

  const fetchResources = async () => {
    try {
      const fetchedResources = await getResources()
      setResources(fetchedResources)
      setFilteredResources(fetchedResources)
    } catch (error) {
      console.error('Error fetching resources:', error)
    }
  }

  const fetchUserPreferences = async () => {
    const user = auth.currentUser
    if (!user) return

    try {
      const profileRef = doc(db, 'users', user.uid, 'profiles', 'jobseeker')
      const profileSnap = await getDoc(profileRef)

      if (profileSnap.exists()) {
        const profileData = profileSnap.data()
        setUserPreferences({
          careerGoals: profileData.resource_career_goal || [],
          resourceTypes: profileData.resource_types || [],
          inPersonPreference: profileData.resource_in_person || ''
        })
      }
    } catch (error) {
      console.error('Error fetching user preferences:', error)
    }
  }

  const toggleSave = async (resourceId) => {
    const user = auth.currentUser
    if (!user) return

    if (savedResources.includes(resourceId)) {
      await removeSavedResource(user.uid, resourceId)
      setSavedResources((prev) => prev.filter((id) => id !== resourceId))
    } else {
      await addSavedResource(user.uid, resourceId)
      setSavedResources((prev) => [...prev, resourceId])
    }
  }

  const handleIndustryChange = (value, isChecked) => {
    setSelectedIndustries((prev) =>
      isChecked ? [...prev, value] : prev.filter((item) => item !== value)
    )
  }

  const handleTopicsChange = (value, isChecked) => {
    setSelectedTopics((prev) =>
      isChecked ? [...prev, value] : prev.filter((item) => item !== value)
    )
  }

  const handleCareerLevelsChange = (value, isChecked) => {
    setSelectedCareerLevels((prev) =>
      isChecked ? [...prev, value] : prev.filter((item) => item !== value)
    )
  }

  const handleLocationsChange = (value, isChecked) => {
    setSelectedLocations((prev) =>
      isChecked ? [...prev, value] : prev.filter((item) => item !== value)
    )
  }

  const handleTargetAudienceChange = (value, isChecked) => {
    setSelectedTargetAudiences((prev) =>
      isChecked ? [...prev, value] : prev.filter((item) => item !== value)
    )
  }

  const handleDeliveryFormatChange = (value, isChecked) => {
    setSelectedDeliveryFormats((prev) =>
      isChecked ? [...prev, value] : prev.filter((item) => item !== value)
    )
  }

  // Update the filter logic to properly handle arrays and partial matches
  const applyFilters = () => {
    let filtered = [...resources]

    // Helper function to safely check if a resource property includes a value
    const safeIncludes = (resourceProp, value) => {
      if (!resourceProp) return false
      if (Array.isArray(resourceProp)) {
        return resourceProp.some((item) =>
          String(item).toLowerCase().includes(String(value).toLowerCase())
        )
      }
      return String(resourceProp)
        .toLowerCase()
        .includes(String(value).toLowerCase())
    }

    if (selectedIndustries.length > 0) {
      filtered = filtered.filter((resource) =>
        // Check if resource has subcategories array and if any selected industry matches
        resource.subcategories?.some((subcategory) =>
          selectedIndustries.some(
            (selected) => subcategory.toLowerCase() === selected.toLowerCase()
          )
        )
      )
    }

    if (selectedTopics.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedTopics.some((topic) => safeIncludes(resource.topic, topic))
      )
    }

    if (selectedCareerLevels.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedCareerLevels.some((level) =>
          safeIncludes(resource.careerLevel, level)
        )
      )
    }

    if (selectedLocations.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedLocations.some((location) =>
          safeIncludes(resource.location, location)
        )
      )
    }

    if (selectedTargetAudiences.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedTargetAudiences.some((audience) =>
          safeIncludes(resource.targetAudience, audience)
        )
      )
    }

    if (selectedDeliveryFormats.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedDeliveryFormats.some((format) =>
          safeIncludes(resource.deliveryFormat, format)
        )
      )
    }

    setFilteredResources(filtered)
  }

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true)
      await fetchSavedResources()
      await fetchResources()
      await fetchUserPreferences()
      setLoading(false)
    }

    initializeData()
  }, [])

  useEffect(() => {
    if (currentTab === 'saved') {
      setFilteredResources(
        resources.filter((resource) => savedResources.includes(resource.id))
      )
    } else {
      applyFilters()
    }
  }, [
    currentTab,
    selectedIndustries,
    selectedTopics,
    selectedCareerLevels,
    selectedLocations,
    selectedTargetAudiences,
    selectedDeliveryFormats
  ])

  useEffect(() => {
    if (userPreferences && resources.length > 0) {
      const recommendations = resources.filter((resource) => {
        const matchesCareerGoal = userPreferences.careerGoals.some((goal) =>
          resource.description?.toLowerCase().includes(goal.toLowerCase())
        )
        const matchesResourceType = userPreferences.resourceTypes.includes(
          resource.resourceType
        )
        const matchesInPersonPreference =
          !userPreferences.inPersonPreference ||
          (userPreferences.inPersonPreference === 'yes' &&
            resource.deliveryFormat?.includes('In-Person')) ||
          (userPreferences.inPersonPreference === 'no' &&
            !resource.deliveryFormat?.includes('In-Person'))

        return (
          matchesCareerGoal && matchesResourceType && matchesInPersonPreference
        )
      })

      const additionalResources = resources.filter(
        (resource) => !recommendations.includes(resource)
      )

      const finalRecommendations = [
        ...recommendations.slice(0, 4),
        ...additionalResources.slice(0, 4 - recommendations.length)
      ]

      setRecommendedResources(finalRecommendations)
    }
  }, [userPreferences, resources])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <PageWrapper>
      <NavbarWrapper>
        <JobSeekerNav />
      </NavbarWrapper>
      <PageContainer>
        <ProBanner>
          <ProText>Arena Pro✨</ProText>
        </ProBanner>
        <HeaderContainer>
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <PageTitle>Resource Hub</PageTitle>
            <Tooltip
              content={
                <>
                  <br />
                  With Arena Pro’s Resource Hub, you unlock insights on relevant
                  industry-specific events, career advice, and more.
                </>
              }
            >
              <ProIcon src="/images/arena-pro.png" alt="Arena Pro" />{' '}
            </Tooltip>
          </div>
          <SearchBar>
            <Search size={14} />
            <SearchInput
              type="text"
              placeholder="Search"
              onChange={(e) => {
                const query = e.target.value.toLowerCase()
                setFilteredResources(
                  resources.filter((resource) =>
                    resource.heading.toLowerCase().includes(query)
                  )
                )
              }}
            />
            <Button>Search</Button>
          </SearchBar>
          <DropDownBar>
            <NestedDropDownSection
              title="Industry"
              options={allSubcategories}
              onChange={handleIndustryChange}
              selectedValues={selectedIndustries}
            />
            <DropDownSection title="Topic">
              <CheckboxList
                options={topicOptions}
                selectedValues={selectedTopics}
                onChange={handleTopicsChange}
              />
            </DropDownSection>
            <DropDownSection title="Career Level">
              <CheckboxList
                options={levelOptions}
                selectedValues={selectedCareerLevels}
                onChange={handleCareerLevelsChange}
              />
            </DropDownSection>
            <DropDownSection title="Location">
              <CheckboxList
                options={locationOptions}
                selectedValues={selectedLocations}
                onChange={handleLocationsChange}
              />
            </DropDownSection>
            <DropDownSection title="Target Audience">
              <CheckboxList
                options={targetAudienceOptions}
                selectedValues={selectedTargetAudiences}
                onChange={handleTargetAudienceChange}
              />
            </DropDownSection>
            {/* <DropDownSection title="Delivery Format">
              <CheckboxList
                options={deliveryFormatOptions}
                selectedValues={selectedDeliveryFormats}
                onChange={handleDeliveryFormatChange}
              />
            </DropDownSection> */}
          </DropDownBar>
        </HeaderContainer>

        {/* Recommended Section */}
        <CardsContainer backgroundColor="rgba(202, 170, 225, 0.3)">
          <TitleRow>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              <PageTitle style={{ fontWeight: '200', fontSize: '1.5rem' }}>
                Recommended For You
              </PageTitle>
              <Tooltip
                content={
                  <>
                    <span style={{ fontWeight: '600' }}>
                      Recommended for you
                    </span>
                    <br />
                    These resources are tailored based on your career goals and
                    preferences. Update your profile to receive more
                    personalized recommendations.
                    <br />
                    <br />
                    <Link
                      to="/resource-intake"
                      style={{
                        color: 'black', // Custom color for the link
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                      }}
                    >
                      Update Here
                    </Link>
                  </>
                }
              >
                <Info
                  size={16}
                  style={{ cursor: 'pointer', color: '#718096' }}
                />
              </Tooltip>
            </div>
          </TitleRow>
          <CardGrid>
            {recommendedResources.map((resource) => (
              <ResourceCard key={resource.id}>
                <ResourceCardContent>
                  {resource.headerImage && (
                    <ResourceImage src={resource.headerImage} />
                  )}
                  <CardType>
                    <Grid2X2 size={14} />
                    <CardDescription>{resource.resourceType}</CardDescription>
                  </CardType>
                  <CardTitle>{resource.heading}</CardTitle>
                  <CardSubtitle>{resource.subheading}</CardSubtitle>
                  <CardDate>
                    {resource.startDate && resource.endDate
                      ? `${formatDate(resource.startDate)} - ${formatDate(
                          resource.endDate
                        )}`
                      : resource.startDate
                      ? formatDate(resource.startDate)
                      : ' '}
                  </CardDate>

                  <CardDescription>{resource.description}</CardDescription>
                </ResourceCardContent>
                <CardActions>
                  <SmallButton
                    onClick={() =>
                      resource.learnMore &&
                      window.open(
                        resource.learnMore,
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  >
                    Learn More
                  </SmallButton>
                  <Bookmark
                    size={24}
                    style={{ cursor: 'pointer' }}
                    fill={
                      savedResources.includes(resource.id) ? '#718096' : 'none'
                    }
                    color={
                      savedResources.includes(resource.id)
                        ? '#718096'
                        : '#718096'
                    }
                    onClick={() => toggleSave(resource.id)}
                  />
                </CardActions>
              </ResourceCard>
            ))}
          </CardGrid>
        </CardsContainer>

        {/* All Resources */}
        <CardsContainer>
          <TabSwitch currentTab={currentTab} setCurrentTab={setCurrentTab} />
          <CardGrid>
            {paginatedResources.map((resource) => (
              <ResourceCard key={resource.id}>
                <ResourceCardContent>
                  {resource.headerImage && (
                    <ResourceImage src={resource.headerImage} />
                  )}
                  <CardType>
                    <Grid2X2 size={14} />
                    <CardDescription>{resource.resourceType}</CardDescription>
                  </CardType>
                  <CardTitle>{resource.heading}</CardTitle>
                  <CardSubtitle>{resource.subheading}</CardSubtitle>
                  <CardDate>
                    {resource.startDate && resource.endDate
                      ? `${formatDate(resource.startDate)} - ${formatDate(
                          resource.endDate
                        )}`
                      : resource.startDate
                      ? formatDate(resource.startDate)
                      : ' '}
                  </CardDate>
                  <CardDescription>{resource.description}</CardDescription>
                </ResourceCardContent>
                <CardActions>
                  <SmallButton
                    onClick={() =>
                      resource.learnMore &&
                      window.open(
                        resource.learnMore,
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  >
                    Learn More
                  </SmallButton>
                  <Bookmark
                    size={24}
                    style={{ cursor: 'pointer' }}
                    fill={
                      savedResources.includes(resource.id) ? '#718096' : 'none'
                    }
                    color={
                      savedResources.includes(resource.id)
                        ? '#718096'
                        : '#718096'
                    }
                    onClick={() => toggleSave(resource.id)}
                  />
                </CardActions>
              </ResourceCard>
            ))}
          </CardGrid>

          {/* Pagination Controls */}
          <PageNavigation>
            <PageButton
              onClick={() => goToPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ArrowLeft />
            </PageButton>
            {renderPageNumbers()}
            <PageButton
              onClick={() => goToPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <ArrowRight />
            </PageButton>
          </PageNavigation>
        </CardsContainer>
      </PageContainer>
    </PageWrapper>
  )
}

const withResourceProtection = (WrappedComponent) => {
  return function ResourceProtectedRoute(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [isAuthorized, setIsAuthorized] = useState(false)

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          const profileRef = doc(db, 'users', user.uid, 'profiles', 'jobseeker')
          const profileListener = onSnapshot(profileRef, (doc) => {
            if (doc.exists()) {
              const profile = doc.data()
              if (profile?.plan === 'paid') {
                setIsAuthorized(true)
              } else {
                navigate('/jobseeker-dashboard')
              }
            } else {
              navigate('/jobseeker-dashboard')
            }
            setLoading(false)
          })
          return () => profileListener()
        } else {
          navigate('/login')
          setLoading(false)
        }
      })
      return () => unsubscribe()
    }, [navigate])

    if (loading) {
      return <div>Loading...</div>
    }

    return isAuthorized ? <WrappedComponent {...props} /> : null
  }
}

export default withResourceProtection(ResourceHub)
