import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { doc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f3f4f6;
`

const Card = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
`

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

const Text = styled.p`
  color: #4a5568;
  font-size: 1rem;
`

export default function CheckoutSuccess() {
  const navigate = useNavigate()

  useEffect(() => {
    const updateUserProfile = async () => {
      try {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (user) {
            const userId = user.uid

            // Get referral ID from URL
            const urlParams = new URLSearchParams(window.location.search)
            const clientReferenceId = urlParams.get('client_reference_id')

            // Track Rewardful conversion if referral exists
            if (clientReferenceId && window.rewardful) {
              window.rewardful('convert', {
                reference: clientReferenceId,
                customer: userId
              })
            }

            // Update the user's plan details in Firestore
            await updateDoc(doc(db, 'users', userId), {
              plan_selected: true
            })

            await updateDoc(doc(db, `users/${userId}/profiles/jobseeker`), {
              plan_selected: true,
              plan: 'paid',
              plan_expire: Date.now() + 90 * 24 * 60 * 60 * 1000 // 90 days from now
            })

            console.log('Plan updated successfully!')
            navigate('/jobseeker-dashboard')
          } else {
            console.error('No authenticated user found')
          }
        })
        return () => unsubscribe()
      } catch (error) {
        console.error('Error updating profile after purchase:', error)
      }
    }
    updateUserProfile()
  }, [navigate])

  return (
    <Container>
      <Card>
        <Title>Processing your purchase...</Title>
        <Text>Please wait while we set up your Pro account.</Text>
      </Card>
    </Container>
  )
}
