import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import JobSeekerPricing from '../JobSeekerPricing'

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`
const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: auto;
  flex-direction: column;
  background: linear-gradient(135deg, #6366f1 0%, #a855f7 100%);
`

const MainContent = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem;
  margin-top: -4rem;
  animation: ${slideUp} 0.5s ease-out 0.2s both;
`
const TopBar = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  width: 100%;
  text-align: center;
  padding-bottom: 2rem;

  border-bottom: 1px solid #e5e7eb;
`
const Header = styled.h2`
  font-size: 2.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 1rem;
  line-height: 1.2;
  background: linear-gradient(to bottom, #ffffff 0%, #b8c7ff 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-align: center;
`

const SubHeader = styled.h2`
  font-size: 0.9rem;
  font-weight: normal;
  color: #ffffff;

  margin-top: -0.5rem;
  text-align: center;
`

const StyledButton = styled.button`
  background-color: ${(props) => (props.grey ? '#4a5568' : '#c471ed')};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  width: 100%;

  &:hover {
    background-color: ${(props) =>
      props.grey
        ? 'rgba(74, 85, 104, 0.9)'
        : 'rgba(196, 113, 237, 0.9)'}; //same colors just 90% opacity on hover
  }
`
const Button = ({ children, to, ...props }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (to?.startsWith('http')) {
      window.open(to, '_blank', 'noopener,noreferrer') // Open external link
    } else if (to) {
      navigate(to) // Navigate to internal route
    }
  }

  return (
    <StyledButton {...props} onClick={handleClick}>
      {children}
    </StyledButton>
  )
}
export default function JobseekerSelectPlan() {
  const [currentTab, setCurrentTab] = useState('every3months')
  const [selectedCard, setSelectedCard] = useState(null)
  return (
    <Container>
      <TopBar>
        <Header>Plans & Prices</Header>
        <SubHeader>Select a plan that aligns with your career goals</SubHeader>
      </TopBar>
      <MainContent>
        <JobSeekerPricing />
      </MainContent>
    </Container>
  )
}
