import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import JobSeekerNav from './JobSeekerNav';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {Edit, Pencil} from 'lucide-react';
import JobSeekerPricing from '../JobSeekerPricing';

import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'
import { getUserByFirebaseUid } from '../../models/User'
import { auth, db } from '../../firebase'
import { storage } from '../../firebase'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const culturePreferenceOptions = [
  'Initiative & Self-Direction',
  'Collaboration',
  'Adaptability',
  'Integrity',
  'Innovation',
  'Growth Mindset',
  'Accountability',
  'Customer Focus'
].sort()

const workPreferenceOptions = ['Fully Remote', 'In-Office', 'Hybrid']

const pronounOptions = [
  'he/him',
  'she/her',
  'they/them',
  'prefer not to say',
  'other'
]

const raceOptions = [
  'Asian',
  'Black',
  'Hispanic/Latino',
  'Middle Eastern/Arab',
  'Native American',
  'Pacific Islander',
  'White',
  'Prefer not to say'
]

const veteranStatusOptions = [
  'Veteran',
  'Active Duty',
  'Reservist',
  'Not A Veteran',
  'Prefer not to say'
]

const sexualOrientationOptions = [
  'Heterosexual/Straight',
  'Gay',
  'Lesbian',
  'Bisexual',
  'Pansexual',
  'Asexual',
  'Queer',
  'Prefer not to say'
]
const genderOptions = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender Male',
  'Transgender Female',
  'Prefer not to say'
]

const disabilityOptions = ['Yes', 'No', 'Prefer not to say']


const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#CAAAE1',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}
const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f8f9fa;
  overflow-y: auto;
`

const ContentContainer = styled.div`
  flex: 1;
  padding: 1rem 3rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: .75rem;
  }
`

const Card = styled.div`
  background-color: #ffffff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  margin-top: -2rem;
`

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 8px;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};

  color: ${softColors.text};
  transition: all 0.2s;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }
`
const WorkCoreValueBubble = styled.div`
  font-size: 12px;
  background-color: #caaae1; 
  border-radius: 15px;
  padding: 6px 12px;
  margin: 5px;
  color: white;
  display: flex;
  align-items: center;

`
const RaceBubble = styled.div`
  font-size: 12px;
  background-color: #6b7280; 
  border-radius: 15px;
  padding: 6px 12px;
  margin: 5px;
  color: white;
  display: inline-flex;
  align-items: center;
`

const StyledButton = styled.button`
  background-color: #CAAAE1;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease; 
  
  &:hover {
    background-color: #b799d4;
  }
`

const CurrentInfo = styled.p`
  font-size: 14px;
  color: #6b7280;
  margin: 6px 0px 12px 0px;
`;
const InputLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
`
const StyledInput = styled.input`
  width: 98%;
  padding: 8px;
  border: 1px solid #e2e8f0;
    
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
  @media (max-width: 768px) {
    max-width: calc(100% - 50px);

  }
`
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`
const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 8px; 
  transform: translateY(-50%); 
  color: #4a5568;
  padding-left: 10px;
`
const EditIconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  
  display: flex;
  align-items: center;

  &:hover {
    color: #b799d4;
  }
`
const SectionTitleContainer = styled.div`
  display: flex;
`
// Checkbox and Radio Components
const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 0.1rem;
  font-size: 14px;
  color: #6b7280;

  &:hover {
    background-color: ${softColors.border};
  }

  @media (max-width: 768px) {
    font-size: 0.65rem;
    padding: 0.5rem;
  }
`
// Multi-select Components
const MultiSelect = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 1rem;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${softColors.card};
  margin-bottom: 10px;
  @media (max-width: 768px) {
    padding: 0.25rem;
  }
`
const TabButton = styled.button`
  padding: 10px;
  background-color: ${({ active }) => (active ? '#CAAAE1' : 'transparent')};
  border: none;
  border-bottom: ${({ active }) => (active ? '2px solid #CAAAE1' : '1px solid #e5e7eb')};
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? '#FFFFFF' : '#000000')};
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  box-shadow: ${({ active }) =>
    active ? '0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06)' : 'none'};

  @media (max-width: 768px) {
    padding: 6px;
    font-size: 12px;
  }
`;

  const Input = ({ label, ...props }) => (
    <div style={{ marginBottom: '15px' }}>
      <InputLabel>{label}</InputLabel>
      <StyledInput {...props}/>
    </div>
  );
  function EditInputField({ label, value, onChange, placeholder }) {
    return (
      <div style={{ marginBottom: '15px' }}>
        <InputLabel>{label}</InputLabel>
        <InputWrapper>
          <StyledInput
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
          <IconWrapper>
            <Pencil size={12} />
          </IconWrapper>
        </InputWrapper>
      </div>
    );
  }

  const Button = ({ children, ...props }) => (
    <StyledButton {...props}>{children}</StyledButton>
  );

  const Checkbox = ({ label, ...props }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="checkbox"
        style={{
          marginRight: '10px',
          accentColor: '#CAAAE1',
        }}
        {...props}
      />
      <label style={{ fontSize: '14px' }}>{label}</label>
    </div>
  );

  export default function JobSeekerSettings() {
    const [profile, setProfile] = useState({})
    const [user, setUser] = useState(null)
    

    const location = useLocation();
    const [activeTab, setActiveTab] = useState('login');
    const [email, setEmail] = useState('john@email.com');
    const [newEmail, setNewEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    //new fields for editing 
    const [address, setAddress] = useState('');
    const [newAddress, setNewAddress] = useState('');
    const [newWorkPreferences, setNewWorkPreferences] = useState([]);
    const [newWorkCoreValues, setnewWorkCoreValues] = useState([]);
    const [newPronouns, setNewPronouns] = useState('');
    const [newSexualOrientation, setNewSexualOrientation] = useState('');
    const [newGenderIdentity, setNewGenderIdentity] = useState('');
    const [newVeteranStatus, setNewVeteranStatus] = useState('');
    const [newDisabilityStatus, setNewDisabilityStatus] = useState('');
    const [newRace, setNewRace] = useState([]);

    const [editingPersonal, setEditingPersonal] = useState(false);
    const [editingDemographic, setEditingDemographic] = useState(false);

    const [currentTab, setCurrentTab] = useState('monthly');
    const [selectedCard, setSelectedCard] = useState(null);



    const handlePersonalEditClick = () => {
      setEditingPersonal(true);
    }
    const handleDemographicEditClick = () => {
      setEditingDemographic(true);
    }
    const handleUpdateEmail = (e) => {
      e.preventDefault();
      //setEmail(newEmail);
      setNewEmail('');
    };

    const handleUpdatePassword = (e) => {
      e.preventDefault();
      setOldPassword('');
      setNewPassword('');
    };

    const handleUpdatePersonalInfo = (e) => {
      e.preventDefault();
      setAddress(newAddress);
      setNewAddress('');

      setEditingPersonal(false);

    };

    const handleUpdateDemographics = (e) => {
      e.preventDefault();
      setEditingDemographic(false);
    
    };
  

    useEffect(() => {
      const query = new URLSearchParams(location.search);
      const tab = query.get('tab');
      if (tab) {
        setActiveTab(tab);
      }
    }, [location]);    

    useEffect(() => {
      const fetchProfileData = async () => {
        const currentUser = auth.currentUser
        if (currentUser) {
          try {
            const userData = await getUserByFirebaseUid(currentUser.uid)
            setUser(userData)
            setEmail(userData.email);
  
            const jobseekerProfileData = await getJobseekerProfile(
              currentUser.uid
            )
            // Ensure all arrays are initialized even if data is missing
            const profile = {
            
              ...jobseekerProfileData
            }
  
            setProfile(profile); 
          } catch (error) {
            console.error('Error fetching profile data:', error)
          }
        }
      }
  
      fetchProfileData()
    }, [])

  return (
    <PageWrapper>
    <NavbarWrapper>
      <JobSeekerNav />
    </NavbarWrapper>
    <PageContainer>
      <ContentContainer>
    <div style={{fontFamily: 'Arial, sans-serif'}}>
      <h1 style={{ fontSize: '24px', marginBottom: '10px' }}>Account Settings</h1>
      <p style={{ fontSize: '14px', color: '#6b7280', marginBottom: '20px' }}>Manage your job seeker account preferences</p>

      <div style={{ display: 'flex', borderBottom: '1px solid #e5e7eb', marginBottom: '2rem'}}>
        <TabButton active={activeTab === 'login'} onClick={() => setActiveTab('login')}>Login Settings</TabButton>
        <TabButton active={activeTab === 'billing'} onClick={() => setActiveTab('billing')}>Billing</TabButton>
        <TabButton active={activeTab === 'notifications'} onClick={() => setActiveTab('notifications')}>Notifications</TabButton>
        <TabButton active={activeTab === 'update-info'} onClick={() => setActiveTab('update-info')}>Update Information</TabButton>
      </div>

      {activeTab === 'login' && (
        <Card>
          <h2 style={{ fontSize: '18px', marginBottom: '15px' }}>Basic Information</h2>
          <p style={{ fontSize: '14px', color: '#6b7280', marginBottom: '20px' }}>This is login information that you can update anytime.</p>

          <form onSubmit={handleUpdateEmail}>
            <Input label="Current Email" value={email} disabled />
            <Input label="New Email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} placeholder="Enter new email" />
            <Button type="submit">Update Email</Button>
          </form>

          <form onSubmit={handleUpdatePassword} style={{ marginTop: '30px' }}>
            <Input label="Old Password" type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} placeholder="Enter old password" />
            <Input label="New Password" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Enter new password" />
            <p style={{ fontSize: '12px', color: '#6b7280', marginBottom: '10px' }}>Minimum 8 characters</p>
            <Button type="submit">Change Password</Button>
          </form>
        </Card>
      )}


{activeTab === 'billing' && (
  <div style={{ 
    fontFamily: 'Arial, sans-serif',
  }}>
    <JobSeekerPricing/>
  </div>
)}

      {activeTab === 'notifications' && (
        <Card>
          <h2 style={{ fontSize: '18px', marginBottom: '15px' }}>Notification Preferences</h2>
          <p style={{ fontSize: '14px', color: '#6b7280', marginBottom: '20px' }}>Customize your preferred notification settings.</p>

          <Checkbox label="Applications" />
          <p style={{ fontSize: '12px', color: '#6b7280', marginLeft: '25px', marginBottom: '15px' }}>These are notifications for jobs that you have applied to</p>

          <Checkbox label="Job Matches" />
          <p style={{ fontSize: '12px', color: '#6b7280', marginLeft: '25px', marginBottom: '15px' }}>These are notifications for job openings that the AI has matched you with </p>

          <Checkbox label="Recommendations" />
          <p style={{ fontSize: '12px', color: '#6b7280', marginLeft: '25px', marginBottom: '15px' }}>These are notifications for personalized recommendations from Arena</p>

          <Button type="submit">Update Notifications</Button>
        </Card>
      )}

      {activeTab === 'update-info' && (
        <Card>
          <SectionTitleContainer>
            <h2 style={{ fontSize: '18px', marginBottom: '0px' }}>Personal Information</h2>
            
            {!editingPersonal && (
                <EditIconButton onClick={handlePersonalEditClick}>
                  <Edit size={16} />
                </EditIconButton>
              )} 
          </SectionTitleContainer>
          <p style={{ fontSize: '14px', color: '#6b7280', marginBottom: '20px' }}>Update your personal information anytime.</p>

          <form onSubmit={handleUpdatePersonalInfo}>
            <InputLabel>Address</InputLabel>
              {!editingPersonal ? (
              <CurrentInfo>{profile && profile.address ? profile.address : "No address added"}</CurrentInfo> 
              ):(
                <EditInputField placeholder= 'Set address'  value={profile?.address} onChange={(e) => setNewAddress(e.target.value)}></EditInputField>

            )} 

            <InputLabel>Work Preferences</InputLabel>
              {!editingPersonal ? (
              <CurrentInfo>{profile && profile.work_preference ? profile.work_preference : "No work preference specified"}</CurrentInfo>
              ):(
                <Select
                id="work_preference"
                value={profile?.work_preference}
                >
                  <option value="">Select your work preference</option>
                  {workPreferenceOptions.map((pref) => (
                <option key={pref} value={pref}         >
                  {pref}
                </option>
                ))}
                </Select>
              )}

            <InputLabel>Work Core Values</InputLabel>
              {!editingPersonal ? (
                <div style={{display: 'flex', flexWrap: 'wrap'}}> 
                  <WorkCoreValueBubble>{profile?.culture_preferences[0]}</WorkCoreValueBubble>
                  <WorkCoreValueBubble>{profile?.culture_preferences[1]}</WorkCoreValueBubble>
                  <WorkCoreValueBubble>{profile?.culture_preferences[2]}</WorkCoreValueBubble>
                </div>
              ):(
                <MultiSelect>
                  <CheckboxGroup>
                    {culturePreferenceOptions.map((preference) => (
                      <CheckboxLabel key={preference}>
                        <Checkbox checked={profile?.culture_preferences?.includes(preference)}/>
                        <span>{preference}</span>
                      </CheckboxLabel>
                    ))}
                  </CheckboxGroup>
                </MultiSelect>
              )}  

            {editingPersonal && (
              <Button type="submit">Update Personal Information</Button>
            )}            
          </form>
          <SectionTitleContainer>
            <h2 style={{ fontSize: '18px', marginBottom: '0px' }}>Demographic Information</h2>
            {!editingDemographic && (
                <EditIconButton onClick={handleDemographicEditClick}>
                  <Edit size={16} />
                </EditIconButton>
              )} 
          </SectionTitleContainer>
          <p style={{ fontSize: '14px', color: '#6b7280', marginBottom: '20px' }}>Update your demographic information anytime.</p>
          <form onSubmit={handleUpdateDemographics}>
            
          <InputLabel>Pronouns</InputLabel>
          {!editingDemographic ? (
            <CurrentInfo>{profile && profile.pronouns ? profile.pronouns : "Pronouns not specified"}</CurrentInfo> 
            ):(
              <Select
              id="pronouns"
              value={profile?.pronouns}
            >
              <option value="">Select your pronouns</option>
                {pronounOptions.map((option) => (
                  <option key={option} value={option}>
                  {option}
              </option>
              ))}
            </Select>

            )}
          
            <InputLabel>Sexual Orientation</InputLabel>
            {!editingDemographic ? (
             <CurrentInfo>{profile && profile.sexual_orientation ? profile.sexual_orientation : "Sexual Orientation not specified"}</CurrentInfo> 
            ):(
              <Select
              id="pronouns"
              value={profile?.sexual_orientation}
            >
              <option value="">Select  sexual orientation</option>
                {sexualOrientationOptions.map((option) => (
                  <option key={option} value={option}>
                  {option}
              </option>
              ))}
            </Select>
            )}
            <InputLabel>Gender Identity</InputLabel>
            {!editingDemographic ? (
             <CurrentInfo>{profile && profile.gender ? profile.gender : "Gender Identity not specified"}</CurrentInfo> 
            ):(
              <Select
              id="gender"
              value={profile?.gender}
            >
              <option value="">Select gender identity</option>
                {genderOptions.map((option) => (
                  <option key={option} value={option}>
                  {option}
              </option>
              ))}
            </Select>
            )}
            

            <InputLabel>Veteran Status</InputLabel>
            {!editingDemographic ? (
             <CurrentInfo>{profile && profile.veteran_status ? profile.veteran_status : "Veteran Status not specified"}</CurrentInfo> 
            ):(
              <Select
              id="veteran"
              value={profile?.veteran_status}
            >
              <option value="">Select veteran status</option>
                {veteranStatusOptions.map((option) => (
                  <option key={option} value={option}>
                  {option}
              </option>
              ))}
            </Select>
            )}

          <InputLabel>Disability Status</InputLabel>
            {!editingDemographic ? (
             <CurrentInfo>{profile && profile.disability_status ? profile.disability_status : "Disability status not specified"}</CurrentInfo> 
            ):(
              <Select
              id="disability"
              value={profile?.disability_status}
            >
              <option value="">Select disability status</option>
                {disabilityOptions.map((option) => (
                  <option key={option} value={option}>
                  {option}
              </option>
              ))}
            </Select>
            )}
      
          <InputLabel>Racial/Ethnic Background</InputLabel>
            {!editingDemographic ? (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {profile?.race && profile.race.length > 0 ? (
                profile.race.map((raceItem, index) => (
                  <RaceBubble key={index}>{raceItem}</RaceBubble>
                ))
              ) : (
                <CurrentInfo>Racial/Ethnic background specified</CurrentInfo>
              )}
            </div>
            ):(
              <MultiSelect>
                <CheckboxGroup>
                  {raceOptions.map((raceOption) => (
                    <CheckboxLabel key={raceOption}>
                      <Checkbox  checked={profile?.race?.includes(raceOption)}/>
                      <span>{raceOption}</span>
                    </CheckboxLabel>
                  ))}
                </CheckboxGroup>
              </MultiSelect>
            )}
            {editingDemographic && (
              <Button type="submit">Update Demographic Information</Button>
            )}
            
          </form>
        </Card>
      )}
    </div>
    </ContentContainer>
    </PageContainer>
    </PageWrapper>
  );
}