import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts'
import { Users, UserCheck, Award, Briefcase } from 'lucide-react'
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore'
import { db } from '../../firebase'

const COLORS = [
  '#CAAAE1', // Soft purple
  '#9DC6E0', // Soft blue
  '#E5A4C4', // Soft pink
  '#A5D6D9', // Soft teal
  '#D5B6EA' // Another soft purple
]

const Container = styled.div`
  width: 100%;
  margin: 1rem 0;
`

const GridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ChartCard = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 10px;

  &:last-child {
    @media (min-width: 768px) {
      grid-column: span 2;
    }
  }
`

const CardHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
`

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: #2d3748;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS[0]};

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const ChartContainer = styled.div`
  height: ${(props) => (props.chartType === 'bar' ? '24rem' : '16rem')};
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;

  .legend-list {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 140px;

    li {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      color: #4a5568;
    }
  }

  .legend-color {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    flex-shrink: 0;
  }

  .recharts-tooltip-wrapper {
    .custom-tooltip {
      background-color: white;
      border: 1px solid #e2e8f0;
      border-radius: 0.375rem;
      padding: 0.75rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .tooltip-label {
        color: #4a5568;
        margin-bottom: 0.25rem;
      }

      .tooltip-value {
        color: #2d3748;
        font-weight: 500;
      }
    }
  }
`

const LoadingMessage = styled.div`
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #718096;
`

const ErrorMessage = styled.div`
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e53e3e;
`

const NoDataMessage = styled.div`
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #718096;
  font-style: italic;
`

const DemographicInsights = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [demographicData, setDemographicData] = useState({
    gender: [],
    race: [],
    athlete_status: [],
    veteran_status: [],
    current_level: []
  })

  const USERS_COLLECTION = 'users'
  const PROFILE_SUBCOLLECTION = 'profiles'
  const JOBSEEKER_PROFILE_DOC = 'jobseeker'

  useEffect(() => {
    const fetchDemographics = async () => {
      setLoading(true)
      try {
        const demographicsData = {
          gender: {},
          race: {},
          veteran_status: {},
          current_level: {},
          athlete_status: {
            'Has Athletic History': 0,
            'No Athletic History': 0
          }
        }

        const usersCollectionRef = collection(db, USERS_COLLECTION)
        const jobseekerQuery = query(
          usersCollectionRef,
          where('role', '==', 'jobseeker')
        )

        const querySnapshot = await getDocs(jobseekerQuery)
        let totalUsers = querySnapshot.size

        const profilePromises = querySnapshot.docs.map(async (userDoc) => {
          try {
            const profileRef = doc(
              db,
              USERS_COLLECTION,
              userDoc.id,
              PROFILE_SUBCOLLECTION,
              JOBSEEKER_PROFILE_DOC
            )
            const profileSnap = await getDoc(profileRef)

            if (profileSnap.exists()) {
              const data = profileSnap.data()

              if (data.gender) {
                demographicsData.gender[data.gender] =
                  (demographicsData.gender[data.gender] || 0) + 1
              }

              if (data.race && Array.isArray(data.race)) {
                data.race.forEach((r) => {
                  demographicsData.race[r] = (demographicsData.race[r] || 0) + 1
                })
              }

              if (data.veteran_status) {
                demographicsData.veteran_status[data.veteran_status] =
                  (demographicsData.veteran_status[data.veteran_status] || 0) +
                  1
              }

              if (data.current_level) {
                demographicsData.current_level[data.current_level] =
                  (demographicsData.current_level[data.current_level] || 0) + 1
              }

              if (
                (data.athletic_history && data.athletic_history.length > 0) ||
                data.athlete_status === true
              ) {
                demographicsData.athlete_status['Has Athletic History'] += 1
              } else {
                demographicsData.athlete_status['No Athletic History'] += 1
              }
            }
          } catch (error) {
            console.error(
              `Error fetching profile for user ${userDoc.id}:`,
              error
            )
          }
        })

        await Promise.all(profilePromises)

        const transformedData = {
          gender: Object.entries(demographicsData.gender).map(
            ([name, value]) => ({ name, value })
          ),
          race: Object.entries(demographicsData.race).map(
            ([name, value]) => ({ name, value })
          ),
          athlete_status: Object.entries(demographicsData.athlete_status).map(
            ([name, value]) => ({ name, value })
          ),
          veteran_status: Object.entries(demographicsData.veteran_status).map(
            ([name, value]) => ({ name, value })
          ),
          current_level: Object.entries(demographicsData.current_level).map(
            ([name, value]) => ({
              name,
              value: ((value / totalUsers) * 100).toFixed(1)
            })
          )
        }

        setDemographicData(transformedData)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching demographics:', error)
        setError(error.message)
        setLoading(false)
      }
    }

    fetchDemographics()
  }, [])

  const CustomTooltip = ({ active, payload, label, chartType }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload.tooltipData || []
      const total = data.reduce((sum, item) => sum + item.value, 0)
      const value = payload[0].value
      const percentage = ((value / total) * 100).toFixed(1)

      return (
        <div className="custom-tooltip">
          <div className="tooltip-label">{payload[0].name}</div>
          <div className="tooltip-value">
            {chartType === 'bar' ? `${payload[0].value}%` : `${percentage}%`}
          </div>
        </div>
      )
    }
    return null
  }

  const CustomLegend = ({ data }) => (
    <ul className="legend-list">
      {data.map((entry, index) => (
        <li key={`legend-${index}`}>
          <span
            className="legend-color"
            style={{ backgroundColor: COLORS[index % COLORS.length] }}
          />
          <span>{entry.name} - {entry.percentage}%</span>
        </li>
      ))}
    </ul>
  )

  const renderChart = (data, title, Icon, chartType = 'pie') => {
    if (loading) {
      return (
        <ChartCard>
          <CardHeader>
            <CardTitle>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {title}
            </CardTitle>
          </CardHeader>
          <LoadingMessage>Loading data...</LoadingMessage>
        </ChartCard>
      )
    }

    if (error) {
      return (
        <ChartCard>
          <CardHeader>
            <CardTitle>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {title}
            </CardTitle>
          </CardHeader>
          <ErrorMessage>Error loading data: {error}</ErrorMessage>
        </ChartCard>
      )
    }

    if (!data || data.length === 0) {
      return (
        <ChartCard>
          <CardHeader>
            <CardTitle>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {title}
            </CardTitle>
          </CardHeader>
          <NoDataMessage>No data available</NoDataMessage>
        </ChartCard>
      )
    }

    const total = data.reduce((sum, item) => sum + item.value, 0)
    const enrichedData = data.map((item) => ({
      ...item,
      tooltipData: data,
      percentage: ((item.value / total) * 100).toFixed(1),
    }))

    return (
      <ChartCard>
        <CardHeader>
          <CardTitle>
            <IconWrapper>
              <Icon />
            </IconWrapper>
            {title}
          </CardTitle>
        </CardHeader>
        <ChartContainer chartType={chartType}>
          {chartType === 'pie' && <CustomLegend data={enrichedData} />}
          <ResponsiveContainer
            width={chartType === 'pie' ? '80%' : '100%'}
            height="100%"
          >
            {chartType === 'pie' ? (
              <PieChart>
                <Pie
                  data={enrichedData}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  nameKey="name"
                  label={false}
                  startAngle={90}
                  endAngle={-270}
                >
                  {enrichedData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  content={(props) => (
                    <CustomTooltip {...props} chartType="pie" />
                  )}
                />
              </PieChart>
            ) : (
              <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 20, right: 60, left: 20, bottom: 20 }}
              >
                <XAxis
                  type="number"
                  tick={{ fill: '#718096' }}
                  axisLine={{ stroke: '#E2E8F0' }}
                  domain={[0, 100]}
                  tickFormatter={(value) => `${value}%`}
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  tick={{
                    fill: '#718096',
                    fontSize: '0.875rem'
                  }}
                  axisLine={{ stroke: '#E2E8F0' }}
                  width={150}
                  interval={0}
                  tickMargin={8}
                  orientation="left"
                />
                <Tooltip
                  content={(props) => (
                    <CustomTooltip {...props} chartType="bar" />
                  )}
                  cursor={{ fill: 'rgba(202, 170, 225, 0.1)' }}
                />
                <Bar
                  dataKey="value"
                  radius={[0, 4, 4, 0]}
                  barSize={24}
                  minPointSize={2}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            )}
          </ResponsiveContainer>
        </ChartContainer>
      </ChartCard>
    )
  }

  return (
    <Container>
      <GridContainer>
        {renderChart(demographicData.gender, 'Gender Distribution', Users)}
        {renderChart(
          demographicData.race,
          'Race/Ethnicity Distribution',
          Users,
        )}
        {renderChart(demographicData.athlete_status, 'Athletic History', Award)}
        {renderChart(
          demographicData.veteran_status,
          'Veteran Status',
          UserCheck
        )}
        {renderChart(
          demographicData.current_level,
          'Current Level Distribution',
          Briefcase,
          'bar'
        )}
      </GridContainer>
    </Container>
  )
}

export default DemographicInsights
