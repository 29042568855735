import React, { useState, useEffect } from 'react'
import { Linkedin } from 'lucide-react'

const LinkedInImport = ({ onDataImported }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const baseUrl =
    process.env.REACT_APP_LINKEDIN_API_URL ||
    'http://localhost:5001/arena-40a49/us-central1/linkedin'

  useEffect(() => {
    const handleMessage = async (event) => {
      console.log('Received message event:', event.origin, event.data)
      const allowedOrigins = [
        'http://localhost:3000/',
        'https://app.arenatalent.com',
        'http://localhost:5001',
        'https://linkedin-rpcqtsqmba-uc.a.run.app'
      ]

      if (!allowedOrigins.includes(event.origin)) {
        console.log('Ignoring message from unauthorized origin')
        return
      }

      if (event.data.type === 'LINKEDIN_AUTH_SUCCESS') {
        console.log('Received LinkedIn auth success message')
        const { accessToken } = event.data

        try {
          console.log('Fetching LinkedIn data')
          const dataResponse = await fetch(`${baseUrl}/member-snapshot`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })

          if (!dataResponse.ok) {
            throw new Error(
              `Failed to fetch LinkedIn data: ${dataResponse.statusText}`
            )
          }

          const linkedInData = await dataResponse.json()
          console.log('LinkedIn data received:', linkedInData)

          // Transform the data to match your form structure
          const transformedData = {
            work_history: linkedInData.positions.map((position) => ({
              title: position.title || '',
              employer: position.companyName || '',
              start_date: position.startDate
                ? `${position.startDate.year}-${String(
                    position.startDate.month
                  ).padStart(2, '0')}`
                : '',
              end_date: position.endDate
                ? `${position.endDate.year}-${String(
                    position.endDate.month
                  ).padStart(2, '0')}`
                : '',
              present: !position.endDate,
              industry: [],
              company_type: [],
              job_function: []
            })),
            education_history: linkedInData.education.map((edu) => ({
              institution: edu.schoolName || '',
              degree_type: edu.degree || '',
              field_of_study: edu.fieldOfStudy || '',
              graduation_date: edu.endDate
                ? `${edu.endDate.year}-${String(
                    edu.endDate.month || '01'
                  ).padStart(2, '0')}`
                : ''
            }))
          }

          console.log('Transformed data:', transformedData)
          onDataImported(transformedData)
          setIsLoading(false)
        } catch (error) {
          console.error('Error fetching LinkedIn data:', error)
          setError('Failed to import LinkedIn data. Please try again.')
          setIsLoading(false)
        }
      } else if (event.data.type === 'LINKEDIN_AUTH_ERROR') {
        console.error(
          'LinkedIn auth error:',
          event.data.error,
          event.data.description
        )
        setError(`LinkedIn authentication failed: ${event.data.error}`)
        setIsLoading(false)
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [baseUrl, onDataImported])

  const handleLinkedInImport = async () => {
    console.log('Starting LinkedIn import process')
    setIsLoading(true)
    setError(null)

    try {
      console.log('Fetching auth URL from Firebase Function')
      const response = await fetch(`${baseUrl}/auth`)
      const { authUrl } = await response.json()
      console.log('Received auth URL:', authUrl)

      const width = 600
      const height = 600
      const left = window.screen.width / 2 - width / 2
      const top = window.screen.height / 2 - height / 2

      console.log('Opening LinkedIn auth window')
      window.open(
        authUrl,
        'LinkedIn Login',
        `width=${width},height=${height},left=${left},top=${top}`
      )
    } catch (error) {
      console.error('LinkedIn import error:', error)
      setError('Failed to initiate LinkedIn import. Please try again.')
      setIsLoading(false)
    }
  }

  return (
    <div>
      <button
        onClick={() => {
          console.log('LinkedIn import button clicked')
          handleLinkedInImport()
        }}
        disabled={isLoading}
        className="flex items-center gap-2 px-4 py-2 bg-[#0A66C2] text-white rounded hover:bg-[#004182] disabled:opacity-50"
      >
        <Linkedin size={18} />
        {isLoading ? 'Importing...' : 'Import from LinkedIn'}
      </button>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  )
}

export default LinkedInImport
