import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { auth } from '../../firebase'
import { createJobPosting } from '../../models/JobPosting'
import { getEmployerProfile } from '../../models/EmployerProfile'
import EmployerNav from './EmployerNav'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { app } from '../../firebase'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {
  Heart,
  Calendar,
  GraduationCap,
  Laptop,
  Smile,
  Monitor,
  Users,
  Train,
  Coffee,
  BanknoteIcon,
  Shield,
  Plus,
  X,
  ArrowLeft
} from 'lucide-react'
const functions = getFunctions(app)

async function fetchSalaryRecommendation(jobTitle, location) {
  const getSalaryRecommendation = httpsCallable(
    functions,
    'getSalaryRecommendation'
  )
  try {
    const result = await getSalaryRecommendation({
      jobTitle,
      location
    })

    if (!result.data || !result.data.salaryRecommendation) {
      throw new Error('Invalid salary recommendation response')
    }

    return result.data.salaryRecommendation
  } catch (error) {
    console.error('Error getting salary recommendation:', error)
    throw error
  }
}

const strengths = [
  'Communication',
  'Leadership',
  'Problem Solving',
  'Teamwork',
  'Adaptability',
  'Creativity',
  'Time Management',
  'Critical Thinking',
  'Attention to Detail',
  'Emotional Intelligence',
  'Strategic Planning',
  'Networking',
  'Decision Making',
  'Conflict Resolution',
  'Analytical Skills',
  'Project Management',
  'Negotiation',
  'Public Speaking',
  'Customer Service',
  'Technical Proficiency',
  'Data Analysis',
  'Research',
  'Writing',
  'Multitasking',
  'Sales',
  'Marketing',
  'Budgeting',
  'Coaching',
  'Innovation',
  'Interpersonal Skills'
].sort()

const additionalCompensationOptions = [
  'Annual Bonus',
  'Commission',
  'Equity',
  'Performance Incentive Bonus',
  'Profit Sharing',
  'School Credit',
  'Signing Bonus',
  'Stock Options'
]

const technicalSkills = [
  'Adobe Creative Suite',
  'Adobe XD',
  'Airtable',
  'Amplitude',
  'Asana',
  'AutoCAD',
  'AWS',
  'Azure',
  'C++',
  'Calendly',
  'CI/CD',
  'ClickUp',
  'Confluence',
  'Docker',
  'Figma',
  'G Suite',
  'Git',
  'GitHub/GitLab',
  'Google Analytics',
  'HubSpot',
  'Java',
  'JavaScript',
  'Jenkins',
  'Jira',
  'Kubernetes',
  'Linear',
  'Looker',
  'Microsoft Dynamics',
  'Microsoft Excel',
  'Microsoft PowerPoint',
  'Microsoft SharePoint',
  'Microsoft Teams',
  'Microsoft Word',
  'Miro',
  'Mixpanel',
  'Monday.com',
  'MongoDB',
  'MySQL',
  'NetSuite',
  'Node.js',
  'Notion',
  'Oracle',
  'Oracle ERP',
  'PostgreSQL',
  'Postman',
  'Python',
  'QuickBooks',
  'React.js',
  'Redis',
  'REST APIs',
  'Revit',
  'SAP',
  'Salesforce',
  'ServiceNow',
  'Sketch',
  'Slack',
  'SQL',
  'Tableau',
  'Trello',
  'TypeScript',
  'Unity',
  'Workday',
  'Zendesk',
  'Zoom',
  'Other'
].sort((a, b) => {
  // Keep "Other" at the end
  if (a === 'Other') return 1
  if (b === 'Other') return -1
  return a.localeCompare(b)
})

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f4f8;
  color: #2d3748;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 999;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e2e8f0;
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  background: none;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    background: #f1f5f9;
    color: #334155;
  }
`

const FormCard = styled.form`
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 0rem;
  }
`

const FormTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 3rem;
`

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }

  &::placeholder {
    color: #94a3b8;
  }
`

const SalaryInputGroup = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const SalaryInput = styled(Input)`
  width: 200px;
  @media (min-width: 768px) {
    width: 200px;
  }
`

const TextFormatGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
`

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  min-height: 120px;
  font-family: 'Inter', sans-serif;
  resize: vertical;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }

  &::placeholder {
    color: #94a3b8;
  }
`

const Select = styled.select`
  width: 80%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-left: 20px;
  font-size: 0.875rem;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s;

  &:hover {
    background: #f8fafc;
  }

  input {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 4px;
    cursor: pointer;
  }
`

const RadioGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: all 0.2s;
  background: ${(props) => (props.checked ? '#f8f4fb' : 'white')};
  border: 1px solid ${(props) => (props.checked ? '#caaae1' : '#e2e8f0')};

  &:hover {
    background: #f8f4fb;
  }

  input {
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
`

const SkillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    max-height: 200px;
    overflow-y: scroll;
  }
`

const SkillTag = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f8f4fb;
  color: #805ad5;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
`

const RemoveButton = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  color: #805ad5;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;

  &:hover {
    color: #553c9a;
  }
`
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #caaae1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #b38fd1;
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-top: 3rem;
`

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
`

const BenefitCard = styled.div`
  position: relative;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const BenefitIcon = styled.div`
  width: 48px;
  height: 48px;
  background-color: #f8f4fb;
  color: #caaae1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(2px);
`
const FormSection = styled.div`
  margin-bottom: 2.5rem;
  position: relative;
`
const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
  color: #475569;

  ${(props) =>
    props.required &&
    `
    &::after {
      content: '*';
      color: #e53e3e;
      margin-left: 4px;
    }
  `}
`
const InfoMessage = styled.div`
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: #f8f4fb;
  border: 1px solid #caaae1;
  border-radius: 8px;
  color: #4a5568;
  font-size: 0.875rem;
  line-height: 1.5;

  strong {
    color: #2d3748;
    display: block;
    margin-bottom: 0.5rem;
  }
`

const RichTextWrapper = styled.div`
  .quill {
    background: white;
    border-radius: 8px;
    border: 1px solid #e2e8f0;

    .ql-toolbar {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: none;
      border-bottom: 1px solid #e2e8f0;
      background: #f8fafc;
    }

    .ql-container {
      border: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      font-family: 'Inter', sans-serif;
      font-size: 0.875rem;
    }

    .ql-editor {
      min-height: 120px;

      &:focus {
        outline: none;
      }
    }
  }

  .ql-editor.ql-blank::before {
    color: #94a3b8;
    font-style: normal;
  }
`

// Rich text editor modules configuration
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean']
  ]
}

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'link'
]

const employmentTypes = [
  'Full-Time',
  'Part-time',
  'Internship',
  'Contract',
  'Hourly'
]

const levelOptions = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]

const workPolicyOptions = ['Remote', 'In-Office', 'Hybrid']

const benefitCategories = [
  { value: 'Healthcare', label: 'Healthcare', icon: Heart },
  { value: 'Time Off', label: 'Time Off', icon: Calendar },
  {
    value: 'Learning & Development',
    label: 'Learning & Development',
    icon: GraduationCap
  },
  { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
  { value: 'Wellness', label: 'Wellness', icon: Smile },
  { value: 'Equipment', label: 'Equipment', icon: Monitor },
  { value: 'Family', label: 'Family', icon: Users },
  { value: 'Commuter', label: 'Commuter', icon: Train },
  { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
  { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
  { value: 'Insurance', label: 'Insurance', icon: Shield }
]

const JobPostForm = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [employerProfile, setEmployerProfile] = useState(null)
  const [showBenefitModal, setShowBenefitModal] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})
  const [salary, setSalary] = useState({ start: '', end: '' })
  const [recommendedSalary, setRecommendedSalary] = useState('')
  const [showOtherTechnicalSkills, setShowOtherTechnicalSkills] =
    useState(false)
  const [otherTechnicalSkills, setOtherTechnicalSkills] = useState('')
  const [companyBenefits, setCompanyBenefits] = useState([])
  const [showCompanyBenefits, setShowCompanyBenefits] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newBenefit, setNewBenefit] = useState({
    category: '',
    description: ''
  })

  const [formData, setFormData] = useState({
    title: '',
    type: [],
    location: '',
    work_policy: '',
    department: '',
    level: '',
    required_skills: [],
    skillInput: '',
    description: '',
    responsibilities: '',
    requirements: '',
    nice_to_haves: '',
    benefits: [],
    salary_type: 'yearly',
    salary_range_start: '40000',
    salary_range_end: '150000',
    willing_to_sponsor: false,
    strengths: [],
    technical_skills: [],
    additional_compensation: [],
    application_type: 'internal',
    external_application_url: ''
  })

  useEffect(() => {
    const fetchEmployerProfile = async () => {
      try {
        const profile = await getEmployerProfile(auth.currentUser.uid)
        setEmployerProfile(profile)
        if (profile.benefits && profile.benefits.length > 0) {
          setCompanyBenefits(profile.benefits)
          // Only initialize if no benefits are currently set
          setFormData((prev) => {
            if (prev.benefits.length === 0) {
              return {
                ...prev,
                benefits: profile.benefits
              }
            }
            return prev
          })
        }
        setLoading(false)
      } catch (error) {
        console.error('Error fetching employer profile:', error)
        setLoading(false)
      }
    }

    fetchEmployerProfile()
  }, []) // We don't need formData.benefits.length as a dependency since we check it inside the effect

  const handleToggleCompanyBenefits = () => {
    setShowCompanyBenefits(!showCompanyBenefits)
    if (!showCompanyBenefits) {
      // When re-enabling company benefits, merge them with current job-specific benefits
      setFormData((prev) => ({
        ...prev,
        benefits: [
          ...new Map(
            [...prev.benefits, ...companyBenefits].map((item) => [
              item.category + item.description,
              item
            ])
          ).values()
        ]
      }))
    }
  }
  const handleSalaryTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      salary_type: type,
      salary_range_start:
        type === 'yearly'
          ? '40000'
          : type === 'hourly'
          ? '10'
          : type === 'stipend'
          ? '500'
          : '0',
      salary_range_end:
        type === 'yearly'
          ? '150000'
          : type === 'hourly'
          ? '100'
          : type === 'stipend'
          ? '5000'
          : '0'
    }))
  }

  const handleStrengthSelect = (strength) => {
    setFormData((prev) => {
      const selected = prev.strengths.includes(strength)
        ? prev.strengths.filter((s) => s !== strength)
        : [...prev.strengths, strength]
      return { ...prev, strengths: selected.slice(0, 3) }
    })
  }

  const handleRichTextChange = (value, field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name === 'external_application_url') {
      // Only format if there's a value and it doesn't already start with http:// or https://
      if (value && !value.match(/^https?:\/\//)) {
        setFormData((prev) => ({
          ...prev,
          [name]: `https://${value}`
        }))
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value
        }))
      }
    } else {
      // Handle all other inputs normally
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }))
    }
  }

  const handleAskAIForSalary = async () => {
    try {
      setIsSubmitting(true)

      if (!formData.title || !formData.location) {
        alert(
          'Please enter both job title and location before requesting a salary recommendation'
        )
        return
      }

      const getSalaryRecommendation = httpsCallable(
        functions,
        'getSalaryRecommendation'
      )
      const result = await getSalaryRecommendation({
        jobTitle: formData.title,
        location: formData.location
      })

      if (result.data && result.data.salaryRecommendation) {
        setRecommendedSalary(result.data.salaryRecommendation)

        const salaryText = result.data.salaryRecommendation.toLowerCase()
        const numbers = salaryText.match(/\d+/g)
        if (numbers && numbers.length >= 2) {
          setFormData((prev) => ({
            ...prev,
            salary_range_start: numbers[0],
            salary_range_end: numbers[1]
          }))
        }
      } else {
        throw new Error('Invalid salary recommendation response')
      }
    } catch (error) {
      console.error('Error getting salary recommendation:', error)
      alert('Unable to get salary recommendation. Please try again later.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCheckInclusiveLanguage = async () => {
    try {
      setIsSubmitting(true)
      const checkInclusiveLanguage = httpsCallable(
        functions,
        'checkInclusiveLanguage'
      )
      const result = await checkInclusiveLanguage({
        jobDescription: formData.description,
        responsibilities: formData.responsibilities,
        requirements: formData.requirements,
        niceToHaves: formData.nice_to_haves
      })

      if (result.data && result.data.suggestions) {
        alert(
          `Inclusive Language Suggestions:\n\n${result.data.suggestions.join(
            '\n'
          )}`
        )
      } else {
        alert('No suggestions for improving inclusive language.')
      }
    } catch (error) {
      console.error('Error checking inclusive language:', error)
      alert('Unable to check inclusive language. Please try again later.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleAdditionalCompensationChange = (compensation) => {
    setFormData((prev) => {
      const updatedCompensation = prev.additional_compensation.includes(
        compensation
      )
        ? prev.additional_compensation.filter((item) => item !== compensation)
        : [...prev.additional_compensation, compensation]

      return {
        ...prev,
        additional_compensation: updatedCompensation
      }
    })
  }
  const handleTechnicalSkillSelect = (skill) => {
    if (skill === 'Other') {
      setShowOtherTechnicalSkills(!showOtherTechnicalSkills)
      return
    }

    setFormData((prev) => ({
      ...prev,
      technical_skills: prev.technical_skills.includes(skill)
        ? prev.technical_skills.filter((s) => s !== skill)
        : [...prev.technical_skills, skill]
    }))
  }

  const handleOtherTechnicalSkillsSubmit = () => {
    if (!otherTechnicalSkills.trim()) return

    const newSkills = otherTechnicalSkills
      .split(',')
      .map((skill) => skill.trim())
      .filter((skill) => skill)

    setFormData((prev) => ({
      ...prev,
      technical_skills: [...new Set([...prev.technical_skills, ...newSkills])]
    }))

    setOtherTechnicalSkills('')
  }

  const handleTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      type: prev.type.includes(type)
        ? prev.type.filter((t) => t !== type)
        : [...prev.type, type]
    }))
  }

  const handleSkillAdd = () => {
    if (formData.skillInput.trim()) {
      setFormData((prev) => ({
        ...prev,
        required_skills: [...prev.required_skills, prev.skillInput.trim()],
        skillInput: ''
      }))
    }
  }

  const handleSkillRemove = (skillToRemove) => {
    setFormData((prev) => ({
      ...prev,
      required_skills: prev.required_skills.filter(
        (skill) => skill !== skillToRemove
      )
    }))
  }

  const handleAddBenefit = () => {
    if (!newBenefit.category || !newBenefit.description) {
      alert('Please fill in all benefit fields')
      return
    }

    setFormData((prev) => ({
      ...prev,
      benefits: [...prev.benefits, newBenefit]
    }))
    setNewBenefit({ category: '', description: '' })
    setShowBenefitModal(false)
  }

  const handleRemoveBenefit = (index) => {
    setFormData((prev) => ({
      ...prev,
      benefits: prev.benefits.filter((_, i) => i !== index)
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    // Basic form validation
    const errors = {}

    if (!formData.title?.trim()) errors.title = 'Job title is required.'
    if (formData.strengths.length < 1)
      errors.strengths = 'Please select at least one strength.'
    if (!formData.description?.trim())
      errors.description = 'Job description is required.'
    if (!formData.responsibilities?.trim())
      errors.responsibilities = 'Responsibilities are required.'
    if (!formData.requirements?.trim())
      errors.requirements = 'Requirements are required.'
    if (!formData.location?.trim())
      errors.location = 'Job location is required.'
    if (!formData.work_policy) errors.work_policy = 'Work policy is required.'
    if (!formData.department?.trim())
      errors.department = 'Department is required.'
    if (!formData.level) errors.level = 'Job level is required.'
    if (formData.type.length < 1)
      errors.type = 'Please select at least one type of employment.'
    if (formData.benefits.length < 1)
      errors.benefits = 'Please add at least one benefit.'
    if (formData.willing_to_sponsor === null)
      errors.willing_to_sponsor = 'Please specify visa sponsorship.'

    // Validate external application URL if application type is external
    if (formData.application_type === 'external') {
      if (!formData.external_application_url?.trim()) {
        errors.external_application_url =
          'External application URL is required when using external applications.'
      } else {
        try {
          new URL(formData.external_application_url)
        } catch (error) {
          errors.external_application_url = 'Please enter a valid URL.'
        }
      }
    }

    // Only validate salary if a type is selected and it's not unpaid
    if (formData.salary_type !== 'unpaid' && formData.salary_type !== '') {
      const startSalary = parseInt(formData.salary_range_start)
      const endSalary = parseInt(formData.salary_range_end)

      if (startSalary && endSalary && startSalary > endSalary) {
        errors.salary = 'Starting salary cannot be greater than ending salary.'
      }
    }

    // Required skills validation
    if (formData.required_skills.length === 0) {
      errors.required_skills = 'Please add at least one required skill.'
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      setIsSubmitting(false)

      // Scroll to the first error
      const firstError = Object.keys(errors)[0]
      const errorElement = document.querySelector(`[name="${firstError}"]`)
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }

      return
    }

    try {
      const postingData = {
        title: formData.title.trim(),
        type: formData.type,
        location: formData.location.trim(),
        work_policy: formData.work_policy,
        department: formData.department.trim(),
        level: formData.level,
        description: formData.description.trim(),
        responsibilities: formData.responsibilities.trim(),
        requirements: formData.requirements.trim(),
        strengths: formData.strengths,
        technical_skills: formData.technical_skills,
        nice_to_haves: formData.nice_to_haves?.trim() || null,
        recommended_skills: formData.required_skills,
        benefits: formData.benefits.map((benefit) => ({
          category: benefit.category,
          description: benefit.description.trim()
        })),
        willing_to_sponsor: formData.willing_to_sponsor,
        application_type: formData.application_type,
        external_application_url:
          formData.application_type === 'external'
            ? formData.external_application_url.trim()
            : null,
        salary:
          formData.salary_type === ''
            ? null
            : {
                type: formData.salary_type,
                range_start:
                  formData.salary_type === 'unpaid'
                    ? 0
                    : parseInt(formData.salary_range_start) || null,
                range_end:
                  formData.salary_type === 'unpaid'
                    ? 0
                    : parseInt(formData.salary_range_end) || null
              },
        additional_compensation: formData.additional_compensation,
        employer_profile: {
          company_name: employerProfile?.company_name || '',
          company_logo: employerProfile?.company_logo || '',
          company_website: employerProfile?.company_website || ''
        }
      }

      const jobRef = await createJobPosting(postingData)
      navigate('/job-postings')
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      const errorMessage =
        error.message || 'Error creating job posting. Please try again.'
      alert(errorMessage)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (loading) return <div>Loading...</div>

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <TopBar>
          <BackButton onClick={() => navigate('/job-postings')}>
            <ArrowLeft size={16} />
            Back to all Jobs
          </BackButton>
        </TopBar>

        <FormCard onSubmit={handleSubmit}>
          <FormTitle>Create Job Posting</FormTitle>

          <FormSection>
            <Label required>Job Title</Label>
            <Input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              placeholder="e.g., Senior Software Engineer"
            />
          </FormSection>

          <FormSection>
            <Label required>Type of Employment</Label>
            <CheckboxGroup>
              {employmentTypes.map((type) => (
                <CheckboxLabel key={type}>
                  <input
                    type="checkbox"
                    checked={formData.type.includes(type)}
                    onChange={() => handleTypeChange(type)}
                    required={formData.type.length === 0}
                  />
                  <span>{type}</span>
                </CheckboxLabel>
              ))}
            </CheckboxGroup>
          </FormSection>

          <GridContainer>
            <FormSection>
              <Label required>Job Location</Label>
              <Input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                required
                placeholder="e.g., New York, NY"
              />
            </FormSection>

            <FormSection>
              <Label required style={{ marginLeft: '20px' }}>
                Work Policy
              </Label>
              <Select
                name="work_policy"
                value={formData.work_policy}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Work Policy</option>
                {workPolicyOptions.map((policy) => (
                  <option key={policy} value={policy}>
                    {policy}
                  </option>
                ))}
              </Select>
            </FormSection>
          </GridContainer>

          <GridContainer>
            <FormSection>
              <Label required>Department</Label>
              <Input
                type="text"
                name="department"
                value={formData.department}
                onChange={handleInputChange}
                required
                placeholder="e.g., Engineering"
              />
            </FormSection>

            <FormSection>
              <Label required style={{ marginLeft: '20px' }}>
                Level
              </Label>
              <Select
                name="level"
                value={formData.level}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Level</option>
                {levelOptions.map((level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
              </Select>
            </FormSection>
          </GridContainer>

          <FormSection>
            <Label>Compensation</Label>
            <RadioGroup>
              <RadioLabel checked={formData.salary_type === 'yearly'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'yearly'}
                  onChange={() => handleSalaryTypeChange('yearly')}
                />
                Yearly Salary
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'hourly'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'hourly'}
                  onChange={() => handleSalaryTypeChange('hourly')}
                />
                Hourly Rate
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'unpaid'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'unpaid'}
                  onChange={() => handleSalaryTypeChange('unpaid')}
                />
                Unpaid
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'stipend'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'stipend'}
                  onChange={() => handleSalaryTypeChange('stipend')}
                />
                Stipend
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'hidden'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'hidden'}
                  onChange={() => handleSalaryTypeChange('hidden')}
                />
                Prefer not to answer
              </RadioLabel>
            </RadioGroup>{' '}
            {formData.salary_type === 'hidden' && (
              <InfoMessage>
                <strong>Why Salary Transparency Matters</strong>
                We noticed you didn't leave a salary range. At Arena, our
                mission is to create equitable career opportunities and that
                includes prioritizing salary transparency on job postings. In
                2023, 50% of all job postings included salary ranges which led
                to 63% more applications and 35% lower cost-per-click for
                employers. Salary transparency is also proven to reduce the
                gender wage gap by 20-40%. We hope you will reconsider posting a
                salary to ensure you are competitive to top talent on the
                platform.
              </InfoMessage>
            )}
            <FormSection>
              <Label>Additional Compensation</Label>
              <CheckboxGroup>
                {additionalCompensationOptions.map((option) => (
                  <CheckboxLabel key={option}>
                    <input
                      type="checkbox"
                      checked={formData.additional_compensation.includes(
                        option
                      )}
                      onChange={() =>
                        handleAdditionalCompensationChange(option)
                      }
                    />
                    <span>{option}</span>
                  </CheckboxLabel>
                ))}
              </CheckboxGroup>
            </FormSection>
            {(formData.salary_type === 'yearly' ||
              formData.salary_type === 'hourly' ||
              formData.salary_type === 'stipend') && (
              <SalaryInputGroup>
                <SalaryInput
                  type="number"
                  name="salary_range_start"
                  value={formData.salary_range_start}
                  onChange={handleInputChange}
                  placeholder="Starting range"
                />
                <span>to</span>
                <SalaryInput
                  type="number"
                  name="salary_range_end"
                  value={formData.salary_range_end}
                  onChange={handleInputChange}
                  placeholder="Ending range"
                />
                {formData.salary_type === 'hourly' && <span>/hour</span>}
                {formData.salary_type === 'stipend' && <span>/stipend</span>}
              </SalaryInputGroup>
            )}
          </FormSection>

          <FormSection>
            <Label required>Required Skills</Label>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Input
                type="text"
                name="skillInput"
                value={formData.skillInput}
                onChange={handleInputChange}
                placeholder="Enter a skill and press Add"
              />
              <Button
                type="button"
                onClick={handleSkillAdd}
                disabled={!formData.skillInput.trim()}
              >
                <Plus size={16} /> Add
              </Button>
            </div>
            <input
              type="hidden"
              required
              value={formData.required_skills.length > 0 ? 'valid' : ''}
            />
            <SkillContainer>
              {formData.required_skills.map((skill, index) => (
                <SkillTag key={index}>
                  {skill}
                  <RemoveButton onClick={() => handleSkillRemove(skill)}>
                    <X size={14} />
                  </RemoveButton>
                </SkillTag>
              ))}
            </SkillContainer>
          </FormSection>
          <FormSection>
            <Label required>Select Strengths (Choose up to 3)</Label>
            <SkillContainer>
              {strengths.map((strength) => (
                <SkillTag
                  key={strength}
                  onClick={() => handleStrengthSelect(strength)}
                  style={{
                    backgroundColor: formData.strengths.includes(strength)
                      ? '#caaae1'
                      : '#f8f4fb',
                    color: formData.strengths.includes(strength)
                      ? 'white'
                      : '#805ad5'
                  }}
                >
                  {strength}
                </SkillTag>
              ))}
            </SkillContainer>
          </FormSection>

          <FormSection>
            <Label>Technical Skills (Select Relevant Skills)</Label>
            <SkillContainer>
              {technicalSkills.map((skill) => (
                <SkillTag
                  key={skill}
                  onClick={() => handleTechnicalSkillSelect(skill)}
                  style={{
                    backgroundColor: formData.technical_skills.includes(skill)
                      ? '#caaae1'
                      : '#f8f4fb',
                    color: formData.technical_skills.includes(skill)
                      ? 'white'
                      : '#805ad5',
                    cursor: 'pointer'
                  }}
                >
                  {skill}
                </SkillTag>
              ))}
              {formData.technical_skills
                .filter((skill) => !technicalSkills.includes(skill))
                .map((skill) => (
                  <SkillTag
                    key={skill}
                    onClick={() => handleTechnicalSkillSelect(skill)}
                    style={{
                      backgroundColor: '#caaae1',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {skill}
                  </SkillTag>
              ))}
            </SkillContainer>

            {showOtherTechnicalSkills && (
              <div style={{ marginTop: '1rem' }}>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <Input
                    type="text"
                    value={otherTechnicalSkills}
                    onChange={(e) => setOtherTechnicalSkills(e.target.value)}
                    placeholder="Enter skills separated by commas (e.g., Kotlin, Flutter, Unity)"
                  />
                  <Button
                    type="button"
                    onClick={handleOtherTechnicalSkillsSubmit}
                    disabled={!otherTechnicalSkills.trim()}
                  >
                    <Plus size={16} /> Add
                  </Button>
                </div>
              </div>
            )}
          </FormSection>

          <FormSection>
            <Label required>Job Summary</Label>
            <RichTextWrapper>
              <ReactQuill
                value={formData.description}
                onChange={(value) => handleRichTextChange(value, 'description')}
                modules={quillModules}
                formats={quillFormats}
                placeholder="Provide a detailed description of the role"
              />
            </RichTextWrapper>
          </FormSection>

          <FormSection>
            <Label required>Responsibilities</Label>
            <RichTextWrapper>
              <ReactQuill
                value={formData.responsibilities}
                onChange={(value) =>
                  handleRichTextChange(value, 'responsibilities')
                }
                modules={quillModules}
                formats={quillFormats}
                placeholder="List the key responsibilities of the role"
              />
            </RichTextWrapper>
          </FormSection>

          <FormSection>
            <Label required>Requirements</Label>
            <RichTextWrapper>
              <ReactQuill
                value={formData.requirements}
                onChange={(value) =>
                  handleRichTextChange(value, 'requirements')
                }
                modules={quillModules}
                formats={quillFormats}
                placeholder="List the requirements for this position"
              />
            </RichTextWrapper>
          </FormSection>

          <FormSection>
            <Label>Preferred Qualifications</Label>
            <RichTextWrapper>
              <ReactQuill
                value={formData.nice_to_haves}
                onChange={(value) =>
                  handleRichTextChange(value, 'nice_to_haves')
                }
                modules={quillModules}
                formats={quillFormats}
                placeholder="List any preferred qualifications or bonus skills"
              />
            </RichTextWrapper>
          </FormSection>

          <FormSection>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem'
              }}
            >
              <div>
                <Label required>Benefits</Label>
                {companyBenefits.length > 0 && (
                  <CheckboxLabel style={{ marginLeft: '1rem' }}>
                    <input
                      type="checkbox"
                      checked={showCompanyBenefits}
                      onChange={handleToggleCompanyBenefits}
                    />
                    <span>Include Company Benefits</span>
                  </CheckboxLabel>
                )}
              </div>
              <Button type="button" onClick={() => setShowBenefitModal(true)}>
                <Plus size={16} /> Add Job-Specific Benefit
              </Button>
            </div>

            <input
              type="hidden"
              required
              value={formData.benefits.length > 0 ? 'valid' : ''}
            />

            <BenefitsGrid>
              {formData.benefits.map((benefit, index) => {
                const categoryInfo = benefitCategories.find(
                  (cat) => cat.value === benefit.category
                )
                const Icon = categoryInfo?.icon || Plus
                const isCompanyBenefit = companyBenefits.some(
                  (cb) =>
                    cb.category === benefit.category &&
                    cb.description === benefit.description
                )

                return (
                  <BenefitCard
                    key={index}
                    style={{
                      border: isCompanyBenefit
                        ? '2px solid #caaae1'
                        : '1px solid #e2e8f0'
                    }}
                  >
                    {!isCompanyBenefit || !showCompanyBenefits ? (
                      <RemoveButton
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem'
                        }}
                        onClick={() => handleRemoveBenefit(index)}
                      >
                        <X size={16} />
                      </RemoveButton>
                    ) : (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem',
                          fontSize: '0.75rem',
                          color: '#805ad5',
                          padding: '0.25rem 0.5rem',
                          borderRadius: '4px',
                          background: '#f8f4fb'
                        }}
                      >
                        Company Benefit
                      </div>
                    )}
                    <BenefitIcon>
                      <Icon size={24} />
                    </BenefitIcon>
                    <h3 style={{ marginBottom: '0.5rem' }}>
                      {categoryInfo?.label}
                    </h3>
                    <p style={{ fontSize: '14px', color: '#666' }}>
                      {benefit.description}
                    </p>
                  </BenefitCard>
                )
              })}
            </BenefitsGrid>
          </FormSection>

          <FormSection>
            <Label required>Willing to sponsor work visa?</Label>
            <RadioGroup>
              <RadioLabel checked={formData.willing_to_sponsor === true}>
                <input
                  type="radio"
                  name="willing_to_sponsor"
                  required
                  checked={formData.willing_to_sponsor === true}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: 'willing_to_sponsor',
                        value: true
                      }
                    })
                  }
                />
                Yes
              </RadioLabel>
              <RadioLabel checked={formData.willing_to_sponsor === false}>
                <input
                  type="radio"
                  name="willing_to_sponsor"
                  required
                  checked={formData.willing_to_sponsor === false}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: 'willing_to_sponsor',
                        value: false
                      }
                    })
                  }
                />
                No
              </RadioLabel>
            </RadioGroup>
          </FormSection>

          <FormSection>
            <Label required>Application Method</Label>
            <RadioGroup>
              <RadioLabel checked={formData.application_type === 'internal'}>
                <input
                  type="radio"
                  name="application_type"
                  checked={formData.application_type === 'internal'}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: 'application_type',
                        value: 'internal'
                      }
                    })
                  }
                />
                Host Job Posting on Arena
              </RadioLabel>
              <RadioLabel checked={formData.application_type === 'external'}>
                <input
                  type="radio"
                  name="application_type"
                  checked={formData.application_type === 'external'}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: 'application_type',
                        value: 'external'
                      }
                    })
                  }
                />
                Link to External Posting
              </RadioLabel>
            </RadioGroup>

            {formData.application_type === 'external' && (
              <div style={{ marginTop: '1rem' }}>
                <Label required>External Application URL</Label>
                <Input
                  type="url"
                  name="external_application_url"
                  value={formData.external_application_url}
                  onChange={handleInputChange}
                  placeholder="https://your-careers-site.com/job-posting"
                  pattern="https?://.*"
                  title="Please enter a valid URL starting with http:// or https://"
                  required={formData.application_type === 'external'}
                />
                <p
                  style={{
                    marginTop: '0.5rem',
                    fontSize: '0.875rem',
                    color: '#64748b'
                  }}
                >
                  When candidates click "Apply", they will be redirected to this
                  URL in a new tab.
                </p>
              </div>
            )}
          </FormSection>

          {/* <Button
            type="button"
            style={{ marginTop: '1rem' }}
            onClick={handleCheckInclusiveLanguage}
            disabled={isSubmitting}
          >
            Check Job Posting with AI for Inclusive Language
          </Button> */}
          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Posting...' : 'Post Job'}
          </SubmitButton>
        </FormCard>
      </MainContent>

      {showBenefitModal && (
        <>
          <Modal>
            <FormTitle>Add Benefit</FormTitle>
            <FormSection>
              <Label>Benefit Category</Label>
              <Select
                value={newBenefit.category}
                onChange={(e) =>
                  setNewBenefit((prev) => ({
                    ...prev,
                    category: e.target.value
                  }))
                }
              >
                <option value="">Select a category</option>
                {benefitCategories.map((category) => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </Select>
            </FormSection>

            <FormSection>
              <Label>Description</Label>
              <Textarea
                value={newBenefit.description}
                onChange={(e) =>
                  setNewBenefit((prev) => ({
                    ...prev,
                    description: e.target.value
                  }))
                }
                placeholder="Enter benefit description"
              />
            </FormSection>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1rem'
              }}
            >
              <Button
                type="button"
                onClick={() => setShowBenefitModal(false)}
                style={{ backgroundColor: '#e2e8f0', color: '#4a5568' }}
              >
                Cancel
              </Button>
              <Button type="button" onClick={handleAddBenefit}>
                Add Benefit
              </Button>
            </div>
          </Modal>
          <ModalOverlay onClick={() => setShowBenefitModal(false)} />
        </>
      )}
    </Container>
  )
}

export default JobPostForm
